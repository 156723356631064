import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type ClickedInviteMember = {
  location: 'fat-nav' | 'workspace-settings' | 'workspace-dropdown' | 'header';
};

export const useTrackClickedInviteMember = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedInviteMember = useCallback(
    (params: ClickedInviteMember) => {
      track(`Clicked Invite Member`, params);
    },
    [track],
  );

  return { trackClickedInviteMember };
};
