import { forwardRef, SVGProps } from 'react';

export interface NavImgRecognitionProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const NavImgRecognition = forwardRef<SVGSVGElement, NavImgRecognitionProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M20.86 24.46H9.68a2.14 2.14 0 01-2.14-2.13V9.67a2.14 2.14 0 012.14-2.13h12.64a2.14 2.14 0 012.14 2.13v12.66a2.14 2.14 0 01-2.14 2.13h-1.46zm.43-2h1a.13.13 0 00.14-.13v-2l-3.09-2.62a.24.24 0 00-.27 0l-1.43.95zM9.54 19.53v2.8a.13.13 0 00.14.13h8.85l-5.77-6.05a.21.21 0 00-.17-.07.24.24 0 00-.17.07zm9.67-3.85a2.24 2.24 0 011.45.53l1.8 1.53V9.67a.13.13 0 00-.14-.13H9.68a.13.13 0 00-.14.13v6.92L11 15.05a2.23 2.23 0 011.62-.71 2.25 2.25 0 011.62.69l2.06 2.16 1.7-1.14a2.16 2.16 0 011.21-.37zM10.67 28a1.1 1.1 0 00-1.1-1.1H5.83a.73.73 0 01-.73-.73v-2.71a1.1 1.1 0 00-2.2 0v2.71a2.93 2.93 0 002.93 2.93h3.74a1.1 1.1 0 001.1-1.1zM5.1 8.54V5.83a.73.73 0 01.73-.73h3.74a1.1 1.1 0 000-2.2H5.83A2.93 2.93 0 002.9 5.83v2.71a1.1 1.1 0 102.2 0zm24 17.63v-2.71a1.1 1.1 0 00-2.2 0v2.71a.73.73 0 01-.73.73h-3.68a1.1 1.1 0 100 2.2h3.68a2.93 2.93 0 002.93-2.93zm0-17.63V5.83a2.93 2.93 0 00-2.93-2.93h-3.68a1.1 1.1 0 000 2.2h3.68a.73.73 0 01.73.73v2.71a1.1 1.1 0 002.2 0zm-9.62 3.28a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.2z" />
  </svg>
));
NavImgRecognition.displayName = 'NavImgRecognition';
