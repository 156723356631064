import { forwardRef, SVGProps } from 'react';

export interface EyeProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Eye = forwardRef<SVGSVGElement, EyeProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M16 27.2c-7.06 0-12.55-7.81-12.79-8.14a1.24 1.24 0 010-1.38c.24-.34 5.73-8.14 12.79-8.14s12.55 7.8 12.79 8.14a1.24 1.24 0 010 1.38c-.24.33-5.73 8.14-12.79 8.14zM5.67 18.37C7.1 20.16 11.27 24.8 16 24.8s8.89-4.64 10.33-6.43c-1.44-1.79-5.6-6.43-10.33-6.43s-8.9 4.64-10.33 6.43zm10.51 5a5.23 5.23 0 010-10.45 1.18 1.18 0 01.83.35 1.23 1.23 0 01.35.85v2.82h2.73a1.19 1.19 0 011.18 1.2 5.16 5.16 0 01-5.09 5.21zM15 15.58a2.83 2.83 0 00-1.55 2.54A2.79 2.79 0 0016.18 21a2.74 2.74 0 002.47-1.63h-2.47a1.15 1.15 0 01-.84-.35 1.21 1.21 0 01-.35-.85zm8.8-4.13a1.18 1.18 0 01-.8-.35 1.2 1.2 0 010-1.69l.64-.66a1.17 1.17 0 011.67 0 1.22 1.22 0 010 1.7l-.64.66a1.15 1.15 0 01-.88.34zm-15.68-.07a1.14 1.14 0 01-.86-.38l-.55-.59a1.22 1.22 0 010-1.7 1.17 1.17 0 011.67.06l.62.59a1.19 1.19 0 01-.86 2zM16 8.58a1.19 1.19 0 01-1.18-1.2V6A1.19 1.19 0 0116 4.8 1.19 1.19 0 0117.18 6v1.38A1.19 1.19 0 0116 8.58z"
    />
  </svg>
));
Eye.displayName = 'Eye';
