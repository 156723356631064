import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { ImportModalEvent } from '../types';

export const useTrackViewedImportModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedImportModal = useCallback(
    (params: ImportModalEvent) => {
      track(`Viewed import modal`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackViewedImportModal };
};
