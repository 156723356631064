import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { ClosedImportModalLocation } from '../types';

export type ClosedImportModalEvent = { location: ClosedImportModalLocation };

export const useTrackClosedImportModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackClosedImportModal = useCallback(
    (params: ClosedImportModalEvent) => {
      track(`Closed Import modal`, params);
    },
    [track],
  );

  return { trackClosedImportModal };
};
