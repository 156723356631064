import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackEditedSessionDurationNumber.meta';

export type UseTrackEditedSessionDurationNumberParams = {
  session_duration_time: number;
  session_duration_number: number;
};

export const useTrackEditedSessionDurationNumber = () => {
  const { track } = useAnalyticsUtilities();

  const trackEditedSessionDurationNumber = useCallback(
    (params: UseTrackEditedSessionDurationNumberParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackEditedSessionDurationNumber };
};
