import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackCopiedContentCollectionLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackCopiedContentCollectionLink = useCallback(() => {
    track(`Copied a content collection link`);
  }, [track]);

  return { trackCopiedContentCollectionLink };
};
