import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedLibrary = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedLibrary = useCallback(() => {
    track(`Viewed Library`);
  }, [track]);

  return { trackViewedLibrary };
};
