'use client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logout } from '../utils/logout';

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      queryClient.clear();
    },
  });

  return { logoutMutation };
};
