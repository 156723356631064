import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { OnboardingSurveyCompleted } from '../types';

export const useTrackCompletedOnboardingPurposeOfUse = () => {
  const { track } = useAnalyticsUtilities();

  const trackCompletedOnboardingPurposeOfUse = useCallback(
    (params: OnboardingSurveyCompleted) => {
      track(`Completed onboarding primary purpose`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackCompletedOnboardingPurposeOfUse };
};
