import { Meta } from '../types/docs';

const META: Meta = {
  name: 'removed_library_member',
  description: 'User removed from a library',
  params: [
    {
      name: 'library_id',
      description: 'The id of the library',
      required: true,
      type: 'string',
    },
    {
      name: 'library_member_id',
      description: 'The id of the updated library member',
      required: true,
      type: 'string',
    },
    {
      name: 'library_member_role_id',
      description: 'The role of the updated library member',
      required: false,
      type: 'string',
    },
  ],
};

export default META;
