import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackClickedConnectToSlackOptionParams } from './useTrackClickedConnectToSlackOption';

export type TrackDisconnectedSlackIntegrationParams = TrackClickedConnectToSlackOptionParams & {
  channelName: string;
};

/**
 * Tracks when a user disconnects a slack integration
 */
export const useTrackDisconnectedSlackIntegration = () => {
  const { track } = useAnalyticsUtilities();

  const trackDisconnectedSlackIntegration = useCallback(
    (params: TrackDisconnectedSlackIntegrationParams) => {
      track('Disconnected slack integration', params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackDisconnectedSlackIntegration };
};
