import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export const useTrackClickedAddCustomField = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedAddCustomField = useCallback(
    (params: TrackCustomFieldsActionParams) => {
      track(`Clicked "Add a custom field"`, params);
    },
    [track],
  );

  return { trackClickedAddCustomField };
};
