import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackSavedFilter } from '../types';

export const useTrackSavedFilterUpdated = () => {
  const { track } = useAnalyticsUtilities();

  const trackSavedFilterUpdated = useCallback(
    (params: TrackSavedFilter) => {
      track(`Updated saved filter`, params);
    },
    [track],
  );

  return { trackSavedFilterUpdated };
};
