import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type LoggedOutReason = 'mismatched-user-pool' | 'no-auth-header';

export const useTrackLoggedOut = () => {
  const { track, reset, resetGlobalEventProps } = useAnalyticsUtilities();

  const trackLoggedOut = useCallback(
    (params?: { reason?: LoggedOutReason }) => {
      // we're firing the `track` event before we reset analytics and global properties
      // so that we can track which user fired this event
      track(`Logged out`, params, { integrations: { Intercom: true } });
      resetGlobalEventProps();
      reset();

      // TODO: this might need intercom specific functionality
    },
    [reset, resetGlobalEventProps, track],
  );

  return { trackLoggedOut };
};
