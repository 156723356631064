import { forwardRef, SVGProps } from 'react';

export interface FigmaAdjustIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaAdjustIcon = forwardRef<SVGSVGElement, FigmaAdjustIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M4 8.05001V1H5V8.05001C6.14112 8.28164 7 9.29052 7 10.5C7 11.7095 6.14112 12.7184 5 12.95V15H4V12.95C2.85888 12.7184 2 11.7095 2 10.5C2 9.29052 2.85888 8.28164 4 8.05001ZM6 10.5C6 11.3284 5.32843 12 4.5 12C3.67157 12 3 11.3284 3 10.5C3 9.67157 3.67157 9 4.5 9C5.32843 9 6 9.67157 6 10.5ZM11 15H12V7.94999C13.1411 7.71836 14 6.70948 14 5.5C14 4.29052 13.1411 3.28164 12 3.05001V1H11V3.05001C9.85888 3.28164 9 4.29052 9 5.5C9 6.70948 9.85888 7.71836 11 7.94999V15ZM13 5.5C13 4.67157 12.3284 4 11.5 4C10.6716 4 10 4.67157 10 5.5C10 6.32843 10.6716 7 11.5 7C12.3284 7 13 6.32843 13 5.5Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaAdjustIcon.displayName = 'FigmaAdjustIcon';
