import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type LeadFormUILocation =
  | 'asset_modal_api_cta'
  | 'libraries_cta'
  | 'members_page_cta'
  | 'plan_page_cta'
  | 'security_page_cta'
  | 'workspace_popover_cta';

export type TrackClickedLeadFormSubmitParams = {
  email: string;
  ui_location?: LeadFormUILocation;
};

export const useTrackClickedLeadFormSubmit = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedLeadFormSubmit = useCallback(
    (params: TrackClickedLeadFormSubmitParams) => {
      track(`Lead Form Submit`, params);
    },
    [track],
  );

  return { trackClickedLeadFormSubmit };
};
