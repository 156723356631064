import { forwardRef, SVGProps } from 'react';

export interface CheckCircledProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const CheckCircled = forwardRef<SVGSVGElement, CheckCircledProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M13.4407 21.3807C13.239 21.3815 13.04 21.335 12.8597 21.2448C12.6794 21.1546 12.5227 21.0234 12.4024 20.8616L9.77556 17.2588C9.57316 16.9834 9.48845 16.6389 9.54005 16.3011C9.59165 15.9632 9.77533 15.6597 10.0507 15.4574C10.3261 15.255 10.6706 15.1702 11.0084 15.2218C11.3462 15.2734 11.6497 15.4571 11.8521 15.7325L13.5237 17.9959C15.5866 15.6295 17.9618 13.5547 20.584 11.8286C20.8577 11.7031 21.1669 11.6784 21.4571 11.7587C21.7472 11.8391 21.9996 12.0194 22.1698 12.2678C22.3399 12.5162 22.4169 12.8167 22.387 13.1163C22.3571 13.4159 22.2223 13.6953 22.0064 13.9051C19.1356 15.7388 16.6118 18.0657 14.5516 20.7785C14.4346 20.9468 14.2796 21.0852 14.0993 21.1825C13.9189 21.2799 13.7182 21.3336 13.5133 21.3392L13.4407 21.3807Z"
    />
    <path
      fill="currentColor"
      d="M16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05844 6.10051 6.10051C8.05845 4.14257 10.553 2.8092 13.2687 2.26901C15.9845 1.72882 18.7994 2.00607 21.3576 3.0657C23.9157 4.12532 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30ZM16 4.18001C13.6622 4.18001 11.377 4.87324 9.43317 6.17203C7.48938 7.47083 5.97438 9.31687 5.07975 11.4767C4.18512 13.6365 3.95105 16.0131 4.40712 18.306C4.8632 20.5988 5.98895 22.705 7.642 24.358C9.29506 26.0111 11.4012 27.1368 13.694 27.5929C15.9869 28.049 18.3635 27.8149 20.5233 26.9203C22.6831 26.0256 24.5292 24.5106 25.828 22.5668C27.1268 20.6231 27.82 18.3378 27.82 16C27.8174 12.866 26.5712 9.86104 24.3551 7.64493C22.139 5.42883 19.1341 4.18265 16 4.18001Z"
    />
  </svg>
));
CheckCircled.displayName = 'CheckCircled';
