import { Meta } from '../types/docs';

const META: Meta = {
  name: 'joined_library',
  description: 'User joined a library',
  params: [
    {
      name: 'library_id',
      description: 'The id of the library',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
