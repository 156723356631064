import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackClickedAssetTabParams = { clipId: Clip['id'] };

/**
 * Tracks when a user navigates to another board from the asset modal
 */
export const useTrackClickedAlternateBoardLocation = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedAlternateBoardLocation = useCallback(
    (params: TrackClickedAssetTabParams) => {
      track(`Clicked alternate board location`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackClickedAlternateBoardLocation };
};
