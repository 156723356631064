import type { Comment } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import type { BaseEditCommentParams } from '../types';
import META from './useTrackAddedComment.meta';

export type AddedComment = BaseEditCommentParams & {
  comment: Comment['body'];
  startedDiscussion: boolean;
};

export const useTrackAddedComment = () => {
  const { track } = useAnalyticsUtilities();

  const trackAddedComment = useCallback(
    (params: AddedComment) => {
      track(META.name, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackAddedComment };
};
