import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackFavoritedBoardParams } from '../types';

export const useTrackUnfavoritedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackUnfavoritedBoard = useCallback(
    (params: TrackFavoritedBoardParams) => {
      track(`Unfavorited board`, params);
    },
    [track],
  );

  return { trackUnfavoritedBoard };
};
