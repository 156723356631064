import { Router } from 'next/router';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';

/**
 * This hook enables you to subscribe to the Next routeChangeComplete event and use a selector
 * so that you only get re-renders when your selector returns a new value. This function uses
 * a deep compare under the hood so if your selector returns an object, it will be deeply checked
 * @param selector - This selector you'd like to run whenever the URL changes. It's passed window.location
 */
export const useRouterSelector = <Value extends any>(selector: (location: Location) => Value) => {
  /**
   * We need to check we're not on the server since window doesn't exist there
   * but when the client renders for the first time we set the value immediately
   */
  const [st, setSt] = useState<Value | undefined>(
    typeof window !== 'undefined' ? selector(window.location) : undefined,
  );

  useEffect(() => {
    const routeChangeCompleteHandler = () => {
      const newValue = selector(window.location);

      /**
       * We do a deep compare here because most likely the consumers will not want a re-render
       * if the values are the same but they are an object.
       *
       * It might look odd that we are calling setSt even if the value hasn't changed. currentState
       * is guaranteed to up-to-date and setting state with the same value doesn't cause a re-render
       */
      setSt((currentState) => (!isEqual(newValue, currentState) ? newValue : currentState));
    };

    Router.events.on('routeChangeComplete', routeChangeCompleteHandler);

    return () => {
      Router.events.off('routeChangeComplete', routeChangeCompleteHandler);
    };
  }, [selector]);

  return st;
};
