import { Meta } from '../types/docs';

const META: Meta = {
  name: 'enabled_or_disabled_inactivity_logout',
  description: 'Tracks when a user enables/disables inactivity logout',
  params: [
    {
      name: 'inactivity_logout_enabled',
      description: 'binary value captures whether feature was enabled or disabled',
      required: false,
      type: 'boolean',
    },
    {
      name: 'inactivity_logout_timeout_duration',
      description: 'Timeout period (in seconds)',
      required: false,
      type: 'number',
    },
  ],
};

export default META;
