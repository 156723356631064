import { User, Workspace } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type CreatedWorkspace = Pick<Workspace, 'id' | 'name'> & {
  location?: 'in-app' | 'onboarding';
  creatorId: User['id'];
};

export const useTrackCreatedWorkspace = () => {
  const { track, setGlobalEventProps } = useAnalyticsUtilities();

  const trackCreatedWorkspace = useCallback(
    (params: CreatedWorkspace) => {
      setGlobalEventProps({ workspaceId: params.id, workspaceName: params.name });
      track(`Created a workspace`, params, { integrations: { Intercom: true } });
    },
    [setGlobalEventProps, track],
  );

  return { trackCreatedWorkspace };
};
