let isLauncherVisible = false;

type IntercomLauncherVisibilityListener = (isVisible: boolean) => void;

const visibilityListeners: IntercomLauncherVisibilityListener[] = [];

export const sendIntercomLauncherVisibilityEvent = (isVisible: boolean) => {
  if (isVisible !== isLauncherVisible) {
    isLauncherVisible = isVisible;
    visibilityListeners.forEach((c) => c(isLauncherVisible));
  }
};

export const subscribeToIntercomLauncherVisibility = (callback: IntercomLauncherVisibilityListener) => {
  visibilityListeners.push(callback);

  return {
    unsubscribe: () => visibilityListeners.filter((c) => c === callback),
  };
};

export const isIntercomLauncherVisible = () => isLauncherVisible;
