import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

type BaseDragData = {
  numberOfBoards: number;
  boardIds: string[];
  numberOfAssets: number;
  clipIds: Clip['id'][];
};

export type DragWorkspaceData = {
  workspaceId: string;
};

export type DragVersionData = {
  clipId: Clip['id'];
};

export type StartedDragging = BaseDragData | DragWorkspaceData | DragVersionData;

export const useTrackStartedDragging = () => {
  const { track } = useAnalyticsUtilities();

  const trackStartedDragging = useCallback(
    (params: StartedDragging) => {
      track(`Started dragging items`, params);
    },
    [track],
  );

  return { trackStartedDragging };
};
