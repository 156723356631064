import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackOpenedPlansModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackOpenedPlansModal = useCallback(() => {
    track(`Opened paid plans modal`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackOpenedPlansModal };
};
