import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackTagUpdated } from '../types';

export const useTrackTagsMerged = () => {
  const { track } = useAnalyticsUtilities();

  const trackTagsMerged = useCallback(
    (params: TrackTagUpdated) => {
      track(`Tags merged`, params);
    },
    [track],
  );

  return { trackTagsMerged };
};
