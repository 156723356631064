import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedCheckoutPage = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedCheckoutPage = useCallback(() => {
    track(`Viewed checkout page`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackViewedCheckoutPage };
};
