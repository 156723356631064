import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Viewed board',
  description: 'Event sent when a user views a board',
  params: [
    {
      name: 'board_id',
      description: 'The id of the board',
      required: true,
      type: 'string',
    },
    {
      name: 'board_title',
      description: 'The title of the board',
      required: true,
      type: 'string',
    },
    {
      name: 'board_view',
      description: 'The view type of the board',
      values: ['kanban', 'gallery', 'table'],
      required: false,
      type: 'enum',
    },
  ],
};

export default META;
