import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackSavedFilterCreated = {
  trackLocation: AirActionTrackingLocation;
};
export const useTrackSavedFilterCreated = () => {
  const { track } = useAnalyticsUtilities();

  const trackSavedFilterCreated = useCallback(
    (params: TrackSavedFilterCreated) => {
      track(`Created saved filter`, params);
    },
    [track],
  );

  return { trackSavedFilterCreated };
};
