import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { ImportModalEvent } from '../types';

export const useTrackClickedImportSignOut = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedImportSignOut = useCallback(
    (params: ImportModalEvent) => {
      track(`Clicked Sign Out in Import Modal`, params);
    },
    [track],
  );

  return { trackClickedImportSignOut };
};
