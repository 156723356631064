import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackRegeneratedShareLinkParams = { shortId: string; location: AirActionTrackingLocation };

export const useTrackRegeneratedShareLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackRegeneratedShareLink = useCallback(
    (params: TrackRegeneratedShareLinkParams) => {
      track(`Regenerated a share url`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackRegeneratedShareLink };
};
