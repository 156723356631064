import { Meta } from '../types/docs';

const META: Meta = {
  name: 'logged_out_due_to_session_expiration',
  description: 'User is logged out due to session expiration',
  params: [
    {
      name: 'session_duration_time',
      description: 'Duration period (in seconds)',
      required: true,
      type: 'number',
    },
  ],
};

export default META;
