import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackTagUpdated } from '../types';

export const useTrackTagDeleted = () => {
  const { track } = useAnalyticsUtilities();

  const trackTagDeleted = useCallback(
    (params: TrackTagUpdated) => {
      track(`Tag deleted`, params);
    },
    [track],
  );

  return { trackTagDeleted };
};
