import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackAddMenuOption } from '../types';

export const useTrackClickedAddASubBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedAddASubBoard = useCallback(
    (params: TrackAddMenuOption) => {
      track(`Clicked 'Add a Sub-board'`, params);
    },
    [track],
  );

  return { trackClickedAddASubBoard };
};
