import { ShortUrl } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedExpiredLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedExpiredLink = useCallback(
    (params: { shortId?: ShortUrl['id'] }) => {
      track(`Viewed expired link`, params);
    },
    [track],
  );

  return { trackViewedExpiredLink };
};
