import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export const useTrackClickedDeleteCustomField = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedDeleteCustomField = useCallback(
    (params: TrackCustomFieldsActionParams) => {
      track(`Clicked Delete custom field`, params);
    },
    [track],
  );

  return { trackClickedDeleteCustomField };
};
