import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackKanbanDrag } from './useTrackKanbanDragStart';

export const useTrackKanbanDragCancel = () => {
  const { track } = useAnalyticsUtilities();

  const trackKanbanDragCancel = useCallback(
    (params: TrackKanbanDrag) => {
      track(`Canceled kanban drag`, params);
    },
    [track],
  );

  return { trackKanbanDragCancel };
};
