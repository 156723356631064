import { forwardRef, SVGProps } from 'react';

export interface BoundingBoxProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const BoundingBox = forwardRef<SVGSVGElement, BoundingBoxProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M2.89999 4.76003C2.89999 3.68956 3.76778 2.82178 4.83825 2.82178H8.71476C9.78522 2.82178 10.653 3.68956 10.653 4.76003V5.72919H21.3134V4.76006C21.3134 3.6896 22.1812 2.82181 23.2517 2.82181H27.1282C28.1986 2.82181 29.0664 3.6896 29.0664 4.76006V8.63657C29.0664 9.70704 28.1986 10.5748 27.1282 10.5748H26.159V21.2352H27.1282C28.1986 21.2352 29.0664 22.103 29.0664 23.1735V27.05C29.0664 28.1205 28.1986 28.9882 27.1282 28.9882H23.2517C22.1812 28.9882 21.3134 28.1205 21.3134 27.05V26.0809H10.653V27.05C10.653 28.1205 9.78523 28.9882 8.71476 28.9882H4.83825C3.76779 28.9882 2.9 28.1205 2.9 27.05V23.1735C2.9 22.103 3.76779 21.2352 4.83825 21.2352H5.80738V10.5748H4.83825C3.76778 10.5748 2.89999 9.70701 2.89999 8.63654V4.76003ZM7.74564 10.5748V21.2352H8.71476C9.78523 21.2352 10.653 22.103 10.653 23.1735V24.1426H21.3134V23.1735C21.3134 22.103 22.1812 21.2352 23.2517 21.2352H24.2208V10.5748H23.2517C22.1812 10.5748 21.3134 9.70704 21.3134 8.63657V7.66745H10.653V8.63654C10.653 9.70701 9.78522 10.5748 8.71476 10.5748H7.74564ZM23.2517 7.66745V8.63657H24.2208H26.159H27.1282V4.76006H23.2517V5.72919V7.66745ZM24.2208 23.1735H23.2517V24.1426V26.0809V27.05H27.1282V23.1735H26.159H24.2208ZM8.71476 24.1426V23.1735H7.74564H5.80738H4.83825V27.05H8.71476V26.0809V24.1426ZM8.71476 4.76003H4.83825V8.63654H8.71476V4.76003Z"
      clipRule="evenodd"
    />
  </svg>
));
BoundingBox.displayName = 'BoundingBox';
