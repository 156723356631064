import { Workspace } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export interface SwitchWorkspaceParams {
  fromId?: Workspace['id'];
  fromName?: Workspace['name'];
  toId: Workspace['id'];
  toName?: Workspace['name'];
}

export const useTrackSwitchedWorkspace = () => {
  const { track, setGlobalEventProps } = useAnalyticsUtilities();

  const trackSwitchedWorkspace = useCallback(
    (params: SwitchWorkspaceParams) => {
      setGlobalEventProps({
        workspaceId: params.toId,
        workspaceName: params.toName,
      });

      track(`Switched workspaces`, params);
    },
    [setGlobalEventProps, track],
  );

  return { trackSwitchedWorkspace };
};
