import { forwardRef, SVGProps } from 'react';

export interface WebProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Web = forwardRef<SVGSVGElement, WebProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M28.5 16a12.5 12.5 0 00-25 0 12.52 12.52 0 0011.68 12.4 1.43 1.43 0 00.22 0h1.26a1 1 0 00.24-.05A12.5 12.5 0 0028.5 16zM5.63 17.08h3.49a14.12 14.12 0 00.9 4 21.23 21.23 0 00-2.31 1.2 10.33 10.33 0 01-2.08-5.2zm2-7.28a20.73 20.73 0 002.51 1.3 14.49 14.49 0 00-1 3.91H5.62A10.34 10.34 0 017.63 9.8zM26.38 15h-3.47a14.63 14.63 0 00-1-3.93 20.66 20.66 0 002.46-1.27 10.38 10.38 0 012.01 5.2zm-15.15 0a12.48 12.48 0 01.86-3.2 13.74 13.74 0 003.91.6 13.82 13.82 0 004-.61 12.23 12.23 0 01.82 3.21zm9.63 2.07a11.6 11.6 0 01-.77 3.31 13.74 13.74 0 00-8.13 0 12.33 12.33 0 01-.77-3.3zM16 5.87a19.77 19.77 0 013 4 11.61 11.61 0 01-3 .43 11.57 11.57 0 01-3-.41 20.43 20.43 0 013-4.02zm5 3.34A21.34 21.34 0 0018.89 6a10.42 10.42 0 014.05 2.25 20.21 20.21 0 01-1.94.96zm-10 0a17.78 17.78 0 01-1.95-1A10.44 10.44 0 0113.17 6 22.55 22.55 0 0011 9.22zM10.86 23a21.69 21.69 0 001.81 2.89 10.62 10.62 0 01-3.51-2c.57-.36 1.14-.65 1.7-.89zM16 26.43h-.18a20.19 20.19 0 01-3-4.14 11.73 11.73 0 013.18-.47 12.1 12.1 0 013.21.47 20 20 0 01-3 4.13zM21.19 23a18.33 18.33 0 011.65.84 10.52 10.52 0 01-3.43 2A22.28 22.28 0 0021.19 23zm.81-1.88a14.19 14.19 0 00.91-4h3.43a10.33 10.33 0 01-2.08 5.22A20.38 20.38 0 0022 21.12z" />
  </svg>
));
Web.displayName = 'Web';
