import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAddedShareLinkPasscode = () => {
  const { track } = useAnalyticsUtilities();

  const trackAddedShareLinkPasscode = useCallback(
    (params: { shortId: string }) => {
      track(`Added passcode`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackAddedShareLinkPasscode };
};
