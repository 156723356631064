import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedDeleteInviteLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedDeleteInviteLink = useCallback(() => {
    track(`Clicked delete invite link`);
  }, [track]);

  return { trackClickedDeleteInviteLink };
};
