import { Meta } from '../types/docs';

const META: Meta = {
  name: 'edited_session_duration_unit',
  description: 'User edits session duration unit select box',
  params: [
    {
      name: 'session_duration_time',
      description: 'Duration period (in seconds)',
      required: true,
      type: 'number',
    },
    {
      name: 'session_duration_unit',
      description: 'The unit of value (days, hours, minutes) the user chose from the select box',
      required: true,
      values: ['day', 'hr', 'min'],
      type: 'enum',
    },
  ],
};

export default META;
