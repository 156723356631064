import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Added a field to collection form',
  description: 'User added a field to collection form',
  params: [
    {
      name: 'fieldId',
      description: 'The ID of the field that was added',
      required: false,
      type: 'string',
    },
    {
      name: 'fieldName',
      description: 'The name of the field that was added',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
