import { forwardRef, SVGProps } from 'react';

export interface FigmaCaretUpIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaCaretUpIcon = forwardRef<SVGSVGElement, FigmaCaretUpIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path fill="currentColor" d="M8 6L11 10L5 10L8 6Z" />
  </svg>
));

FigmaCaretUpIcon.displayName = 'FigmaCaretUpIcon';
