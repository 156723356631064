import { forwardRef, SVGProps } from 'react';

export interface FigmaListDetailedIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaListDetailedIcon = forwardRef<SVGSVGElement, FigmaListDetailedIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M4 2.5H2V3.5H4V2.5ZM4 12.5H2V13.5H4V12.5ZM2 7.5H4V8.5H2V7.5ZM14 2.5H6V3.5H14V2.5ZM6 12.5H14V13.5H6V12.5ZM14 7.5H6V8.5H14V7.5Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaListDetailedIcon.displayName = 'FigmaListDetailedIcon';
