import { type LDContext, useLDClient } from 'launchdarkly-react-client-sdk';
import { debounce, noop } from 'lodash';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { convertUnknownToError, reportErrorToBugsnag } from '~/utils/ErrorUtils';

type LaunchDarklyProviderProps = PropsWithChildren<object>;

export interface LaunchDarklyProviderContextValue {
  identify: (context: LDContext) => void;
}

const defaultValue: LaunchDarklyProviderContextValue = {
  identify: noop,
};

const LaunchDarklyProviderContext = createContext<LaunchDarklyProviderContextValue>(defaultValue);

export const LaunchDarklyProvider = ({ children }: LaunchDarklyProviderProps) => {
  const ldClient = useLDClient();
  const userRef = useRef<LDContext>();

  const debouncedIdentify = useMemo(
    () =>
      debounce(async () => {
        if (userRef.current) {
          try {
            await ldClient?.identify(userRef.current);
          } catch (error) {
            const typedError = convertUnknownToError(error);
            // ignore network error to prevent unhandled promise being thrown and logging that to bugsnag
            if (typedError.message !== 'network error (Error)') {
              throw error;
            }
          }
        }
      }, 250),
    [ldClient],
  );

  const identify = useCallback(
    (user: LDContext) => {
      userRef.current = {
        kind: 'user',
        ...userRef.current,
        ...user,
      };

      debouncedIdentify();
    },
    [debouncedIdentify],
  );

  const value = useMemo(() => ({ identify }), [identify]);

  return <LaunchDarklyProviderContext.Provider value={value}>{children}</LaunchDarklyProviderContext.Provider>;
};

export const useLaunchDarklyContext = () => {
  const context = useContext(LaunchDarklyProviderContext);

  if (context === defaultValue) {
    const error = 'LaunchDarklyProviderContext used outside of LaunchDarklyProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
