import { forwardRef, SVGProps } from 'react';

export interface NavBlogProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const NavBlog = forwardRef<SVGSVGElement, NavBlogProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M30 14.51a1.7 1.7 0 00-1.29-1.65v-.37a3 3 0 00-3-3h-.81V4.07A1.1 1.1 0 0023.78 3H8.45a1.1 1.1 0 00-1.1 1.1v5.38h-.81a3 3 0 00-3 3v.33a1.71 1.71 0 000 3.39v9.7a3 3 0 003 3h19.15a3 3 0 003-3v-9.73A1.72 1.72 0 0030 14.51zm-7.32-9.34v4.29h-2.23a1.1 1.1 0 100 2.2h.65v.89a1.66 1.66 0 01-1.66 1.66h-6.66a1.66 1.66 0 01-1.65-1.66v-.89h.68a1.1 1.1 0 000-2.2H9.55V5.17zm3.83 20.73a.82.82 0 01-.82.82H6.54a.82.82 0 01-.82-.82V12.49a.82.82 0 01.82-.81h2.39v.87a3.86 3.86 0 003.85 3.86h6.66a3.86 3.86 0 003.86-3.86v-.87h2.39a.82.82 0 01.82.81zm-13.45-2.25a1.34 1.34 0 11-1.34-1.34 1.34 1.34 0 011.34 1.34zm4.39 0a1.34 1.34 0 11-1.34-1.34 1.34 1.34 0 011.34 1.31zm4.4 0a1.34 1.34 0 11-1.34-1.34 1.34 1.34 0 011.34 1.31zM9.94 21.12a1.34 1.34 0 111.34-1.34 1.34 1.34 0 01-1.34 1.34zm9.55-1.34a1.34 1.34 0 11-1.34-1.34 1.34 1.34 0 011.34 1.34zm-5.42 1.34a1.34 1.34 0 111.34-1.34 1.34 1.34 0 01-1.34 1.34zm9.55-1.34a1.34 1.34 0 11-1.34-1.34 1.34 1.34 0 011.34 1.34z" />
  </svg>
));
NavBlog.displayName = 'NavBlog';
