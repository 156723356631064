import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedOnboardingSource = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedOnboardingSource = useCallback(() => {
    track(`Viewed onboarding referral source`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackViewedOnboardingSource };
};
