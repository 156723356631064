import qs from 'qs';
import { ParsedUrlQueryInput } from 'querystring';

export const uuidRegExpString = '[A-F0-9]{8}-[A-F0-9]{4}-[45][A-F0-9]{3}-[89AB][A-F0-9]{3}-[A-F0-9]{12}';

export const sanitizeStringForUrl = (str: string): string =>
  str
    .replace(/[^a-zA-Z0-9 ]/g, ' ')
    .trim()
    .replace(/[ ]+/g, '-')
    .toLowerCase();

/**
 * Returns the web hostname if conditions are true, returns false if the preceding conditions fail
 */
const getWebHostname = () => {
  return (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname &&
    window.location.hostname.toLowerCase()
  );
};

/**
 * Returns true if the web hostname is localhost; otherwise false
 */
export const isLocal = () => getWebHostname() === 'localhost';

export const isUnitTest = () => process.env.NODE_ENV === 'test';

const getCurrentQueryString = () => (typeof window !== 'undefined' ? window.location.search.replace('?', '') : '');

// ParsedUrlQueryInput as qs.parse return are actually the same, but TypeScript complains that they mismatch
export const getCurrentQuery = (): ParsedUrlQueryInput =>
  (qs.parse(getCurrentQueryString()) ?? {}) as ParsedUrlQueryInput;

/**
 * Generates a referral URL for a given user.
 */
export const getReferralUrl = (referralId: string) => {
  // @NOTE disable for now
  // /**
  //  * On production, we will be generating a referral URL that will point
  //  * to the marketing website.
  //  */
  // if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
  //   return `${Routes.marketing.root}/?referral=${referralId}`;
  // }

  if (isLocal()) {
    return `http://${getWebHostname()}:3000/sign-up?referral=${referralId}`;
  }

  return `https://${getWebHostname()}/sign-up?referral=${referralId}`;
};

export const getPageUrl = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return isProduction ? 'https://app.air.inc' : process.env.NEXT_PUBLIC_VERCEL_URL || 'http://localhost:3000';
};
