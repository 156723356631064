import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackViewedAsset = {
  assetId: string;
  clip_id: string;
  isDemo: boolean;
};

export const useTrackViewedAsset = () => {
  const { track } = useAnalyticsUtilities();

  /**
   * This event will track when the user views an asset
   * @returns {function} This function should be called when the asset is no longer viewed. This allows the event to track how long the asset was viewed for
   */
  const trackViewedAsset = useCallback(
    (params: TrackViewedAsset) => {
      track(`Viewed an asset`, params);
    },
    [track],
  );

  return { trackViewedAsset };
};
