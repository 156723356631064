'use client';

import { Account } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useIsLoggedIn } from './useIsLoggedIn';

export const ACCOUNT_KEY = 'ACCOUNT';

export const getAccountKey = () => {
  return [ACCOUNT_KEY];
};

export const useAccount = () => {
  const { isLoggedIn } = useIsLoggedIn();

  return useQuery({
    queryKey: getAccountKey(),
    queryFn: Account.get,
    staleTime: Infinity,
    enabled: isLoggedIn,
  });
};
