import { BillingCyclePricing } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

interface TrackSelectedPlanParams {
  billingCycle: BillingCyclePricing['id'];
  planPrice: BillingCyclePricing['planPrice'];
}

export const useTrackSelectedPlan = () => {
  const { track } = useAnalyticsUtilities();

  const trackSelectedPlan = useCallback(
    (params: TrackSelectedPlanParams) => {
      track(`Selected plan in checkout`, params);
    },
    [track],
  );

  return { trackSelectedPlan };
};
