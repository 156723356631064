import { Comment } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { BaseEditCommentParams } from '../types';

export type EditedCommentParams = BaseEditCommentParams & {
  comment: Comment['body'];
};
export const useTrackEditedComment = () => {
  const { track } = useAnalyticsUtilities();

  const trackEditedComment = useCallback(
    (params: EditedCommentParams) => {
      track(`Edited a comment`, params);
    },
    [track],
  );

  return { trackEditedComment };
};
