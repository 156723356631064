import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackGeneratedShareUrl = () => {
  const { track } = useAnalyticsUtilities();

  const trackGeneratedShareUrl = useCallback(
    (params: { id: string; shareId?: string; type: 'clip' | 'board' }) => {
      track(`Generated a share url`, params);
    },
    [track],
  );

  return { trackGeneratedShareUrl };
};
