import { forwardRef, SVGProps } from 'react';

export interface FigmaCloseIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaCloseIcon = forwardRef<SVGSVGElement, FigmaCloseIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M8.00002 7.29295L12.6465 2.64648L13.3536 3.35359L8.70713 8.00006L13.3536 12.6465L12.6465 13.3536L8.00002 8.70716L3.35359 13.3536L2.64648 12.6465L7.29291 8.00006L2.64649 3.35367L3.35359 2.64656L8.00002 7.29295Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaCloseIcon.displayName = 'FigmaCloseIcon';
