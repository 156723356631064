import { OAuthConfiguration } from '@air/api';
import { ANONYMOUS_OAUTH_CONFIG, OAUTH_CONFIG } from '@air/utils-auth';

// Cypress can't read env vars from process.env so we need to pass them in using Cypress.env
const cypressAuthConfig: OAuthConfiguration = {
  ...OAUTH_CONFIG,
  clientId: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_OAUTH_CLIENT_ID'),
  domain: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_OAUTH_DOMAIN'),
  redirectSignIn: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_OAUTH_SIGNIN_REDIRECT'),
  redirectSignOut: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_OAUTH_SIGNOUT_REDIRECT'),
};

export const getOauthConfig = (): OAuthConfiguration =>
  typeof Cypress === 'undefined' ? OAUTH_CONFIG : cypressAuthConfig;

const cypressAnonymousOAuthConfig: OAuthConfiguration = {
  ...ANONYMOUS_OAUTH_CONFIG,
  clientId: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_ANONYMOUS_OAUTH_CLIENT_ID'),
};

export const getAnonymousOAuthConfig = (): OAuthConfiguration =>
  typeof Cypress === 'undefined' ? ANONYMOUS_OAUTH_CONFIG : cypressAnonymousOAuthConfig;
