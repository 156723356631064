import { forwardRef, SVGProps } from 'react';

export interface TimerProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Timer = forwardRef<SVGSVGElement, TimerProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.5 8.92731C7.13352 8.77896 6.875 8.41967 6.875 8C6.875 7.58033 7.13352 7.22104 7.5 7.07269V4.42531C7.5 4.21803 7.66645 4.04999 7.875 4.04999C8.08211 4.04999 8.25 4.21672 8.25 4.42531V7.07269C8.61648 7.22104 8.875 7.58033 8.875 8C8.875 8.41967 8.61648 8.77896 8.25 8.92731V9.67466C8.25 9.88195 8.08355 10.05 7.875 10.05C7.66789 10.05 7.5 9.88325 7.5 9.67466V8.92731ZM7.00792 2.58924C7.00272 2.56031 7 2.53049 7 2.5C7 2.22386 7.22168 2 7.50468 2H8.49532C8.77405 2 9 2.22193 9 2.5C9 2.53046 8.9973 2.56029 8.99212 2.58925C11.5561 3.05634 13.5 5.30117 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8C2.5 5.30115 4.44389 3.05632 7.00792 2.58924ZM8 12.7C10.5957 12.7 12.7 10.5957 12.7 8C12.7 5.40426 10.5957 3.3 8 3.3C5.40426 3.3 3.3 5.40426 3.3 8C3.3 10.5957 5.40426 12.7 8 12.7ZM11.634 3.19971C11.4771 3.04285 11.4755 2.79015 11.6328 2.63285C11.789 2.47664 12.0428 2.47714 12.1997 2.63403L13.3994 3.83375C13.5563 3.99061 13.5579 4.24332 13.4006 4.40062C13.2444 4.55683 12.9906 4.55633 12.8337 4.39944L11.634 3.19971Z"
    />
  </svg>
));
Timer.displayName = 'Timer';
