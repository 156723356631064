import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import LogRocket from 'logrocket';
import React from 'react';

/**
 * This if prevents Bugnsnag from possibly running more than once.
 * This usually happens while in the dev environment.
 * @see https://github.com/bugsnag/bugsnag-js/issues/811#issuecomment-703853628
 * */
// @ts-ignore
if (!Bugsnag._client) {
  if (process.env.NEXT_PUBLIC_BUGSNAG) {
    Bugsnag.start({
      releaseStage: process.env.NEXT_PUBLIC_VERCEL_ENV,
      appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG,
      plugins: [new BugsnagPluginReact(React)],
      onError: (event) => {
        // events to ignore
        if (event.originalError === 'ResizeObserver loop limit exceeded') {
          return false;
        }

        if (typeof window !== 'undefined') {
          // if we're working locally, don't report bugs to bugsnag
          if (window.location.hostname === 'localhost') {
            return false;
          }

          if (window.hasLogRocketInitialized) {
            event.addMetadata('LogRocket', {
              sessionURL: LogRocket.sessionURL,
            });
          }

          const isInIframe = window.top !== window.self;
          if (isInIframe) {
            event.addMetadata('isInIframe', {
              isInIframe,
              // document.location.ancestorOrigins is not supported on Firefox, I couldn't find a replacement
              parentLocation: document.location.ancestorOrigins ? document.location.ancestorOrigins[0] : 'Unknown',
            });
          }
        }
      },
      logger: null,
    });
  } else {
    console.error('Bugsnag API key not found. Please set NEXT_PUBLIC_BUGSNAG in your .env file.');
  }
}

export default Bugsnag;
