import type { NotificationType } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackNavigatedToItemFromNotification = {
  type: NotificationType;
};

export const useTrackNavigatedToItemFromNotification = () => {
  const { track } = useAnalyticsUtilities();

  const trackNavigatedToItemFromNotification = useCallback(
    (params: TrackNavigatedToItemFromNotification) => {
      track(`Navigated to item from notification`, params);
    },
    [track],
  );

  return { trackNavigatedToItemFromNotification };
};
