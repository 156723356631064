import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackToggledSamlSso = {
  location: AirActionTrackingLocation;
  enabled?: boolean;
  enforce?: boolean;
};

export const useTrackToggledSamlSso = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledSamlSso = useCallback(
    (params: TrackToggledSamlSso) => {
      track(`Toggled SAML SSO`, params);
    },
    [track],
  );

  return { trackToggledSamlSso };
};
