import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackEditedSessionDurationUnit.meta';

export type UseTrackEditedSessionDurationUnitParams = {
  session_duration_time: number;
  session_duration_unit: 'day' | 'hr' | 'min';
};

export const useTrackEditedSessionDurationUnit = () => {
  const { track } = useAnalyticsUtilities();

  const trackEditedSessionDurationUnit = useCallback(
    (params: UseTrackEditedSessionDurationUnitParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackEditedSessionDurationUnit };
};
