import { forwardRef, SVGProps } from 'react';

export interface SyncProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Sync = forwardRef<SVGSVGElement, SyncProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M5.66 15.71A10.36 10.36 0 0116 5.64h.28a10.25 10.25 0 015.35 1.66h-1.95a1.08 1.08 0 00-.83.3 1.13 1.13 0 00-.38.8v.11a1.21 1.21 0 001.11 1.21h4.65a1.61 1.61 0 001.07-.54 1.62 1.62 0 00.29-1l-.66-4.61a1.1 1.1 0 00-.38-.77 1.13 1.13 0 00-.81-.28 1.15 1.15 0 00-1.05 1.24.19.19 0 000 .08l.15 1.56A12.63 12.63 0 003.38 15.7a15.55 15.55 0 00.19 2.45 1.16 1.16 0 001.14.93h.18a1.21 1.21 0 00.91-1.42 9.7 9.7 0 01-.14-1.95zm22.77-1.85a1.16 1.16 0 00-1.32-.92 1.2 1.2 0 00-.91 1.42 9.7 9.7 0 01.14 1.94A10.34 10.34 0 0116 26.36h-.28a10.25 10.25 0 01-5.35-1.66h2a1.16 1.16 0 001.16-1.1v-.11a1.21 1.21 0 00-1.11-1.21H7.8a1.62 1.62 0 00-1.1.53 1.66 1.66 0 00-.29 1l.71 4.61a1.1 1.1 0 00.38.77 1.14 1.14 0 00.74.28h.1a1.19 1.19 0 00.78-.4 1.14 1.14 0 00.27-.84.19.19 0 000-.08l-.24-1.55a12.64 12.64 0 0019.47-10.3 15.34 15.34 0 00-.19-2.44zM8.33 29.42z" />
  </svg>
));
Sync.displayName = 'Sync';
