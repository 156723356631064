import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackKanbanDragComplete = {
  dragContent: string[];
  destinationColumnName: string;
  destinationColumnId: string;
};

export const useTrackKanbanDragComplete = () => {
  const { track } = useAnalyticsUtilities();

  const trackKanbanDragComplete = useCallback(
    (params: TrackKanbanDragComplete) => {
      track(`Completed kanban drag`, params);
    },
    [track],
  );

  return { trackKanbanDragComplete };
};
