import { isString } from 'lodash';

const backup = console.error;

console.error = function filterWarnings(...args) {
  /**
   * Until we get off styled components, we keep getting this error: "React does not recognize the `__baseStyles`".
   * The recommended best way is to overwrite console.error. Unfortunately, the arg comes in with a variable so we can't filter
   * out this specific instance of this error which sucks.
   * @see https://stackoverflow.com/questions/64158705/ignore-certain-console-errors-warnings-in-react
   * @see https://github.com/facebook/react/issues/12567#issuecomment-379472819
   */
  const supressedWarnings = ['React does not recognize the `%s` prop on a DOM element'];

  if (!supressedWarnings.some((entry) => isString(args[0]) && args[0].includes(entry))) {
    backup.apply(console, args);
  }
};
