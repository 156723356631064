import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export type TrackChangedCustomFieldParams = TrackCustomFieldsActionParams & {
  customFieldId: string;
  valueId: string | null;
};

export const useTrackChangedCustomFieldValue = () => {
  const { track } = useAnalyticsUtilities();

  const trackChangedCustomFieldValue = useCallback(
    (params: TrackChangedCustomFieldParams) => {
      track(`Changed custom field`, params);
    },
    [track],
  );

  return { trackChangedCustomFieldValue };
};
