import { Airror } from '@air/errors';
import { isError, isNumber, isObject, isString } from 'lodash';

import { isObjectWithCode } from './isObjectWithCode';
import { isObjectWithMessage } from './isObjectWithMessage';

/**
 * @description takes an unknown object and returns an Error or Airror instance.
 */
export const convertUnknownToError = (error: unknown): Error | Airror => {
  if (error instanceof Airror) {
    return error;
  } else if (isError(error)) {
    return error;
  } else if (isString(error)) {
    return new Error(error);
  } else if (isNumber(error)) {
    return new Error(`${error}`);
  } else if (isObjectWithCode(error)) {
    return new Error(error.code);
  } else if (isObjectWithMessage(error)) {
    return new Error(error.message);
  } else if (isObject(error)) {
    return new Error(JSON.stringify(error));
  } else {
    console.warn('An unknown type error was handled', error);
    return new Error(`An unknown error occurred.`);
  }
};
