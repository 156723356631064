import { add } from 'date-fns';
import Cookies, { CookieAttributes } from 'js-cookie';
import { isString } from 'lodash';
import { useEffect } from 'react';

import { useRouterSelector } from '~/hooks/useRouterSelector';

export const REFERRAL_ID_COOKIE_NAME = 'air_referral_id';

const cookieOpts: CookieAttributes = { expires: add(new Date(), { months: 1 }) };

/**
 * On mount, check whether query param for referral exists
 * and save it to cookie in case it wasn't there before
 */
export const usePersistReferral = () => {
  const referral = useRouterSelector((location) => {
    const params = new URLSearchParams(location.search);

    return params.get('referral');
  });

  useEffect(() => {
    const referralCookie = Cookies.get(REFERRAL_ID_COOKIE_NAME);
    const referralId = isString(referral) ? referral : undefined;

    if (referralId && !referralCookie) {
      Cookies.set(REFERRAL_ID_COOKIE_NAME, referralId, cookieOpts);
    }
  }, [referral]);
};
