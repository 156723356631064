import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackReorderedVersions = () => {
  const { track } = useAnalyticsUtilities();

  const trackReorderedVersions = useCallback(() => {
    track(`Versions reordered`);
  }, [track]);

  return { trackReorderedVersions };
};
