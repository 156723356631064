import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackAddedCustomFieldToForm.meta';

export type AddedCollectionFormCustomField = {
  fieldId?: string;
  fieldName: string;
};

export const useTrackAddedCustomFieldToForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackAddedCustomFieldToForm = useCallback(
    (params: AddedCollectionFormCustomField) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackAddedCustomFieldToForm };
};
