import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedToOpenImportFromDeviceModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedToOpenImportFromDeviceModal = useCallback(() => {
    track(`Clicked to open Import from Device modal`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackClickedToOpenImportFromDeviceModal };
};
