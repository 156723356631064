import { Meta } from '../types/docs';

const META: Meta = {
  name: 'account_api_key_delete',
  description: 'Tracks the deletion of the API key',
  params: [
    {
      name: 'api_key_id',
      description: 'The ID of the API key',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
