import { forwardRef, SVGProps } from 'react';

export interface BellProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Bell = forwardRef<SVGSVGElement, BellProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M12.4 29a2.92 2.92 0 01-2.05-.84A10.61 10.61 0 018 23.59a13.23 13.23 0 00-1.82-3.95.57.57 0 01-.08-.13l-.1-.13-.15-.25C2.77 15.21 2.16 11.06 4.13 7.6a2.42 2.42 0 01.3-3.18 2.42 2.42 0 013.16-.3C11 2.19 15.14 2.77 19.2 5.76l.27.17.13.07.14.08a12.7 12.7 0 003.89 1.83 10.52 10.52 0 014.54 2.38 3 3 0 010 4.12l-5.45 5.5.22.23A3.64 3.64 0 0124 22.79a3.39 3.39 0 01-1.1 2.42 3.74 3.74 0 01-2.59 1 3.36 3.36 0 01-2.41-1.11l-.2-.2-3.25 3.23a2.89 2.89 0 01-2 .84zM8 18.47A15.32 15.32 0 0110.11 23a8.26 8.26 0 001.79 3.59.73.73 0 00.5.2.74.74 0 00.51-.2l7.5-7.47 6.2-6.26a.78.78 0 000-1.05A8.19 8.19 0 0023.07 10a15 15 0 01-4.53-2.11l-.29-.18-.12-.08-.13-.07C15.87 6 11.81 3.78 8.14 6.36l-.5.42-1.29 1.39c-1.89 2.76-1.42 6.28 1.28 9.68a.8.8 0 01.13.19.74.74 0 00.08.13 2.64 2.64 0 01.16.3zm11.27 4.93l.22.23a1.16 1.16 0 00.88.42 1.42 1.42 0 001-.42 1.23 1.23 0 00.43-.9 1.44 1.44 0 00-.43-1l-.21-.22z" />
  </svg>
));
Bell.displayName = 'Bell';
