import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export type TrackChangedCustomFieldDateValueParams = TrackCustomFieldsActionParams & {
  customFieldId: string;
  dateValue?: string;
};

export const useTrackChangedCustomFieldDateValue = () => {
  const { track } = useAnalyticsUtilities();

  const trackChangedCustomFieldDateValue = useCallback(
    (params: TrackChangedCustomFieldDateValueParams) => {
      track(`Changed custom field (date)`, params);
    },
    [track],
  );

  return { trackChangedCustomFieldDateValue };
};
