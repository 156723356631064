import { WorkspaceMemberRoleDisplayName, WorkspaceMemberType } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type InvitedMember = {
  location: 'fat-nav' | 'workspace-settings' | 'workspace-dropdown' | 'header' | 'board';
  role?: WorkspaceMemberRoleDisplayName;
  type?: WorkspaceMemberType;
  invitorRole?: WorkspaceMemberRoleDisplayName;
};

export const useTrackInvitedMember = () => {
  const { track } = useAnalyticsUtilities();

  const trackInvitedMember = useCallback(
    (params: InvitedMember) => {
      track(`Invited member`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });
    },
    [track],
  );

  return { trackInvitedMember };
};
