import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export const useTrackClickedEditCustomField = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedEditCustomField = useCallback(
    (params: TrackCustomFieldsActionParams) => {
      track(`Clicked Edit custom field`, params);
    },
    [track],
  );

  return { trackClickedEditCustomField };
};
