import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedLogInForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedLogInForm = useCallback(() => {
    track(`Viewed Log in Form`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackViewedLogInForm };
};
