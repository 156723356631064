import { Workspace } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AuthInfo } from '../types';
import { trackGTMEvent } from '../utils/trackGTMEvent';

export interface ActivatedAccount extends AuthInfo {
  creationSource: 'main' | 'workspace-invite' | 'share-link' | 'canva' | null;
  workspaceId?: Workspace['id'];
  workspaceName?: Workspace['name'];
}

export const useTrackActivatedAccount = () => {
  const { track, alias, identify } = useAnalyticsUtilities();

  const trackActivatedAccount = useCallback(
    (params: ActivatedAccount) => {
      const { id, email, firstName, lastName } = params;

      // Creates new alias in Mixpanel to current distinct ID
      alias(id);

      // there can be race conditions in Mixpanel with calling alias and identify too close to each other
      setTimeout(() => {
        identify(id, {
          first_name: firstName,
          last_name: lastName,
          email: email,
        });
      }, 1000);

      // TODO: this might need an Intercom('update') call

      track(`Activated account`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });

      trackGTMEvent({ event: 'Free Account Sign Up', eventProps: { label: 'free', category: 'self serve', value: 0 } });
    },
    [alias, track, identify],
  );

  return { trackActivatedAccount };
};
