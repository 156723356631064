import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AuthInfo } from '../types';

export const useTrackLoggedIn = () => {
  const { track, identify } = useAnalyticsUtilities();

  const trackLoggedIn = useCallback(
    (params: AuthInfo) => {
      const { id, email, firstName, lastName } = params;

      identify(id, {
        first_name: firstName,
        last_name: lastName,
        email: email,
      });

      track(`Logged in`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });
    },
    [identify, track],
  );

  return { trackLoggedIn };
};
