import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackSearchSuggestionClickedParams = {
  type: 'text' | 'board' | 'saved-filter' | string;
  text: string;
};

export const useTrackSearchSuggestionClicked = () => {
  const { track } = useAnalyticsUtilities();

  const trackSearchSuggestionClicked = useCallback(
    (params: TrackSearchSuggestionClickedParams) => {
      track(`Search suggestion clicked`, params);
    },
    [track],
  );

  return { trackSearchSuggestionClicked };
};
