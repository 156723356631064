import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedCollectContent = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedCollectContent = useCallback(() => {
    track(`Track clicked "Collect content"`);
  }, [track]);

  return { trackClickedCollectContent };
};
