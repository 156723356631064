import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackCommentModeToggledOn = () => {
  const { track } = useAnalyticsUtilities();

  const trackCommentModeToggledOn = useCallback(() => {
    track(`Comment mode toggled on`);
  }, [track]);

  return { trackCommentModeToggledOn };
};
