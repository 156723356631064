import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackCommentModeToggledOff = () => {
  const { track } = useAnalyticsUtilities();

  const trackCommentModeToggledOff = useCallback(() => {
    track(`Comment mode toggled off`);
  }, [track]);

  return { trackCommentModeToggledOff };
};
