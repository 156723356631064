import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackFiltersApplied } from '../types';

export const useTrackFiltersRemoved = () => {
  const { track } = useAnalyticsUtilities();

  const trackFiltersRemoved = useCallback(
    (params: TrackFiltersApplied) => {
      track(`Filters removed`, params);
    },
    [track],
  );

  return { trackFiltersRemoved };
};
