import { Workspace } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackAccountApiWorkspaceIdCopy.meta';

export type UseTrackAccountApiWorkspaceIdCopyParams = {
  workspace_id: Workspace['id'];
};

export const useTrackAccountApiWorkspaceIdCopy = () => {
  const { track } = useAnalyticsUtilities();

  const trackAccountApiWorkspaceIdCopy = useCallback(
    (params: UseTrackAccountApiWorkspaceIdCopyParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackAccountApiWorkspaceIdCopy };
};
