import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackSelectedParams = {
  location: 'discussions tab' | 'video timeline';
  has_timestamp: boolean;
  has_annotation: boolean;
};

export const useTrackSelectedComment = () => {
  const { track } = useAnalyticsUtilities();

  const trackSelectedComment = useCallback(
    (params: TrackSelectedParams) => {
      track(`Selected a comment`, params);
    },
    [track],
  );

  return { trackSelectedComment };
};
