import { ApiKey } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackAccountApiKeyCopy.meta';

export type UseTrackAccountApiKeyCopyParams = {
  api_key_id: ApiKey['id'];
};

export const useTrackAccountApiKeyCopy = () => {
  const { track } = useAnalyticsUtilities();

  const trackAccountApiKeysCopy = useCallback(
    (params: UseTrackAccountApiKeyCopyParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackAccountApiKeysCopy };
};
