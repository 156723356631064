import { ApiKey } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackAccountApiKeyCreate.meta';

export type UseTrackAccountApiKeyCreateParams = {
  api_key_id: ApiKey['id'];
  api_key_name: ApiKey['name'];
};

export const useTrackAccountApiKeyCreate = () => {
  const { track } = useAnalyticsUtilities();

  const trackAccountApiKeyCreate = useCallback(
    (params: UseTrackAccountApiKeyCreateParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackAccountApiKeyCreate };
};
