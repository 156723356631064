import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackCopiedPublicClipParams = {
  clipId: string;
  workspaceId: string;
  location: AirActionTrackingLocation;
};

export const useTrackCopiedPublicClip = () => {
  const { track } = useAnalyticsUtilities();

  const trackCopiedPublicClip = useCallback(
    (params: TrackCopiedPublicClipParams) => {
      track(`copied_public_clip`, params);
    },
    [track],
  );

  return { trackCopiedPublicClip };
};
