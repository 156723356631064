import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackTagUpdated } from '../types';

export const useTrackTagSearchInitiated = () => {
  const { track } = useAnalyticsUtilities();

  const trackTagSearchInitiated = useCallback(
    (params: TrackTagUpdated) => {
      track(`Tag search initiated`, params);
    },
    [track],
  );

  return { trackTagSearchInitiated };
};
