import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

type ViewedWorkspaceSettingsParams = { location: 'current-workspace-nav' | 'workspace-popover' };

export const useTrackViewedWorkspaceSettings = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedWorkspaceSettings = useCallback(
    (params: ViewedWorkspaceSettingsParams) => {
      track(`Viewed workspace settings`, params);
    },
    [track],
  );

  return { trackViewedWorkspaceSettings };
};
