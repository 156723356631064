import { forwardRef, SVGProps } from 'react';

export interface BrandfolderLogoProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const BrandfolderLogo = forwardRef<SVGSVGElement, BrandfolderLogoProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 500 500">
    <defs>
      <clipPath id="a" transform="translate(0 .24)">
        <rect width="312" height="294" x="103.47" y="103" fill="none" />
      </clipPath>
    </defs>
    <rect width="500" height="500" fill="none" />
    <g clip-path="url(#a)">
      <path
        fill="#51c8ec"
        d="M245.9,234H235.58V189.15a68.67,68.67,0,0,1,11-.7,59.89,59.89,0,0,1,9.42.8,22.42,22.42,0,0,1,8.61,3.31,18,18,0,0,1,6.31,6.81,22.87,22.87,0,0,1,2.4,11.12,28.11,28.11,0,0,1-2.1,11.62,18,18,0,0,1-5.81,7.21,22.57,22.57,0,0,1-8.81,3.7,58.34,58.34,0,0,1-10.72,1m-10.32,74.22V259.67h14a67.71,67.71,0,0,1,13.22,1.2,27.21,27.21,0,0,1,10.31,4.1,18.32,18.32,0,0,1,6.61,7.72,28.11,28.11,0,0,1,2.31,12c0,5.1-1,9.31-3,12.52a22.14,22.14,0,0,1-7.71,7.51,30.07,30.07,0,0,1-10,3.6,62.73,62.73,0,0,1-10.32.9c-2.31,0-4.91-.1-7.81-.2s-5.41-.5-7.62-.8M201.73,165.82c5-.81,10.72-1.41,17.33-1.91s14.72-.7,24.54-.7a134.29,134.29,0,0,1,24.24,2.1,61,61,0,0,1,19.93,7.12,37.44,37.44,0,0,1,13.42,13.42c3.3,5.61,4.91,12.62,4.91,21a41.59,41.59,0,0,1-2.21,13.92A40.74,40.74,0,0,1,298,232a36.44,36.44,0,0,1-8.41,7.91,21.81,21.81,0,0,1-9.51,3.81A53.47,53.47,0,0,1,292,247.05a38.83,38.83,0,0,1,12.12,7.61,39.31,39.31,0,0,1,9.32,12.92c2.5,5.31,3.7,11.62,3.7,19.13,0,10.12-1.9,18.43-5.7,24.84A41.91,41.91,0,0,1,296,326.87a66,66,0,0,1-22.44,7.72,164.57,164.57,0,0,1-26.74,2.1c-7.21,0-14.22-.2-21.23-.6s-14.93-1.1-23.84-2.21V165.82Zm-98.26,231H358.28V358h-216V142H358.38V283.8H339.85l37.76,37.86,37.86-37.86H397V103.21H103.47Z"
        transform="translate(0 .24)"
      />
    </g>
  </svg>
));
BrandfolderLogo.displayName = 'BrandfolderLogo';
