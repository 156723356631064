import { forwardRef, SVGProps } from 'react';

export interface SupportProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Support = forwardRef<SVGSVGElement, SupportProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M27.93 15.94a12.25 12.25 0 00-.64-3.89 5.77 5.77 0 001-3.2A5.85 5.85 0 0022.42 3 5.76 5.76 0 0019 4.12 12 12 0 0012.78 4a5.79 5.79 0 00-3.22-1 5.85 5.85 0 00-5.85 5.85 5.76 5.76 0 00.54 2.45 12.15 12.15 0 00-.92 4.64 12.29 12.29 0 00.94 4.71 5.83 5.83 0 008.63 7.28 12.28 12.28 0 002.73.31 12 12 0 003.25-.45A5.76 5.76 0 0022.42 29a5.85 5.85 0 005.85-5.85 5.78 5.78 0 00-1-3.25 12.06 12.06 0 00.66-3.96zm-12.3-3.08a3.08 3.08 0 11-3.08 3.08 3.09 3.09 0 013.08-3.08zM17.34 21a5.34 5.34 0 003.37-3.45l4.68.72a10.06 10.06 0 01-7.32 7.42zm3.47-6.31a5.32 5.32 0 00-3.42-3.75l.73-4.7A10.06 10.06 0 0125.47 14zm-7.13-8.6l.72 4.66a5.34 5.34 0 00-3.92 3.84l-4.67-.72a10.07 10.07 0 017.87-7.78zm-3.1 11.52a5.35 5.35 0 003.87 3.54l-.72 4.64a10.05 10.05 0 01-7.84-7.46zm11.84-13.1a4.34 4.34 0 014.34 4.34 4.26 4.26 0 01-.25 1.37 12.35 12.35 0 00-5.68-5.41 4.28 4.28 0 011.59-.3zM5.21 8.85a4.29 4.29 0 015.49-4.17 12.38 12.38 0 00-5.45 4.69 4.38 4.38 0 01-.04-.52zm4.35 18.64a4.34 4.34 0 01-4.35-4.34 4.21 4.21 0 010-.62 12.45 12.45 0 005.58 4.74 4.25 4.25 0 01-1.23.22zm17.2-4.34a4.34 4.34 0 01-4.34 4.34 4.21 4.21 0 01-1.72-.36 12.32 12.32 0 005.8-5.45 4.29 4.29 0 01.26 1.47z" />
  </svg>
));
Support.displayName = 'Support';
