import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackCreatedLibrary.meta';

export type UseTrackCreatedLibraryParams = {
  library_accessibility?: Library['accessibility'];
  library_name: Library['title'];
  library_visibility?: Library['visibility'];
};

export const useTrackCreatedLibrary = () => {
  const { track } = useAnalyticsUtilities();

  const trackCreatedLibrary = useCallback(
    (params: UseTrackCreatedLibraryParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackCreatedLibrary };
};
