import { Clip, Tag, UnmadeTag } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackRemovedAssetsTagsParams = {
  assets: Clip['id'][];
  tags: (Tag | UnmadeTag)[];
  location: AirActionTrackingLocation;
};

export const useTrackRemovedTagsFromAssets = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedTagsFromAssets = useCallback(
    (params: TrackRemovedAssetsTagsParams) => {
      track(`Removed tags from assets`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackRemovedTagsFromAssets };
};
