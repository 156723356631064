import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackKanbanDrag = {
  dragContent: string[];
  originColumnName: string;
  originColumnId: string;
};

export const useTrackKanbanDragStart = () => {
  const { track } = useAnalyticsUtilities();

  const trackKanbanDragStart = useCallback(
    (params: TrackKanbanDrag) => {
      track(`Started kanban drag`, params);
    },
    [track],
  );

  return { trackKanbanDragStart };
};
