import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export type TrackChangedCustomFieldPlainTextParams = TrackCustomFieldsActionParams & {
  customFieldId: string;
};

export const useTrackChangedCustomFieldPlainTextValue = () => {
  const { track } = useAnalyticsUtilities();

  const trackChangedCustomFieldPlainTextValue = useCallback(
    (params: TrackChangedCustomFieldPlainTextParams) => {
      track(`Changed custom field (plain-text)`, params);
    },
    [track],
  );

  return { trackChangedCustomFieldPlainTextValue };
};
