import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAssetsMerged = () => {
  const { track } = useAnalyticsUtilities();

  const trackAssetsMerged = useCallback(() => {
    track(`Version stack created via drag and drop`);
  }, [track]);

  return { trackAssetsMerged };
};
