import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackToggledInactivityLogout.meta';

export type useTrackToggledInactivityLogoutParams = {
  inactivity_logout_enabled: boolean;
  inactivity_logout_timeout_duration: number;
};

export const useTrackToggledInactivityLogout = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledInactivityLogout = useCallback(
    (params: useTrackToggledInactivityLogoutParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackToggledInactivityLogout };
};
