import { Board, ViewTypeName } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackViewedBoard.meta';

export type TrackViewedBoardParams = Pick<Board, 'isDemo'> & {
  board_view?: ViewTypeName;
  board_id: Board['id'];
  board_title: Board['title'];
};

export const useTrackViewedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedBoard = useCallback(
    (params: TrackViewedBoardParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackViewedBoard };
};
