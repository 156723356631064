import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedToOpenImportFromDropboxModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedToOpenImportFromDropboxModal = useCallback(() => {
    track(`Clicked to open Import from Dropbox modal`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackClickedToOpenImportFromDropboxModal };
};
