import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackClickedConnectToSlackOptionParams = {
  boardTitle: Board['title'];
  trackLocation: AirActionTrackingLocation | 'board-header-ellipsis' | 'edit-board-details-modal';
};

/**
 * Tracks when a user clicks 'Connect to Slack'
 */
export const useTrackClickedConnectToSlackOption = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedConnectToSlackOption = useCallback(
    (params: TrackClickedConnectToSlackOptionParams) => {
      track('Clicked "connect to slack" option', params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackClickedConnectToSlackOption };
};
