import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackSavedFilter } from '../types';

export const useTrackSavedFilterModified = () => {
  const { track } = useAnalyticsUtilities();

  const trackSavedFilterModified = useCallback(
    (params: TrackSavedFilter) => {
      track(`Modified saved filter`, params);
    },
    [track],
  );

  return { trackSavedFilterModified };
};
