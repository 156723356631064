import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackFollowedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackFollowedBoard = useCallback(
    (params: { boardId: string }) => {
      track(`Followed Board`, params);
    },
    [track],
  );

  return { trackFollowedBoard };
};
