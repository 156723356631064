import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackUpdateApprovedDomains = {
  addedDomains: string[];
  removedDomains: string[];
};

export const useTrackUpdateApprovedDomains = () => {
  const { track } = useAnalyticsUtilities();

  const trackUpdateApprovedDomains = useCallback(
    (params: TrackUpdateApprovedDomains) => {
      track(`Updated approved domains`, params);
    },
    [track],
  );

  return { trackUpdateApprovedDomains };
};
