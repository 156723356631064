import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { ImportModalEvent } from '../types';

export const useTrackClickedLinkImportAccount = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedLinkImportAccount = useCallback(
    (params: ImportModalEvent) => {
      track(`Clicked Link Account in Import Modal`, params);
    },
    [track],
  );

  return { trackClickedLinkImportAccount };
};
