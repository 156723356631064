import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackFiltersApplied } from '../types';

export const useTrackFiltersApplied = () => {
  const { track } = useAnalyticsUtilities();

  const trackFiltersApplied = useCallback(
    (params: TrackFiltersApplied) => {
      track(`Filters applied`, params);
    },
    [track],
  );

  return { trackFiltersApplied };
};
