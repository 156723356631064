import { forwardRef, SVGProps } from 'react';

export interface SelectedProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Selected = forwardRef<SVGSVGElement, SelectedProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M26.92 29h-3.38a1.09 1.09 0 110-2.18h3.28v-2.38a1.09 1.09 0 112.18 0v2.48A2.08 2.08 0 0126.92 29zM19 29h-6a1.09 1.09 0 010-2.18h6A1.09 1.09 0 0119 29zM8.46 29H5.08A2.08 2.08 0 013 26.92v-2.48a1.09 1.09 0 112.18 0v2.38h3.28a1.09 1.09 0 110 2.18zm19.45-8.93A1.09 1.09 0 0126.82 19v-6A1.09 1.09 0 0129 13v6a1.09 1.09 0 01-1.09 1.07zm-23.82 0A1.09 1.09 0 013 19v-6a1.09 1.09 0 012.18 0v6a1.09 1.09 0 01-1.09 1.07zM27.91 8.66a1.09 1.09 0 01-1.09-1.1V5.18h-3.28a1.09 1.09 0 110-2.18h3.38A2.08 2.08 0 0129 5.08v2.48a1.09 1.09 0 01-1.09 1.1zm-23.82 0A1.09 1.09 0 013 7.56V5.08A2.08 2.08 0 015.08 3h3.38a1.09 1.09 0 010 2.18H5.18v2.38a1.09 1.09 0 01-1.09 1.1zM19 5.18h-6A1.09 1.09 0 0113 3h6a1.09 1.09 0 010 2.18zm-4.85 16.28a1.19 1.19 0 01-.91-.42l-3.13-3.7a1.19 1.19 0 011.82-1.54L14 18.23a39.43 39.43 0 012.8-3.85 25 25 0 013.47-3.6 1.19 1.19 0 111.43 1.91 23.83 23.83 0 00-3.07 3.22 37 37 0 00-3.43 4.94 1.18 1.18 0 01-.92.6z" />
  </svg>
));
Selected.displayName = 'Selected';
