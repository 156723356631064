import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackAddMenuOption } from '../types';

export const useTrackClickedAddContent = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedAddContent = useCallback(
    (params: TrackAddMenuOption) => {
      track(`Opened add content menu`, params);
    },
    [track],
  );

  return { trackClickedAddContent };
};
