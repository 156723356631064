import { IDP_ACCOUNT_EXISTS } from '@air/errors';
import { convertUnknownToError, reportErrorToBugsnag } from '@air/utils-error';

export const getLoginError = ({ email, error }: { email: string; error: unknown }) => {
  const _error = convertUnknownToError(error);

  if (_error.message.includes(IDP_ACCOUNT_EXISTS.message)) {
    return 'No password on this account.';
  }

  if (_error.message.includes('NotAuthorizedException')) {
    return 'Incorrect username or password.';
  }

  if (_error.message.includes('UserNotFoundException')) {
    return 'User does not exist.';
  }

  reportErrorToBugsnag({
    error,
    context: 'Failed to login',
    metadata: {
      Data: { emailFromAttemptedLogin: email },
    },
  });

  return _error.message;
};
