import { forwardRef, SVGProps } from 'react';

export interface AProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const A = forwardRef<SVGSVGElement, AProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M13.482 6.24578C15.1083 5.16161 15.8052 5.39393 15.8052 7.09763C15.8052 8.56901 15.418 10.0404 15.418 10.0404H16.9668C16.9668 10.0404 17.2766 8.41413 17.2766 7.09763C17.354 4.54209 16.115 4 15.1083 4C13.0948 4 10.2295 6.32323 6.43489 11.0471C5.04096 12.8283 3.56958 14.9192 2.64029 16.2357C1.711 17.5521 1.86583 18.1723 2.64002 18.1723C3.4142 18.1723 6.89954 12.9057 9.22276 10.1953C10.9265 8.25925 12.3978 6.94275 13.482 6.24578Z"
    />
    <path
      fill="currentColor"
      d="M8.67977 12.8278C7.13096 14.1443 6.97607 15.6157 6.97607 16.0029C6.97607 17.7066 8.29257 19.1005 9.99627 19.1779C10.0737 19.1779 10.0737 19.1779 10.1511 19.1779C14.6427 19.1779 16.1915 13.215 16.269 12.9052C16.269 12.9052 14.7202 12.8278 14.6427 12.9827C14.5653 13.1376 14.4878 13.3699 14.4104 13.6022C13.636 15.6157 12.2421 17.6291 10.1511 17.6291C9.2993 17.6291 8.52489 17.0096 8.52489 16.0803V16.0029C8.52489 15.4608 8.83465 14.6864 9.6865 13.912C11.0804 12.7504 14.1006 11.5887 20.838 12.5955C20.838 12.5955 21.9999 12.8301 21.9999 11.9771C21.9999 11.1241 21.3026 11.1241 21.3026 11.1241C17.1208 10.5046 11.6225 10.3497 8.67977 12.8278Z"
    />
  </svg>
));
A.displayName = 'A';
