import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

enum AssetModalPanel {
  ANNOTATIONS = 'ANNOTATIONS',
  BOARDS = 'BOARDS',
  CAPTURED_TEXT = 'CAPTURED_TEXT',
  COMMENTS = 'COMMENTS',
  FIELDS = 'FIELDS',
  INFO = 'INFO',
  NONE = 'NONE',
  TRANSCRIPT = 'TRANSCRIPT',
  VERSIONS = 'VERSIONS',
  PEOPLE = 'PEOPLE',
}

export type TrackClickedAssetModalTab = {
  tab: AssetModalPanel | null;
  panelAction: 'open' | 'close' | 'switch';
};

export const useTrackClickedAssetModalTab = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedAssetModalTab = useCallback(
    (params: TrackClickedAssetModalTab) => {
      track(`Clicked asset modal tab`, params);
    },
    [track],
  );

  return { trackClickedAssetModalTab };
};
