import { config } from '@air/api';

import { configureAPIPackage } from './configureAPIPackage';

export const restoreAPIPackageConfiguration = (savedAPIConfig: ReturnType<typeof config.getAuthConfiguration>) => {
  configureAPIPackage({
    cognitoConfigParams: {
      userPoolId: savedAPIConfig.userPoolId,
      mandatorySignIn: savedAPIConfig.mandatorySignIn,
      authenticationFlowType: savedAPIConfig.authenticationFlowType,
      region: savedAPIConfig.region,
    },
    oauthConfigParams: savedAPIConfig.oauth,
  });
};
