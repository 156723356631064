import { forwardRef, SVGProps } from 'react';

export interface FigmaArrowLeftIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaArrowLeftIcon = forwardRef<SVGSVGElement, FigmaArrowLeftIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M2.29291 8.00004L2.64646 7.64648L5.64646 4.64648L6.35357 5.35359L4.20712 7.50004L12.5 7.50004L12.5 8.50004L4.20712 8.50004L6.35357 10.6465L5.64646 11.3536L2.64646 8.35359L2.29291 8.00004Z"
      clipRule="evenodd"
      fillOpacity="0.8"
      fillRule="evenodd"
    />
  </svg>
));

FigmaArrowLeftIcon.displayName = 'FigmaArrowLeftIcon';
