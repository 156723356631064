import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedCDNHelpCenterLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedCDNHelpCenterLink = useCallback(() => {
    track(`Clicked CDN Help Center link`);
  }, [track]);

  return { trackClickedCDNHelpCenterLink };
};
