import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackAppliedSearchTerm = {
  term: string;
};

export const useTrackAppliedSearchTerm = () => {
  const { track } = useAnalyticsUtilities();

  const trackAppliedSearchTerm = useCallback(
    (params: TrackAppliedSearchTerm) => {
      track(`Applied a search term`, params);
    },
    [track],
  );

  return { trackAppliedSearchTerm };
};
