import { forwardRef, SVGProps } from 'react';

export interface GearFilledProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const GearFilled = forwardRef<SVGSVGElement, GearFilledProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M28.49 18.79l-2-1.11a9.48 9.48 0 000-3.36l2-1.13a.93.93 0 00.3-.26 1.06 1.06 0 00.18-.35 1.07 1.07 0 000-.4 1 1 0 00-.13-.37l-2.73-4.65a1 1 0 00-1.35-.37L22.7 8a10.75 10.75 0 00-2.95-1.67V4a1 1 0 00-.29-.71 1.05 1.05 0 00-.71-.29h-5.5a1.05 1.05 0 00-.71.29 1 1 0 00-.29.71v2.3A10.59 10.59 0 009.3 8L7.24 6.79a1 1 0 00-1.35.37l-2.75 4.65a1 1 0 00-.13.37 1.07 1.07 0 000 .4 1.06 1.06 0 00.18.35.93.93 0 00.3.26l2 1.13a9.48 9.48 0 000 3.36l-2 1.11a1.06 1.06 0 00-.3.26 1.22 1.22 0 00-.18.36 1 1 0 000 .39 1.16 1.16 0 00.13.37l2.75 4.66a1 1 0 00.6.45 1 1 0 00.75-.09l2-1.11a10 10 0 003 1.74V28a1 1 0 00.29.71 1.05 1.05 0 00.71.29h5.5a1.05 1.05 0 00.71-.29 1 1 0 00.29-.71v-2.19a10.57 10.57 0 003-1.73l2 1.11a1 1 0 00.75.09 1 1 0 00.6-.45l2.75-4.66a1.16 1.16 0 00.16-.37 1 1 0 000-.39 1 1 0 00-.17-.36 1.2 1.2 0 00-.34-.26zm-9-2.8a3.52 3.52 0 01-2.11 3.34 3.54 3.54 0 01-3.89-.71 3.53 3.53 0 01.51-5.47 3.58 3.58 0 012-.58 3.43 3.43 0 011.34.25 3.38 3.38 0 011.13.73 3.64 3.64 0 01.78 1.12 3.56 3.56 0 01.28 1.33z" />
  </svg>
));
GearFilled.displayName = 'GearFilled';
