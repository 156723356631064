import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export type TrackChangedCustomFieldValuesParams = TrackCustomFieldsActionParams & {
  customFieldId: string;
  valueIds: string[];
  actionType: 'add' | 'remove';
};

export const useTrackChangedCustomFieldValues = () => {
  const { track } = useAnalyticsUtilities();

  const trackChangedCustomFieldValues = useCallback(
    (params: TrackChangedCustomFieldValuesParams) => {
      track(`Changed custom field (multi)`, params);
    },
    [track],
  );

  return { trackChangedCustomFieldValues };
};
