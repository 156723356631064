import { forwardRef, SVGProps } from 'react';

export interface PaletteProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Palette = forwardRef<SVGSVGElement, PaletteProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M16 29a13 13 0 01-2.35-.25A13.07 13.07 0 013.17 18.11a13 13 0 0115-14.94 12.75 12.75 0 0110.6 10.12c.67 3.88-.14 7.09-2.22 8.79a5.78 5.78 0 01-5.93.72c-.23-.09-.54-.15-.71 0a.64.64 0 00-.06.67 3.79 3.79 0 010 3.41 3.68 3.68 0 01-2.61 2A6.39 6.39 0 0116 29zm0-24A11 11 0 005.15 17.79a11.06 11.06 0 008.89 9 8.42 8.42 0 002.86.14 1.71 1.71 0 001.19-.93 1.75 1.75 0 000-1.64 2.61 2.61 0 01.38-2.89 2.54 2.54 0 012.89-.56 3.81 3.81 0 003.95-.39c1.49-1.22 2.06-3.81 1.53-6.92a10.74 10.74 0 00-8.94-8.48A12.77 12.77 0 0016 5zm-4.29 10.26a2.16 2.16 0 00-2.14-2.15h-.09a2.15 2.15 0 102.23 2.15zm2.94 6.45a2.16 2.16 0 00-2.15-2.15h-.08a2.15 2.15 0 102.23 2.15zm.82-12a2.16 2.16 0 00-2.15-2.15h-.08a2.15 2.15 0 102.23 2.15zm6.25.82a2.16 2.16 0 00-2.14-2.15h-.09a2.15 2.15 0 102.23 2.15zm3.44 5.83a2.16 2.16 0 00-2.16-2.1h-.09a2.15 2.15 0 102.23 2.15z" />
  </svg>
));
Palette.displayName = 'Palette';
