import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackCalendarBooking = () => {
  const { track } = useAnalyticsUtilities();

  const trackCalendarBooking = useCallback(() => {
    track(`Calendar Booking`);
  }, [track]);

  return { trackCalendarBooking };
};
