import { SubscriptionPlan } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackUpgradeToProFrom = {
  plan: SubscriptionPlan;
  location: AirActionTrackingLocation;
};

export const useTrackUpgradedToProFrom = () => {
  const { track } = useAnalyticsUtilities();

  const trackUpgradedToProFrom = useCallback(
    (params: TrackUpgradeToProFrom) => {
      track(`Clicked Upgrade Plan to Pro from this plan and location`, params);
    },
    [track],
  );
  return { trackUpgradedToProFrom };
};
