'use client';
import { Auth } from '@air/api';
import { type CognitoAttributes } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { type CognitoUser } from 'amazon-cognito-identity-js';

import { callOnFullAccountUserPool } from './callOnFullAccountUserPool';

/**
 * The CognitoUser type is incorrect and it's an open issue in the amplify library.
 * @see https://github.com/aws-amplify/amplify-js/issues/3316
 * @see https://github.com/aws-amplify/amplify-js/issues/3733
 */
export enum UserChallenge {
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  MFA_SETUP = 'MFA_SETUP',
}

export type UserCognitoInfo = {
  sub: string;
  email: string;
};

export type TrueCognitoType = CognitoUser & {
  challengeName?: UserChallenge;
  attributes?: CognitoAttributes;
};

export type LoginViaEmailParams = {
  /**
   * User email
   */
  username: string;
  /**
   * User password
   */
  password: string;
  /**
   * cognitoUser object - If this is available it should be passed.
   * Made available during sign up
   */
  cognitoUser?: CognitoUser;
};

export const loginViaEmail = async ({ username, password, cognitoUser }: LoginViaEmailParams) => {
  return callOnFullAccountUserPool(async () => {
    try {
      let user = cognitoUser as TrueCognitoType | undefined;

      if (!user) {
        user = await Auth.signIn(username, password);
      }

      if (user.challengeName === UserChallenge.NEW_PASSWORD_REQUIRED) {
        return { cognitoUser: user, updatePassword: true };
      } else {
        const userSession = user.getSignInUserSession();

        if (!userSession) throw new Error('no user session');

        const { payload } = userSession.getIdToken();
        const token = userSession.getIdToken().getJwtToken();
        const userInfo: UserCognitoInfo = {
          sub: payload.sub,
          email: payload.email,
        };

        return { cognitoUser: user, token, userInfo };
      }
    } catch (_error) {
      const error = reportErrorToBugsnag({ error: _error, context: 'Error logging in with Cognito' });

      if (error.message.includes('PasswordResetRequiredException')) {
        return { resetPassword: true };
      }

      throw error;
    }
  });
};
