'use client';
import type { Config } from 'tailwind-merge';
import type { TV } from 'tailwind-variants';
import { tv as variants } from 'tailwind-variants';

import { tailwindMergeConfig } from './mergeConfig';

export type { VariantProps } from 'tailwind-variants';

export type TailwindVariants = TV;

export const tailwindVariants: TV = (options, config) =>
  variants(options, {
    /**
     * Type casting is required here because the `tailwind-variant` marks the
     * `twMergeConfig` as required when it's not necessary. On L26-L28 of the
     * file linekd below, the `twMergeConfig` is using `extendTailwindMerge`
     * which does not require the all the values of `Config` to be present.
     *
     * @see https://github.com/nextui-org/tailwind-variants/blob/main/src/index.js#L26-L28
     */
    twMerge: true,
    twMergeConfig: tailwindMergeConfig as Config,
    ...config,
  });
