import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackRemovedAnAssetFromCollectionForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedAnAssetFromCollectionForm = useCallback(
    (params: { numberOfAssets: number }) => {
      track(`Removed an asset from collection form`, params);
    },
    [track],
  );

  return { trackRemovedAnAssetFromCollectionForm };
};
