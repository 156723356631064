import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackJoinedLibrary.meta';

export type UseTrackJoinedLibraryParams = {
  library_id: Library['id'];
};

export const useTrackJoinedLibrary = () => {
  const { track } = useAnalyticsUtilities();

  const trackJoinedLibrary = useCallback(
    (params: UseTrackJoinedLibraryParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackJoinedLibrary };
};
