import { Meta } from '../types/docs';

const META: Meta = {
  name: 'edited_inactivity_logout_duration',
  description: 'User edits inactivity logout duration (assumes feature is already enabled)',
  params: [
    {
      name: 'inactivity_logout_timeout_duration',
      description: 'Timeout period (in seconds)',
      required: true,
      type: 'number',
    },
  ],
};

export default META;
