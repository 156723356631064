import { forwardRef, SVGProps } from 'react';

export interface NotionProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Notion = forwardRef<SVGSVGElement, NotionProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M19.24 4.05L6 5a1.47 1.47 0 00-1.5 1.67v14.56a3.15 3.15 0 00.79 2l3.13 4.06a1.93 1.93 0 002 .74l15.44-.93c1.31-.09 1.68-.7 1.68-1.73V9c0-.53-.21-.68-.83-1.13l-.1-.08-4.25-3c-1.06-.79-1.48-.88-3.12-.74zm-8.51 4.64c-1.26.08-1.55.1-2.26-.48L6.65 6.76c-.19-.19-.1-.42.37-.47l12.78-.93a2.62 2.62 0 012.05.64l2.2 1.58c.09 0 .32.33 0 .33l-13.2.79zm-1.47 16.5v-13.9c0-.61.19-.89.74-.94l15.17-.88c.51-.05.74.28.74.88v13.81c0 .61-.09 1.13-.93 1.17l-14.51.84c-.84.05-1.21-.23-1.21-.98zM23.58 12c.09.42 0 .84-.42.89l-.7.14v10.3a3.7 3.7 0 01-1.63.51c-.75 0-.93-.23-1.49-.93l-4.58-7.19v7l1.45.33s0 .84-1.17.84l-3.22.11a.6.6 0 01.33-.75l.84-.23v-9.2l-1.17-.09a.87.87 0 01.8-1.07l3.45-.24 4.76 7.28v-6.41l-1.21-.14a.79.79 0 01.74-.93z" />
  </svg>
));
Notion.displayName = 'Notion';
