import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackEditedInactivityLogoutDuration.meta';

export type useTrackEditedInactivityLogoutDurationParams = {
  inactivity_logout_timeout_duration: number;
};

export const useTrackEditedInactivityLogoutDuration = () => {
  const { track } = useAnalyticsUtilities();

  const trackEditedInactivityLogoutDuration = useCallback(
    (params: useTrackEditedInactivityLogoutDurationParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackEditedInactivityLogoutDuration };
};
