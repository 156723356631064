import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackMovedClipsToBoardParams = {
  fromId: Board['id'];
  toId: Board['id'];
  clipIds: Clip['id'][];
  location: AirActionTrackingLocation;
};

export const useTrackMovedClipsToBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackMovedClipsToBoard = useCallback(
    (params: TrackMovedClipsToBoardParams) => {
      track(`Moved clips to a board`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackMovedClipsToBoard };
};
