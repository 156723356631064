import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackUpdatedLibraryMember.meta';

export type UseTrackUpdatedLibraryMemberParams = {
  library_id: Library['id'];
  library_member_email?: string;
  library_member_role_id?: string;
};

export const useTrackUpdatedLibraryMember = () => {
  const { track } = useAnalyticsUtilities();

  const trackUpdatedLibraryMember = useCallback(
    (params: UseTrackUpdatedLibraryMemberParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackUpdatedLibraryMember };
};
