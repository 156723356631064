import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackVersionUploadInitiated = () => {
  const { track } = useAnalyticsUtilities();

  const trackVersionUploadInitiated = useCallback(() => {
    track(`Version upload initiated`);
  }, [track]);

  return { trackVersionUploadInitiated };
};
