import { object } from 'yup';

import { emailValidationSchema } from '../schemas/emailValidationSchema';

export const getRegistrationEmailValidationSchema = (email?: string) => {
  return object()
    .shape({
      email: emailValidationSchema.default(email ? decodeURIComponent(email) : ''),
    })
    .required();
};
