import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedSignUpPageBasicInfo = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedSignUpPageBasicInfo = useCallback(() => {
    track(`viewed_sign_up_page_basic_information`);
  }, [track]);

  return { trackViewedSignUpPageBasicInfo };
};
