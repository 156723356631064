import { forwardRef, SVGProps } from 'react';

export interface FigmaRecentIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaRecentIcon = forwardRef<SVGSVGElement, FigmaRecentIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <g clip-path="url(#a)">
      <path
        fill="currentColor"
        d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7 4V8.5V9H7.5H12V8H8V4H7Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
));

FigmaRecentIcon.displayName = 'FigmaRecentIcon';
