import { forwardRef, SVGProps } from 'react';

export interface ApertureProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Aperture = forwardRef<SVGSVGElement, ApertureProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M14 8C14 4.69065 11.3094 2 8 2C4.69065 2 2 4.69065 2 8C2 11.3093 4.69065 14 8 14C11.3094 14 14 11.3093 14 8ZM13.1655 8C13.1655 8.70503 13.0216 9.38129 12.7626 10H10.3309L11.9856 4.71942C12.7194 5.61151 13.1655 6.76259 13.1655 8ZM5.64029 7.20863L8.01439 5.51079L10.3165 7.20863L9.43885 9.98561H6.54676L5.64029 7.20863ZM11.3237 4.05755L10.5899 6.38849L6.23021 3.16547C6.77698 2.96403 7.38129 2.84892 8 2.84892C9.26619 2.83453 10.4173 3.29496 11.3237 4.05755ZM5.36691 3.55396L7.30935 4.9928L2.83453 8.18705C2.83453 8.12949 2.83453 8.07194 2.83453 8C2.83453 6.11511 3.85611 4.46043 5.36691 3.55396ZM2.96403 9.1223L4.93525 7.71223L6.66187 12.9784C4.80575 12.4892 3.38129 10.9928 2.96403 9.1223ZM8 13.1655C7.85611 13.1655 7.72662 13.1511 7.58273 13.1511L6.82014 10.8345H12.3022C11.3957 12.2302 9.79856 13.1655 8 13.1655Z"
    />
  </svg>
));
Aperture.displayName = 'Aperture';
