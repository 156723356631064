import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackBoardViewSaved = () => {
  const { track } = useAnalyticsUtilities();

  const trackBoardViewSaved = useCallback(
    (params: { boardId: string }) => {
      track(`Board view saved`, params);
    },
    [track],
  );

  return { trackBoardViewSaved };
};
