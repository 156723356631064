import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackCreatedContentCollectionLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackCreatedContentCollectionLink = useCallback(() => {
    track(`Created a content collection link`);
  }, [track]);

  return { trackCreatedContentCollectionLink };
};
