import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackShowField = () => {
  const { track } = useAnalyticsUtilities();

  const trackShowField = useCallback(
    ({ type }: { type: string }) => {
      track(`Field shown`, { type });
    },
    [track],
  );

  return { trackShowField };
};
