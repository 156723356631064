import { CognitoConfigParams } from '@air/api';
import { ANONYMOUS_COGNITO_CONFIG, COGNITO_CONFIG } from '@air/utils-auth';

// Cypress can't read env vars from process.env so we need to pass them in using Cypress.env
const cypressCognitoConfig: CognitoConfigParams = {
  ...COGNITO_CONFIG,
  region: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_COGNITO_REGION'),
  userPoolId: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_COGNITO_USER_POOL'),
};

export const getCognitoConfig = (): CognitoConfigParams =>
  typeof Cypress === 'undefined' ? COGNITO_CONFIG : cypressCognitoConfig;

const cypressAnonymousCognitoConfig: CognitoConfigParams = {
  ...ANONYMOUS_COGNITO_CONFIG,
  userPoolId: typeof Cypress === 'undefined' ? '' : Cypress.env('NEXT_PUBLIC_ANONYMOUS_USER_POOL'),
};

export const getAnonymousCognitoConfig = (): CognitoConfigParams =>
  typeof Cypress === 'undefined' ? ANONYMOUS_COGNITO_CONFIG : cypressAnonymousCognitoConfig;
