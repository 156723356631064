import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedJoinWorkspaces = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedJoinWorkspaces = useCallback(
    (params: { workspaceIds: string[] }) => {
      track(`Clicked Join Workspace on approved domains`, params);
    },
    [track],
  );

  return { trackClickedJoinWorkspaces };
};
