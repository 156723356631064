import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackInitiateUploadParams = { location: 'drag-drop' | 'new-button' };

export const useTrackInitiateUpload = () => {
  const { track } = useAnalyticsUtilities();

  const trackInitiateUpload = useCallback(
    (params: TrackInitiateUploadParams) => {
      track(`Initiated asset upload`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackInitiateUpload };
};
