import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Deleted items',
  description: 'User deleted items',
  params: [
    {
      name: 'item_type',
      description: 'Item type',
      required: true,
      type: 'enum',
      values: ['library', 'asset', 'board'],
    },
    {
      name: 'item_ids',
      description: 'Array of deleted item ids',
      required: true,
      type: 'array',
      values: ['string'],
    },
    {
      name: 'items_total',
      description: 'Total number of deleted items',
      required: true,
      type: 'number',
    },
    {
      name: 'location',
      description: 'Location of the action',
      required: false,
      type: 'string',
    },
  ],
};

export default META;
