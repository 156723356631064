import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { BaseEditCommentParams } from '../types';

export const useTrackViewedDiscussion = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedDiscussion = useCallback(
    (params: BaseEditCommentParams) => {
      track(`Viewed a discussion`, params);
    },
    [track],
  );

  return { trackViewedDiscussion };
};
