import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedVideoSpeedButton = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedVideoSpeedButton = useCallback(() => {
    track(`Clicked speed changer button in video player`);
  }, [track]);

  return { trackClickedVideoSpeedButton };
};
