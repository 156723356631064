import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackCustomFieldsActionParams } from '../types';

export const useTrackClickedSaveCustomField = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedSaveCustomField = useCallback(
    (params: TrackCustomFieldsActionParams) => {
      track(`Clicked Save custom field`, params);
    },
    [track],
  );

  return { trackClickedSaveCustomField };
};
