import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Added a comment',
  description: 'User added a comment',
  params: [
    {
      name: 'comment',
      description: 'The comment that was added',
      required: true,
      type: 'string',
    },
    {
      name: 'startedDiscussion',
      description: 'Whether the comment started a discussion',
      required: true,
      type: 'boolean',
    },
    {
      name: 'clipId',
      description: 'The clip ID that the comment was added to',
      required: true,
      type: 'string',
    },
    {
      name: 'boardId',
      description: 'The board ID that the comment was added to',
      required: false,
      type: 'string',
    },
    {
      name: 'public',
      description: 'Whether the comment was public',
      required: false,
      type: 'boolean',
    },
    {
      name: 'location',
      description: 'The location where the user added a comment',
      required: true,
      values: ['all-media', 'bookmarks', 'board', 'public-board', 'asset-page'],
      type: 'enum',
    },
    {
      name: 'has_timestamp',
      description: 'Whether the comment has a timestamp',
      required: false,
      type: 'boolean',
    },
    {
      name: 'has_annotation',
      description: 'Whether the comment has an annotation',
      required: false,
      type: 'boolean',
    },
    {
      name: 'annotationType',
      description: 'The type of annotation',
      required: false,
      values: ['area', 'point', 'pencil', 'standalone'],
      type: 'enum',
    },
    {
      name: 'assetType',
      description: 'The type of asset',
      required: false,
      values: ['animated', 'audio', 'livePhoto', 'nonMedia', 'photo', 'video'],
      type: 'enum',
    },
  ],
};

export default META;
