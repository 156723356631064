import { forwardRef, SVGProps } from 'react';

export interface KeyboardShortcutProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const KeyboardShortcut = forwardRef<SVGSVGElement, KeyboardShortcutProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M9,11.41a1.23,1.23,0,1,0,1.22,1.23A1.23,1.23,0,0,0,9,11.41Zm4.65,0a1.23,1.23,0,1,0,1.23,1.23A1.23,1.23,0,0,0,13.67,11.41Zm4.66,0a1.23,1.23,0,1,0,1.22,1.23A1.23,1.23,0,0,0,18.33,11.41Zm4.65,0a1.23,1.23,0,1,0,1.22,1.23A1.22,1.22,0,0,0,23,11.41ZM9,15.62a1.23,1.23,0,1,0,1.22,1.22A1.22,1.22,0,0,0,9,15.62Zm4.65,0a1.23,1.23,0,1,0,1.23,1.22A1.22,1.22,0,0,0,13.67,15.62Zm4.66,0a1.23,1.23,0,1,0,1.22,1.22A1.23,1.23,0,0,0,18.33,15.62Zm4.65,0a1.23,1.23,0,1,0,1.22,1.22A1.22,1.22,0,0,0,23,15.62Zm.85,5.64a1.13,1.13,0,0,0-1.12-1.13H9.41a1.13,1.13,0,0,0,0,2.26h13.3A1.13,1.13,0,0,0,23.83,21.26ZM26.69,7.7A1.12,1.12,0,0,1,27.8,8.83V23.17a1.12,1.12,0,0,1-1.11,1.13H5.31A1.12,1.12,0,0,1,4.2,23.17V8.83A1.12,1.12,0,0,1,5.31,7.7H26.69m0-2.2H5.31A3.32,3.32,0,0,0,2,8.83V23.17A3.32,3.32,0,0,0,5.31,26.5H26.69A3.32,3.32,0,0,0,30,23.17V8.83A3.32,3.32,0,0,0,26.69,5.5Z" />
  </svg>
));
KeyboardShortcut.displayName = 'KeyboardShortcut';
