import { forwardRef, SVGProps } from 'react';

export interface FigmaArrowRightIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaArrowRightIcon = forwardRef<SVGSVGElement, FigmaArrowRightIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M12.7071 8.00004L12.3536 7.64648L9.35355 4.64648L8.64645 5.35359L10.7929 7.50004L2.5 7.50004L2.5 8.50004L10.7929 8.50004L8.64645 10.6465L9.35355 11.3536L12.3536 8.35359L12.7071 8.00004Z"
      clipRule="evenodd"
      fillOpacity="0.8"
      fillRule="evenodd"
    />
  </svg>
));

FigmaArrowRightIcon.displayName = 'FigmaArrowRightIcon';
