import { TrackSelectedItemsParams } from '@air/analytics';
import { SelectableItem } from '@air/redux-selected-items';

export const getTrackSelectedItemsCountsAndType = (selected: SelectableItem[]) => {
  let type: TrackSelectedItemsParams['type'] = selected[0]?.type ?? 'asset';
  let fileSelectionCount = 0;
  let assetSelectionCount = 0;
  let boardSelectionCount = 0;
  let totalSelectionCount = 0;
  selected.forEach((item) => {
    totalSelectionCount += 1;
    if (item.type !== type) {
      type = 'mixed';
    }
    switch (item.type) {
      case 'file': {
        fileSelectionCount += 1;
        break;
      }
      case 'asset': {
        assetSelectionCount += 1;
        break;
      }
      case 'board': {
        boardSelectionCount += 1;
        break;
      }
    }
  });
  return {
    type,
    fileSelectionCount,
    assetSelectionCount,
    boardSelectionCount,
    totalSelectionCount,
  };
};
