import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackMarkedNotificationAsRead = () => {
  const { track } = useAnalyticsUtilities();

  const trackMarkedNotificationAsRead = useCallback(() => {
    track(`Marked notification as read`);
  }, [track]);

  return { trackMarkedNotificationAsRead };
};
