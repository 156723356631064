import { Auth, config } from '@air/api';
import { reportErrorToBugsnag } from '@air/utils-error';

import { configureAPIPackage } from './configureAPIPackage';
import { getIsSAMLEnforced } from './getIsSAMLEnforced';
import { wipePersistedCognitoArtifacts } from './wipePersistedCognitoArtifacts';

const REDIRECT_URI_KEY = 'rpaqs';

export type LogoutParams = {
  expiredSession?: string;
  redirect?: string;
  onExpiredSession?: () => void;
  onError?: (error: unknown) => void;
  onSuccess?: () => void;
  onSettled?: () => void;
};

export const logout = async ({
  expiredSession,
  onExpiredSession,
  onError,
  onSettled,
  onSuccess,
  redirect,
}: LogoutParams = {}) => {
  try {
    /**
     * You should only be able to logout by using `utils/Auth/logout()` which will provider
     * a redirect path as a query parameter.
     *
     * AWS amplify only allows us to configure static
     * sign out redirect paths (you can see what we've defined it as within
     * `utils/ConfigureAmplify`).
     *
     * This redirect would blow away any passed query parameters, so we store them in session
     * storage and have logic to read from session storage on `/login` since `Auth.signOut()`
     * always navigates us to that page.
     */
    if (redirect) {
      window.sessionStorage.setItem(REDIRECT_URI_KEY, encodeURIComponent(redirect));
    }

    if (expiredSession) {
      onExpiredSession?.();
    }

    /**
     * When a user hits this page, they could actually be logged in (maybe an expired session
     * sent them here or they manually logged out).
     *
     * If they are logged in, we check if their email is attached to any workspace that has
     * SAML enforced.
     *
     * If it is, we redirect them to the SAML login page. If not, we just log them out.
     */
    try {
      const userInfo = await Auth.getCurrentUserInfo();
      const email = userInfo.attributes.email;
      const isSAMLEnforced = await getIsSAMLEnforced(email);

      if (isSAMLEnforced) {
        configureAPIPackage({
          oauthConfigParams: {
            redirectSignOut: process.env.NEXT_PUBLIC_OAUTH_SAML_SIGNOUT_REDIRECT,
          },
        });
      }
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: "User wasn't logged in",
      });
    }

    await Auth.signOut();

    onSuccess?.();
  } catch (error) {
    reportErrorToBugsnag({
      error,
      context: 'Failed to logout user',
    });

    onError?.(error);
  } finally {
    const authConfig = config.getAuthConfiguration();

    wipePersistedCognitoArtifacts(authConfig.oauth.clientId);

    onSettled?.();
  }
};
