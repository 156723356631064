import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackVersionRemoved = () => {
  const { track } = useAnalyticsUtilities();

  const trackVersionRemoved = useCallback(() => {
    track(`Version removed`);
  }, [track]);

  return { trackVersionRemoved };
};
