import { User } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type CompletedOnboardingBasicInfo = Pick<User, 'email' | 'firstName' | 'lastName'>;

export const useTrackCompletedOnboardingBasicInfo = () => {
  const { track } = useAnalyticsUtilities();

  const trackCompletedOnboardingBasicInfo = useCallback(
    (params: CompletedOnboardingBasicInfo) => {
      track(`Completed onboarding basic info`, params);
    },
    [track],
  );

  return { trackCompletedOnboardingBasicInfo };
};
