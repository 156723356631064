import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedSignUpForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedSignUpForm = useCallback(() => {
    track(`Viewed Sign up form`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackViewedSignUpForm };
};
