import { forwardRef, SVGProps } from 'react';

export interface VectorProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Vector = forwardRef<SVGSVGElement, VectorProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M7.52 29a4.36 4.36 0 01-2.61-.87L4.75 28a4.38 4.38 0 01-.86-6.1 2.22 2.22 0 00-.45-3.1 1.1 1.1 0 01-.22-1.53A1.08 1.08 0 014.74 17a4.4 4.4 0 01.9 6.16 2.21 2.21 0 00.44 3.07l.16.13a2.2 2.2 0 003-.46l1.35-1.8.88-13.31a1.12 1.12 0 01.73-1l7.51-2.59.38-2v-.11A2.91 2.91 0 0122 3.13a2.31 2.31 0 012.14.32l4 3a2.38 2.38 0 01.91 2 2.85 2.85 0 01-1.31 2.3.33.33 0 01-.1.06l-1.86 1-.15 8.09a1.1 1.1 0 01-.71 1l-12.56 4.56L11 27.25A4.3 4.3 0 018.16 29a3.52 3.52 0 01-.64 0zm9.82-10.2l-2.57 3.44L23.41 19l.14-7.45-3.1-2.32-6.8 2.43-.65 9.26 2.56-3.43a2.72 2.72 0 01-.13-.49 2.09 2.09 0 01.41-1.57 2.07 2.07 0 011.38-.83 2.11 2.11 0 012 3.35 2.12 2.12 0 01-1.39.84 2.27 2.27 0 01-.49.01zm4.52-11.23l2.92 2.2 1.75-.91a.73.73 0 00.29-.52.23.23 0 00-.05-.17l-4-3h-.15a.73.73 0 00-.42.44z" />
  </svg>
));
Vector.displayName = 'Vector';
