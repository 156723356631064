import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackCopiedPublicAssetParams = {
  id: string;
  workspaceId: string;
  location: AirActionTrackingLocation;
};

export const useTrackCopiedPublicAsset = () => {
  const { track } = useAnalyticsUtilities();

  const trackCopiedPublicAsset = useCallback(
    (params: TrackCopiedPublicAssetParams) => {
      track(`Copied public asset`, params);
    },
    [track],
  );

  return { trackCopiedPublicAsset };
};
