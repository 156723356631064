import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackEmailVerified = () => {
  const { track, setGlobalEventProps } = useAnalyticsUtilities();

  const trackEmailVerified = useCallback(
    (params: { email: string }) => {
      track(`Email verified`, params, { integrations: { 'Customer.io (Actions)': true } });
      setGlobalEventProps({
        email_verified: true,
      });
    },
    [setGlobalEventProps, track],
  );

  return { trackEmailVerified };
};
