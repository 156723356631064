import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackTagListSortType = 'name' | 'count';
export type TrackTagListSortDirection = 'asc' | 'desc';

export type TrackTagListSorted = {
  sort: TrackTagListSortType;
  direction: TrackTagListSortDirection;
};

export const useTrackTagListSorted = () => {
  const { track } = useAnalyticsUtilities();

  const trackTagListSorted = useCallback(
    (params: TrackTagListSorted) => {
      track(`Tag list sorted`, params);
    },
    [track],
  );

  return { trackTagListSorted };
};
