import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackApprovedLibraryAccess.meta';

export type UseTrackApprovedLibraryAccessParams = {
  library_id: Library['id'];
  library_member_id?: string;
};

export const useTrackApprovedLibraryAccess = () => {
  const { track } = useAnalyticsUtilities();

  const trackApprovedLibraryAccess = useCallback(
    (params: UseTrackApprovedLibraryAccessParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackApprovedLibraryAccess };
};
