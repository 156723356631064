import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedOnboardingPurposeOfUse = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedOnboardingPurposeOfUse = useCallback(() => {
    track(`Viewed onboarding primary purpose`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackViewedOnboardingPurposeOfUse };
};
