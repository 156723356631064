import { forwardRef, SVGProps } from 'react';

export interface ResolveProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Resolve = forwardRef<SVGSVGElement, ResolveProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M13.53,21.23a1.21,1.21,0,0,1-1-.5L10,17.26a1.23,1.23,0,1,1,2-1.46L13.56,18a33.31,33.31,0,0,1,6.8-6,1.23,1.23,0,0,1,1.37,2.05,27.9,27.9,0,0,0-7.18,6.61,1.26,1.26,0,0,1-1,.54Zm-3.55,5a1.23,1.23,0,0,0-.26-1.72,10.66,10.66,0,0,1-2.34-2.4,1.23,1.23,0,0,0-2,1.41,12.69,12.69,0,0,0,2.89,3,1.16,1.16,0,0,0,.73.24A1.25,1.25,0,0,0,10,26.19ZM19.23,4.61a1.25,1.25,0,0,0-1-1.42,13.07,13.07,0,0,0-4.14,0,1.23,1.23,0,0,0-1,1.4,1.21,1.21,0,0,0,1.39,1,10.75,10.75,0,0,1,3.35,0,.82.82,0,0,0,.22,0A1.24,1.24,0,0,0,19.23,4.61ZM4.54,18.94a1.23,1.23,0,0,0,1-1.4,10.69,10.69,0,0,1,0-3.34,1.23,1.23,0,1,0-2.42-.43,13.07,13.07,0,0,0,0,4.14A1.24,1.24,0,0,0,4.36,19Zm3-9.22a10.66,10.66,0,0,1,2.4-2.34,1.23,1.23,0,1,0-1.41-2,12.69,12.69,0,0,0-3,2.89A1.23,1.23,0,0,0,5.81,10a1.25,1.25,0,0,0,.73.24A1.21,1.21,0,0,0,7.53,9.72Zm16,16.91a12.69,12.69,0,0,0,3-2.89,1.23,1.23,0,1,0-2-1.46,10.66,10.66,0,0,1-2.4,2.34,1.23,1.23,0,0,0,.71,2.23A1.21,1.21,0,0,0,23.48,26.63Zm2.85-16.4a1.23,1.23,0,0,0,.3-1.71,12.69,12.69,0,0,0-2.89-3,1.23,1.23,0,1,0-1.46,2,10.66,10.66,0,0,1,2.34,2.4,1.22,1.22,0,0,0,1,.52A1.24,1.24,0,0,0,26.33,10.23Zm2.48,8a13.07,13.07,0,0,0,.05-4.14,1.23,1.23,0,0,0-2.43.36,10.75,10.75,0,0,1,0,3.35,1.23,1.23,0,0,0,1,1.43h.21A1.23,1.23,0,0,0,28.81,18.23ZM17.91,28.86a1.23,1.23,0,0,0-.36-2.43,10.75,10.75,0,0,1-3.35,0,1.23,1.23,0,1,0-.43,2.42A12.8,12.8,0,0,0,16,29,13.78,13.78,0,0,0,17.91,28.86Z" />
  </svg>
));
Resolve.displayName = 'Resolve';
