import { QueryClient } from '@tanstack/react-query';

export const QUERY_CLIENT_STALE_TIME = 3 * 1000;
export const QUERY_CLIENT_CACHE_TIME = 60 * 60 * 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: QUERY_CLIENT_STALE_TIME,
      gcTime: QUERY_CLIENT_CACHE_TIME,
    },
  },
});
