import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Unfollowed Boards',
  description: 'User unfollowed a board',
  params: [
    {
      name: 'location',
      description: 'Location of user action',
      required: true,
      type: 'enum',
      values: ['notifications settings', 'board header'],
    },
    {
      name: 'boardId',
      description: 'Id of unfollowed board',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
