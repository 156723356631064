'use client';
import { Bugsnag } from './Bugsnag';
import { convertUnknownToError } from './convertUnknownToError';

export type ReportErrorToBugsnagParams = {
  error: unknown;
  context: string;
  metadata?: { [name: string]: any };
};

/**
 * @description Handles catch block errors by returning an Error/Airror instance, and notifying bugsnag
 * @param error  This is what failed, or the error initially received from the catch block (this will likely be of type `unknown`)
 * @param context This is the message to be passed to bugsnag. Example: `Failed to load user`
 * @param metadata This is the content that you would like to pass to bugsnag
 * @return an Error/Airror instance representing the error
 */
export const reportErrorToBugsnag = ({ error, context, metadata }: ReportErrorToBugsnagParams) => {
  /**
   * First, convert the error from an unknown to an Error/Airror
   */
  const _error = convertUnknownToError(error);

  /**
   * Then, now that it's in a valid format, report it to Bugsnag
   * */
  Bugsnag.notify(_error, (event) => {
    event.context = context;
    if (metadata) {
      Object.keys(metadata).forEach((key) => {
        event.addMetadata(key, metadata[key]);
      });
    }
  });

  /**
   * If this is a local environment, log it to the console to inform the dev
   */
  if (process.env.NODE_ENV !== 'production' && process.env.NEXT_PUBLIC_LOG_TRY_CATCH_ERRORS) {
    console.error(_error.message, context);
  }

  /**
   * Return the Error
   */
  return _error;
};
