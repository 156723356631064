import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

/**
 * tracks when a user clicks "Invite guests" option in "Share" menu dropdown
 */
export const useTrackClickedInviteGuests = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedInviteGuests = useCallback(() => {
    track(`Clicked "Invite guests"`);
  }, [track]);
  return { trackClickedInviteGuests };
};
