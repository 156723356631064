import * as RadixPortal from '@radix-ui/react-portal';
import { useEffect, useState } from 'react';

export type PortalProps = RadixPortal.PortalProps;

export const Portal = (props: PortalProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  return isMounted ? <RadixPortal.Root {...props} /> : null;
};
