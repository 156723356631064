import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export const useTrackUpdatedSamlMetadataUrl = () => {
  const { track } = useAnalyticsUtilities();

  const trackUpdatedSamlMetadataUrl = useCallback(
    (params: { location: AirActionTrackingLocation }) => {
      track(`Updated SAML metadata URL`, params);
    },
    [track],
  );

  return { trackUpdatedSamlMetadataUrl };
};
