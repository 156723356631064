import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackClickedNotificationsButton = {
  unviewedNotifications?: number;
};

export const useTrackClickedNotificationsIcon = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedNotificationsIcon = useCallback(
    (params: TrackClickedNotificationsButton) => {
      track(`Clicked notifications icon`, params);
    },
    [track],
  );

  return { trackClickedNotificationsIcon };
};
