import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRejectedLibraryAccess.meta';

export type UseTrackRejectedLibraryAccessParams = {
  library_id: Library['id'];
  library_member_id?: string;
};

export const useTrackRejectedLibraryAccess = () => {
  const { track } = useAnalyticsUtilities();

  const trackRejectedLibraryAccess = useCallback(
    (params: UseTrackRejectedLibraryAccessParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRejectedLibraryAccess };
};
