import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedSignUpPage = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedSignUpPage = useCallback(() => {
    track(`Viewed sign-up page`);
  }, [track]);

  return { trackViewedSignUpPage };
};
