import { forwardRef, SVGProps } from 'react';

export interface FigmaRefreshIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaRefreshIcon = forwardRef<SVGSVGElement, FigmaRefreshIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M14.6094 5.68765L13.4347 7.15603C13.4266 7.10387 13.4177 7.05185 13.4081 7C13.2566 6.18064 12.92 5.40189 12.4187 4.72539C11.7167 3.77797 10.7287 3.08128 9.60051 2.73808C8.47235 2.39489 7.26376 2.42337 6.15302 2.81933C5.35991 3.10206 4.64667 3.56145 4.06455 4.15764C3.83142 4.3964 3.61931 4.6571 3.43153 4.93718L3.43167 4.93728L4.26213 5.49406L4.26227 5.49416C4.79953 4.69282 5.5801 4.08538 6.48886 3.76143C7.39761 3.43748 8.38642 3.41418 9.30942 3.69496C10.2324 3.97574 11.0408 4.54574 11.6152 5.32088C12.0999 5.97502 12.3964 6.74603 12.4771 7.55063L10.2772 6.08398L9.72246 6.91603L12.7225 8.91603L13.1039 9.1703L13.3902 8.81235L15.3902 6.31235L14.6094 5.68765ZM2.60937 7.18765L0.609375 9.68765L1.39024 10.3123L2.56494 8.84397C2.57304 8.89614 2.58189 8.94815 2.59148 9C2.74298 9.81936 3.07957 10.5981 3.58088 11.2746C4.28296 12.222 5.27095 12.9187 6.39911 13.2619C7.52727 13.6051 8.73586 13.5766 9.8466 13.1807C10.6397 12.8979 11.3529 12.4386 11.9351 11.8424C12.1677 11.6041 12.3794 11.344 12.5669 11.0646L12.5681 11.0628L12.5679 11.0627L11.7375 10.5059L11.7373 10.5058L11.7363 10.5074C11.1991 11.308 10.4189 11.9148 9.51076 12.2386C8.60201 12.5625 7.6132 12.5858 6.6902 12.305C5.76719 12.0243 4.95887 11.4543 4.38446 10.6791C3.89971 10.025 3.60322 9.25397 3.52247 8.44937L5.72246 9.91603L6.27716 9.08398L3.27716 7.08398L2.89574 6.8297L2.60937 7.18765Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaRefreshIcon.displayName = 'FigmaRefreshIcon';
