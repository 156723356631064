import { forwardRef, SVGProps } from 'react';

export interface IsoProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Iso = forwardRef<SVGSVGElement, IsoProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M1.5 4.5C1.36739 4.5 1.24021 4.55268 1.14645 4.64645C1.05268 4.74021 1 4.86739 1 5V11C1 11.1326 1.05268 11.2598 1.14645 11.3536C1.24021 11.4473 1.36739 11.5 1.5 11.5C1.63261 11.5 1.75979 11.4473 1.85355 11.3536C1.94732 11.2598 2 11.1326 2 11V5C2 4.86739 1.94732 4.74021 1.85355 4.64645C1.75979 4.55268 1.63261 4.5 1.5 4.5ZM5 5.5H8C8.13261 5.5 8.25979 5.44732 8.35355 5.35355C8.44732 5.25979 8.5 5.13261 8.5 5C8.5 4.86739 8.44732 4.74021 8.35355 4.64645C8.25979 4.55268 8.13261 4.5 8 4.5H5C4.60218 4.5 4.22064 4.65804 3.93934 4.93934C3.65804 5.22064 3.5 5.60218 3.5 6V7C3.5 7.39782 3.65804 7.77936 3.93934 8.06066C4.22064 8.34196 4.60218 8.5 5 8.5H7C7.13261 8.5 7.25979 8.55268 7.35355 8.64645C7.44732 8.74021 7.5 8.86739 7.5 9V10C7.5 10.1326 7.44732 10.2598 7.35355 10.3536C7.25979 10.4473 7.13261 10.5 7 10.5H4C3.86739 10.5 3.74021 10.5527 3.64645 10.6464C3.55268 10.7402 3.5 10.8674 3.5 11C3.5 11.1326 3.55268 11.2598 3.64645 11.3536C3.74021 11.4473 3.86739 11.5 4 11.5H7C7.39782 11.5 7.77936 11.342 8.06066 11.0607C8.34196 10.7794 8.5 10.3978 8.5 10V9C8.5 8.60218 8.34196 8.22064 8.06066 7.93934C7.77936 7.65804 7.39782 7.5 7 7.5H5C4.86739 7.5 4.74021 7.44732 4.64645 7.35355C4.55268 7.25979 4.5 7.13261 4.5 7V6C4.5 5.86739 4.55268 5.74021 4.64645 5.64645C4.74021 5.55268 4.86739 5.5 5 5.5ZM11.5 4.5H13.5C13.8978 4.5 14.2794 4.65804 14.5607 4.93934C14.842 5.22064 15 5.60218 15 6V10C15 10.3978 14.842 10.7794 14.5607 11.0607C14.2794 11.342 13.8978 11.5 13.5 11.5H11.5C11.1022 11.5 10.7206 11.342 10.4393 11.0607C10.158 10.7794 10 10.3978 10 10V6C10 5.60218 10.158 5.22064 10.4393 4.93934C10.7206 4.65804 11.1022 4.5 11.5 4.5ZM13.8536 10.3536C13.9473 10.2598 14 10.1326 14 10V6C14 5.86739 13.9473 5.74021 13.8536 5.64645C13.7598 5.55268 13.6326 5.5 13.5 5.5H11.5C11.3674 5.5 11.2402 5.55268 11.1464 5.64645C11.0527 5.74021 11 5.86739 11 6V10C11 10.1326 11.0527 10.2598 11.1464 10.3536C11.2402 10.4473 11.3674 10.5 11.5 10.5H13.5C13.6326 10.5 13.7598 10.4473 13.8536 10.3536Z"
      clipRule="evenodd"
    />
  </svg>
));
Iso.displayName = 'Iso';
