import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { OnboardingSurveyCompleted } from '../types';

export const useTrackCompletedOnboardingSize = () => {
  const { track } = useAnalyticsUtilities();

  const trackCompletedOnboardingSize = useCallback(
    (params: OnboardingSurveyCompleted) => {
      track(`Completed onboarding company size`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackCompletedOnboardingSize };
};
