import { Refresh } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Portal } from '@air/primitive-portal';

export type ReloadApplicationPromptProps = {
  onReload: () => void;
};

export const ReloadApplicationPrompt = ({ onReload }: ReloadApplicationPromptProps) => {
  return (
    <Portal>
      <div className="absolute bottom-6 left-6 flex w-full max-w-[224px] flex-col gap-4 rounded-lg bg-grey-1 p-4 shadow-popover">
        <header className="flex flex-col items-center">
          <div className="text-14 font-semibold text-grey-12">Air has an update!</div>
          <div className="text-12 text-grey-10">Click to refresh.</div>
        </header>

        <footer className="flex flex-col items-stretch">
          <Button appearance="filled" color="blue" onClick={onReload} prefix={<Refresh className="h-4 w-4" />}>
            Refresh now
          </Button>
        </footer>
      </div>
    </Portal>
  );
};
