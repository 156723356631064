import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackSubmittedCollectionForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackSubmittedCollectionForm = useCallback(() => {
    track(`Submitted collection form`);
  }, [track]);

  return { trackSubmittedCollectionForm };
};
