import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackLoggedOutInactiveUser.meta';

export const useTrackLoggedOutInactiveUser = () => {
  const { track } = useAnalyticsUtilities();

  const trackLoggedOutInactiveUser = useCallback(() => {
    track(META.name);
  }, [track]);

  return { trackLoggedOutInactiveUser };
};
