import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackRemovedUser = {
  removerId?: string;
  removedUserId?: string;
  removedUserRole?: string;
  wasBilled?: boolean;
  wasInvitePending?: boolean;
};

export const useTrackRemovedUserFromWorkspace = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedUserFromWorkspace = useCallback(
    (params: TrackRemovedUser) => {
      track(`Removed user from workspace`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackRemovedUserFromWorkspace };
};
