import { forwardRef, SVGProps } from 'react';

export interface PopcornProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Popcorn = forwardRef<SVGSVGElement, PopcornProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M27 10.88a4.75 4.75 0 00-4.73-4.76A4.65 4.65 0 0021 6.3 4.69 4.69 0 0013.72 4a4.53 4.53 0 00-1.8-.37A4.75 4.75 0 007.5 6.68a4.75 4.75 0 00-2.5 4.2v1.31a.31.31 0 000 .08v.07l2.49 15.85a1 1 0 00.95.81h15.12a1 1 0 001-.81L27 12.34v-.07a.24.24 0 000-.08zM8.65 8.26a1 1 0 00.55-.63 2.84 2.84 0 012.72-2.1 2.78 2.78 0 011.44.41 1 1 0 001.17-.14 2.78 2.78 0 014.13.2 3.57 3.57 0 00-1.22 0 4.66 4.66 0 00-2.83 1.92 1 1 0 00.27 1.32.92.92 0 00.53.16 1 1 0 00.79-.4 2.8 2.8 0 011.61-1.09 2.54 2.54 0 011.92.56h.06a1.17 1.17 0 00.24.09 1.09 1.09 0 00.31 0h.08a.77.77 0 00.3-.11 2.81 2.81 0 014.3 2.41v.35H6.91v-.35a2.82 2.82 0 011.74-2.6zm11.22 4.89l-1.33 13.93H14l-1.35-13.93zm-12.8 0h3.66l1.33 13.93H9.25zm15.68 13.93h-2.29l1.33-13.93h3.14zM22.73 5a1 1 0 10-1-1 1 1 0 001 1z" />
  </svg>
));
Popcorn.displayName = 'Popcorn';
