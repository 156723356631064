import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedSubmitCollectionForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedSubmitCollectionForm = useCallback(() => {
    track(`Clicked submit collection form`);
  }, [track]);

  return { trackClickedSubmitCollectionForm };
};
