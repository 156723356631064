import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackSavedFilter } from '../types';

export const useTrackSavedFilterDeleted = () => {
  const { track } = useAnalyticsUtilities();

  const trackSavedFilterDeleted = useCallback(
    (params: TrackSavedFilter) => {
      track(`Deleted saved filter`, params);
    },
    [track],
  );

  return { trackSavedFilterDeleted };
};
