import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedVideoQualityButton = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedVideoQualityButton = useCallback(() => {
    track(`Clicked video quality button in video player`);
  }, [track]);

  return { trackClickedVideoQualityButton };
};
