import { type CognitoConfigParams } from '@air/api';

export const COGNITO_CONFIG: CognitoConfigParams = {
  region: process.env.NEXT_PUBLIC_COGNITO_REGION!,
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL!,
  mandatorySignIn: false,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
};

export const ANONYMOUS_COGNITO_CONFIG: CognitoConfigParams = {
  ...COGNITO_CONFIG,
  userPoolId: process.env.NEXT_PUBLIC_ANONYMOUS_USER_POOL!,
  authenticationFlowType: 'CUSTOM_AUTH',
};
