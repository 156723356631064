import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackActivatedIntercom.meta';

export type IntercomLocation =
  | 'main-nav'
  | 'workspace-popover'
  | 'settings'
  | 'cancel-workspace-subscription'
  | 'enterprise-contact-sales';

export const useTrackActivatedIntercom = () => {
  const { track } = useAnalyticsUtilities();

  const trackActivatedIntercom = useCallback(
    (params: { location: IntercomLocation }) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackActivatedIntercom };
};
