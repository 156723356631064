import { type CognitoConfigParams, config, type OAuthConfiguration } from '@air/api';
import { defaultsDeep, isUndefined } from 'lodash';

import { AIR_API_CONFIG } from '../constants/airApiConfig';
import { COGNITO_CONFIG } from '../constants/cognitoConfig';
import { OAUTH_CONFIG } from '../constants/oauthConfig';

export type ConfigureAPIPackageParams = {
  cognitoConfigParams: CognitoConfigParams;
  oauthConfigParams: OAuthConfiguration;
  baseUrl: string;
};

export const configureAPIPackage = (params: DeepPartial<ConfigureAPIPackageParams> = {}) => {
  const defaults: ConfigureAPIPackageParams = {
    cognitoConfigParams: COGNITO_CONFIG,
    oauthConfigParams: OAUTH_CONFIG,
    baseUrl: AIR_API_CONFIG.baseUrl,
  };

  const _params: ConfigureAPIPackageParams = defaultsDeep(params, defaults);

  const isBaseUrlValid = !!_params.baseUrl;

  const isCognitoValid = !!(
    _params.cognitoConfigParams.region &&
    _params.cognitoConfigParams.userPoolId &&
    _params.cognitoConfigParams.authenticationFlowType &&
    !isUndefined(_params.cognitoConfigParams.mandatorySignIn)
  );

  const isOAuthValid = !!(
    _params.oauthConfigParams.clientId &&
    _params.oauthConfigParams.domain &&
    _params.oauthConfigParams.redirectSignIn &&
    _params.oauthConfigParams.scope
  );

  const errors = [];

  if (!isBaseUrlValid) {
    errors.push('A baseUrl has not been set');
  }

  if (!isCognitoValid) {
    errors.push(
      'The cognito config must include a region, userPoolId, authenticationFlowType, and mandatorySignIn property',
    );
  }

  if (!isOAuthValid) {
    errors.push('The oauth config must include a clientId, domain, redirectSignIn, and scope');
  }

  if (errors.length > 0) {
    errors.push('Current config:', JSON.stringify(params, null, 2));

    throw new Error(errors.join('\n'));
  }

  config.configure({
    ssr: true,
    ..._params,
  });
};
