import { Account, Auth } from '@air/api';

export type GetProviderNameFromSSOOptionsParams = Extract<
  Awaited<ReturnType<typeof Account.getAccountSSOOptionsV2>>,
  { data: any }
>['data'][number];

export const getProviderNameFromSSOOptions = (ssoOptions: GetProviderNameFromSSOOptionsParams) => {
  return 'organizationId' in ssoOptions
    ? Auth.convertOrganizationIdToSAMLProvider(ssoOptions.organizationId)
    : Auth.convertWorkspaceIdToSAMLProvider(ssoOptions.workspaceId);
};
