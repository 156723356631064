import { useCallback } from 'react';
import { UrlObject } from 'url';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export interface ActionItemParams {
  destination?: string | UrlObject;
  destinationType?: 'external' | 'internal';
  location?: string;
  name?: string;
  pathName?: string;
  section?: {
    contentId?: string;
    contentTitle?: string;
    contentType?: string;
    order?: number;
  };
  zone?: 'marketing' | 'product';
  version?: string;
}

export const useTrackClickedActionItem = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedActionItem = useCallback(
    (params?: ActionItemParams) => {
      track(`Clicked item`, params);
    },
    [track],
  );

  return { trackClickedActionItem };
};
