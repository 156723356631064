import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackHideField = () => {
  const { track } = useAnalyticsUtilities();

  const trackHideField = useCallback(
    ({ type }: { type: string }) => {
      track(`Field hidden`, { type });
    },
    [track],
  );

  return { trackHideField };
};
