import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackBoardCustomFieldsSidePanelOpened = () => {
  const { track } = useAnalyticsUtilities();

  const trackBoardCustomFieldsSidePanelOpened = useCallback(
    (params: { boardId: string }) => {
      track(`Board custom fields side panel opened`, params);
    },
    [track],
  );

  return { trackBoardCustomFieldsSidePanelOpened };
};
