import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedNoPermissionPage = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedNoPermissionPage = useCallback(() => {
    track(`Viewed no permission page`);
  }, [track]);

  return { trackViewedNoPermissionPage };
};
