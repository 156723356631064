import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackRemovedLibraryMember.meta';

export type UseTrackRemovedLibraryMemberParams = {
  library_id: Library['id'];
  library_member_id?: string;
  library_member_role_id?: string;
};

export const useTrackRemovedLibraryMember = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedLibraryMember = useCallback(
    (params: UseTrackRemovedLibraryMemberParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackRemovedLibraryMember };
};
