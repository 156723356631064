import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackUnfollowedBoards.meta';

type UnfollowBoardLocation = 'notifications settings' | 'board header';

interface UnfollowBoardsParams {
  location: UnfollowBoardLocation;
  boardIds: string[];
}

export const useTrackUnfollowedBoards = () => {
  const { track } = useAnalyticsUtilities();

  const trackUnfollowedBoards = useCallback(
    (params: UnfollowBoardsParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackUnfollowedBoards };
};
