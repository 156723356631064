import { forwardRef, SVGProps } from 'react';

export interface FigmaChevronIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaChevronIcon = forwardRef<SVGSVGElement, FigmaChevronIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path fill="currentColor" d="M11 8L5 4V12L11 8Z" />
  </svg>
));

FigmaChevronIcon.displayName = 'FigmaChevronIcon';
