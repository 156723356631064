'use client';
import { button, buttonSpinner } from '@air/primitive-button';
import { Spinner } from '@air/primitive-spinner';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import type { ComponentPropsWithRef, ElementRef, ElementType, ReactNode } from 'react';
import { forwardRef } from 'react';

export const iconButton = tailwindVariants({
  extend: button,
  base: 'px-0',
  variants: {
    size: {
      'extra-small': 'h-4 w-4 px-0 text-12',
      small: 'h-6 w-6 px-0 text-12',
      medium: 'h-8 w-8 px-0 text-14',
      large: 'h-10 w-10 px-0 text-16',
      'extra-large': 'h-12 w-12 px-0 text-16',
    },
  },
});

export const iconButtonSpinner = tailwindVariants({
  extend: buttonSpinner,
});

export const iconButtonIcon = tailwindVariants({
  base: 'block h-4 w-4',
  variants: {
    size: {
      'extra-small': 'h-3 w-3',
      small: 'h-4 w-4',
      medium: 'h-4 w-4',
      large: 'h-5 w-5',
      'extra-large': 'h-6 w-6',
    },
  },
});

export const ICON_BUTTON_ELEMENT_TAG = 'button';

export type IconButtonVariants = VariantProps<typeof iconButton>;

export type IconButtonProps = Omit<ComponentPropsWithRef<typeof ICON_BUTTON_ELEMENT_TAG>, 'children'> &
  IconButtonVariants & {
    isLoading?: boolean;
    label: string;
  } & (
    | {
        icon: ElementType;
      }
    | {
        children: ReactNode;
      }
  );

export const IconButton = forwardRef<ElementRef<typeof ICON_BUTTON_ELEMENT_TAG>, IconButtonProps>(
  (
    {
      appearance = 'filled',
      className,
      color = 'blue',
      isLoading,
      label,
      size = 'medium',
      type = 'button',
      ...restOfProps
    },
    forwardedRef,
  ) => {
    const childrenNode =
      'children' in restOfProps ? restOfProps.children : <restOfProps.icon className={iconButtonIcon({ size })} />;

    return (
      <button
        className={classNames(
          iconButton({ appearance, color, size, class: className }),
          isLoading ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
        ref={forwardedRef}
        type={type}
        {...restOfProps}
      >
        {isLoading ? (
          <div role="status">
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <Spinner className={iconButtonSpinner({ size })} />
            </div>
            <VisuallyHidden>Loading...</VisuallyHidden>
            <div aria-hidden className="invisible opacity-0">
              {childrenNode}
            </div>
          </div>
        ) : (
          <>
            {label ? <VisuallyHidden>{label}</VisuallyHidden> : label}
            {childrenNode}
          </>
        )}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
