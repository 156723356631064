import { forwardRef, SVGProps } from 'react';

export interface GearProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Gear = forwardRef<SVGSVGElement, GearProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M18.78 29h-5.46a1.09 1.09 0 01-1.09-1.09v-2.15a10 10 0 01-2.91-1.68l-1.9 1.1a1.09 1.09 0 01-1.48-.38l-2.78-4.56A1.12 1.12 0 013 19.4a1.09 1.09 0 01.52-.67l1.87-1.09a8.91 8.91 0 01-.14-1.54 9 9 0 01.15-1.67l-1.88-1.17a1.09 1.09 0 01-.37-1.48l2.67-4.66a1.09 1.09 0 011.48-.41l2 1.12a12 12 0 012.81-1.61V4.09A1.09 1.09 0 0113.22 3h5.46a1.09 1.09 0 011.09 1.09v2.18a9.64 9.64 0 012.84 1.65l2-1.11a1.08 1.08 0 011.46.39l2.78 4.56a1.12 1.12 0 01.13.84 1.09 1.09 0 01-.52.67l-1.88 1.09a9.1 9.1 0 01.14 1.64v.13c0 .24-.05.47-.07.68s-.05.54-.09.82l1.9 1.1a1.09 1.09 0 01.41 1.49l-2.68 4.66a1.11 1.11 0 01-.67.51 1.05 1.05 0 01-.83-.11l-1.91-1.1a10.41 10.41 0 01-2.9 1.65v2.08a1.09 1.09 0 01-1.1 1.09zm-4.37-2.18h3.28V25a1.08 1.08 0 01.81-1 8.3 8.3 0 003.41-2 1.1 1.1 0 011.28-.14l1.64 1 1.59-2.78-1.64-.94a1.1 1.1 0 01-.5-1.25 7.14 7.14 0 00.19-1.26c0-.22 0-.45.06-.68a6.72 6.72 0 00-.25-1.82 1.1 1.1 0 01.5-1.25l1.61-.93-1.65-2.72-1.76 1a1.09 1.09 0 01-1.3-.19 7.41 7.41 0 00-3.28-1.9 1.08 1.08 0 01-.81-1V5.18h-3.28V7a1.1 1.1 0 01-.72 1 9.83 9.83 0 00-3.41 2 1.1 1.1 0 01-1.26.15l-1.74-1L5.57 12l1.67 1a1.08 1.08 0 01.48 1.23 6.78 6.78 0 00-.25 1.88 6.43 6.43 0 00.25 1.78 1.1 1.1 0 01-.5 1.25l-1.61.93 1.64 2.7 1.65-1a1.1 1.1 0 011.28.14 7.89 7.89 0 003.32 1.94 1.08 1.08 0 01.78.79l.1.39a1.24 1.24 0 010 .27zm1.59-6A4.86 4.86 0 1120.86 16 4.87 4.87 0 0116 20.86zm0-7.54A2.68 2.68 0 1018.68 16 2.68 2.68 0 0016 13.32z" />
  </svg>
));
Gear.displayName = 'Gear';
