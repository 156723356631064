import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Activated Intercom',
  description: 'User activated Intercom',
  params: [
    {
      name: 'location',
      description: 'The location where the user activated Intercom',
      required: true,
      values: [
        'cancel-workspace-subscription',
        'enterprise-contact-sales',
        'main-nav',
        'settings',
        'workspace-popover',
      ],
      type: 'enum',
    },
  ],
};

export default META;
