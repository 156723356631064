import { forwardRef, SVGProps } from 'react';

export interface LoopProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Loop = forwardRef<SVGSVGElement, LoopProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M28.41 11.2a1.19 1.19 0 00-1.64-.33 1.18 1.18 0 00-.33 1.63 7.26 7.26 0 01-6.07 11.24H9.09l1.56-1.32a1.17 1.17 0 00.14-1.66 1.19 1.19 0 00-1.67-.14L5.07 24a1.15 1.15 0 00-.42.9 1.2 1.2 0 00.43.91l4.08 3.38a1.19 1.19 0 001.67-.16 1.18 1.18 0 00-.16-1.66L9.09 26.1h11.28a9.61 9.61 0 008-14.9zM5.36 21.28a1.18 1.18 0 00.3-1.64 7.16 7.16 0 01-1.29-4.13 7.26 7.26 0 017.26-7.25h11.28l-1.56 1.32a1.18 1.18 0 00.76 2.08 1.2 1.2 0 00.77-.28L26.93 8a1.85 1.85 0 00.14-.14 1.1 1.1 0 00.14-.21l.06-.15a1 1 0 00.07-.36 1.2 1.2 0 00-.43-.91l-4.07-3.46a1.2 1.2 0 00-1.67.16 1.17 1.17 0 00.16 1.66l1.58 1.31H11.63A9.63 9.63 0 002 15.51 9.52 9.52 0 003.72 21a1.16 1.16 0 001 .5 1.18 1.18 0 00.64-.22z" />
  </svg>
));
Loop.displayName = 'Loop';
