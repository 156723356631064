import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined, noop } from 'lodash';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export type PendingAction = {
  id: string;
  description: string;
  timestamp?: Date;
};

export type ReloadApplicationContextValue = {
  /**
   * Determines whether there is a new deployment.
   */
  hasNewRelease: boolean;

  /**
   * Determines when a scheduled reload is in progress.
   */
  isScheduled: boolean;

  /**
   * List of pending actions that will be cancelled when
   * the application is reloaded.
   */
  pendingActions: PendingAction[];

  /**
   * Adds a new pending action to the list of pending actions.
   */
  onAddPendingAction: (pendingAction: PendingAction) => void;

  /**
   * Dismisses the prompt
   */
  onDismiss: () => void;

  /**
   * Reloads the application.
   */
  onReload: () => void;

  /**
   * Removes a pending action from the list of pending actions.
   */
  onRemovePendingAction: (pendingActionId: string) => void;

  /**
   * Schedules a reload of the application.
   */
  onScheduleReload: () => void;
};

const DEFAULT_VALUE = {
  hasNewRelease: false,
  isScheduled: false,
  pendingActions: [],
  onAddPendingAction: noop,
  onDismiss: noop,
  onReload: noop,
  onRemovePendingAction: noop,
  onScheduleReload: noop,
};

export const ReloadApplicationContext = createContext<ReloadApplicationContextValue>(DEFAULT_VALUE);

export type ReloadApplicationProviderProps = {
  children: ReactNode;
};

export const ReloadApplicationProvider = ({ children }: ReloadApplicationProviderProps) => {
  const { forceReload: forceReloadFlag } = useFlags();
  const [forceReload, setForceReload] = useState(false);
  const router = useRouter();
  const [isScheduled, setIsScheduled] = useState(false);
  const [hasNewRelease, setHasNewRelease] = useState(false);
  const [pendingActions, setPendingActions] = useState<PendingAction[]>([]);
  const { releaseVersion } = useFlags();
  const previousRelease = usePrevious(releaseVersion);

  /**
   * This will force reload the application when there is a new
   * deployment and the `forceReload` flag is enabled.
   */
  useEffect(() => {
    if (forceReloadFlag && !forceReload && hasNewRelease) {
      setForceReload(true);
      router.reload();
    }
  }, [forceReload, forceReloadFlag, hasNewRelease, setForceReload, router]);

  /**
   * This will set `hasNewDeployment` to true when there is a new
   * deployment is detected.
   */
  useEffect(() => {
    if (releaseVersion && !isUndefined(previousRelease) && releaseVersion !== previousRelease) {
      setHasNewRelease(true);
    }
  }, [previousRelease, releaseVersion]);

  /**
   * This will reload the application when there is a new deployment
   * and the `isScheduled` flag is set to true.
   */
  useEffect(() => {
    if (isScheduled && pendingActions.length === 0) {
      setIsScheduled(false);
      router.reload();
    }
  }, [isScheduled, pendingActions, router, setIsScheduled]);

  const onAddPendingAction = useCallback(
    (newPendingAction: PendingAction) => {
      return setPendingActions((prev) => [...prev, newPendingAction]);
    },
    [setPendingActions],
  );

  const onDismiss = useCallback(() => {
    setHasNewRelease(false);
  }, [setHasNewRelease]);

  const onRemovePendingAction = useCallback(
    (pendingActionId: string) => {
      setPendingActions((prev) => prev.filter((pendingAction) => pendingAction.id !== pendingActionId));
    },
    [setPendingActions],
  );

  const onReload = useCallback(() => {
    router.reload();
  }, [router]);

  const onScheduleReload = useCallback(() => {
    setIsScheduled(true);
  }, [setIsScheduled]);

  const value = useMemo<ReloadApplicationContextValue>(() => {
    return {
      hasNewRelease,
      isScheduled,
      pendingActions,
      onAddPendingAction,
      onDismiss,
      onReload,
      onRemovePendingAction,
      onScheduleReload,
    };
  }, [
    hasNewRelease,
    isScheduled,
    onAddPendingAction,
    onDismiss,
    onReload,
    onRemovePendingAction,
    onScheduleReload,
    pendingActions,
  ]);

  return <ReloadApplicationContext.Provider value={value}>{children}</ReloadApplicationContext.Provider>;
};

export const useReloadApplication = () => {
  const context = useContext(ReloadApplicationContext);

  if (context === DEFAULT_VALUE) {
    const error = 'ReloadApplicationContext used outside of ReloadApplicationProvider';

    if (isDevOrTestStage()) {
      throw new Error(error);
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
