import { forwardRef, SVGProps } from 'react';

export interface FocalLengthProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FocalLength = forwardRef<SVGSVGElement, FocalLengthProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M11.7345 12.5179C12.4832 11.1453 12.8822 9.6023 12.8822 8C12.8822 6.39765 12.4832 4.85474 11.7347 3.48243L8.64415 5.1618C9.13103 5.73241 9.45799 6.78984 9.45799 8C9.45799 9.21039 9.13091 10.268 8.64387 10.8385L11.7345 12.5179ZM12.4568 3.09005C13.2702 4.58186 13.704 6.25949 13.704 8C13.704 9.74043 13.2702 11.4181 12.4566 12.9103L12.9425 13.1743C13.1545 13.2967 13.2282 13.566 13.1052 13.7791C12.9831 13.9907 12.7115 14.0627 12.5001 13.9406L7.56863 11.2508C7.43999 11.2122 7.31623 11.1438 7.19916 11.0492L2.88349 8.69528C2.65305 8.5497 2.5 8.29272 2.5 8C2.5 7.70727 2.65305 7.45029 2.88349 7.30471L7.19833 4.95143C7.31594 4.85628 7.4403 4.78756 7.56958 4.74895L12.5001 2.05991C12.7121 1.93748 12.9822 2.00829 13.1052 2.22136C13.2274 2.43296 13.1539 2.70408 12.9425 2.82617L12.4568 3.09005ZM6.38068 9.60875C6.24699 9.1332 6.17074 8.58441 6.17074 8C6.17074 7.41582 6.24693 6.86723 6.38052 6.39182L3.63 7.8864L3.63003 8.11409L6.38068 9.60875ZM7.7081 10.33L7.83587 10.3995C7.84762 10.3919 7.86461 10.3794 7.888 10.3597C7.98513 10.2775 8.09258 10.1323 8.19253 9.93241C8.4345 9.44848 8.58139 8.75387 8.58139 8C8.58139 7.24613 8.4345 6.55151 8.19253 6.06759C8.09258 5.86769 7.98513 5.7225 7.888 5.64033C7.86488 5.62078 7.84801 5.60842 7.83628 5.60078L7.70697 5.67105C7.61908 5.75649 7.5247 5.8906 7.4362 6.06759C7.19424 6.55151 7.04734 7.24613 7.04734 8C7.04734 8.75387 7.19424 9.44848 7.4362 9.93241C7.52508 10.1102 7.61989 10.2447 7.7081 10.33Z"
    />
  </svg>
));
FocalLength.displayName = 'FocalLength';
