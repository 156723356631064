import { Meta } from '../types/docs';

const META: Meta = {
  name: 'leave_library',
  description: 'Member leaves a library',
  params: [
    {
      name: 'library_id',
      description: 'The id of the library',
      required: true,
      type: 'string',
    },
    {
      name: 'library_member_id',
      description: 'The id of the library member that left',
      required: false,
      type: 'string',
    },
  ],
};

export default META;
