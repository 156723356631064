import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackAddMenuOption } from '../types';

export const useTrackClickedFileUpload = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedFileUpload = useCallback(
    (params: TrackAddMenuOption) => {
      track(`Clicked 'File Upload'`, params);
    },
    [track],
  );

  return { trackClickedFileUpload };
};
