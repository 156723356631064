import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackSetCustomPdfThumbnailParams = {
  clipId: Clip['id'];
  boardId: Board['id'];
  location: AirActionTrackingLocation;
};

export const useTrackSetCustomPdfThumbnail = () => {
  const { track } = useAnalyticsUtilities();

  const trackSetCustomPdfThumbnail = useCallback(
    (params: TrackSetCustomPdfThumbnailParams) => {
      track(`Set custom pdf thumbnail`, params);
    },
    [track],
  );

  return { trackSetCustomPdfThumbnail };
};
