import { forwardRef, SVGProps } from 'react';

export interface ImageProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Image = forwardRef<SVGSVGElement, ImageProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M11.855 14.5H2.955C2.56952 14.4987 2.2002 14.345 1.92762 14.0724C1.65504 13.7998 1.50132 13.4305 1.5 13.045V2.95C1.50132 2.56497 1.6552 2.19618 1.92793 1.92439C2.20065 1.65261 2.56998 1.5 2.955 1.5H13.05C13.4346 1.5 13.8034 1.65276 14.0753 1.92469C14.3472 2.19662 14.5 2.56543 14.5 2.95V13.045C14.5 13.43 14.3474 13.7994 14.0756 14.0721C13.8038 14.3448 13.435 14.4987 13.05 14.5H11.855ZM12.09 13.405H13.025C13.1205 13.405 13.212 13.3671 13.2796 13.2996C13.3471 13.232 13.385 13.1405 13.385 13.045V11.355L10.825 9.19C10.7554 9.13331 10.6701 9.09951 10.5806 9.09325C10.491 9.08698 10.4018 9.10855 10.325 9.155L8.935 10.075L12.09 13.405ZM2.59 10.72V13.045C2.59 13.1405 2.62793 13.232 2.69544 13.2996C2.76296 13.3671 2.85452 13.405 2.95 13.405H10.605L5.605 8.15C5.52038 8.06446 5.40532 8.01592 5.285 8.015C5.22558 8.01472 5.16678 8.02706 5.11248 8.05119C5.05818 8.07533 5.00962 8.11071 4.97 8.155L2.59 10.72ZM10.56 7.995C10.9261 7.98892 11.2818 8.11697 11.56 8.355L13.415 9.925V2.95C13.415 2.85452 13.3771 2.76296 13.3096 2.69544C13.242 2.62793 13.1505 2.59 13.055 2.59H2.955C2.85952 2.59 2.76796 2.62793 2.70044 2.69544C2.63293 2.76296 2.595 2.85452 2.595 2.95V9.11L4.165 7.415C4.30633 7.2606 4.47765 7.13663 4.6685 7.05066C4.85934 6.96469 5.06571 6.91853 5.275 6.915C5.69883 6.93105 6.10009 7.11018 6.395 7.415L8.175 9.285L9.725 8.285C9.9623 8.11039 10.2458 8.00953 10.54 7.995H10.56ZM11.4 6.08C11.2516 6.08 11.1047 6.05077 10.9676 5.99398C10.8305 5.93719 10.7059 5.85396 10.601 5.74903C10.496 5.6441 10.4128 5.51953 10.356 5.38243C10.2992 5.24533 10.27 5.09839 10.27 4.95C10.27 4.8016 10.2992 4.65466 10.356 4.51756C10.4128 4.38046 10.496 4.2559 10.601 4.15097C10.7059 4.04604 10.8305 3.9628 10.9676 3.90601C11.1047 3.84923 11.2516 3.82 11.4 3.82C11.6773 3.82047 11.9448 3.92246 12.1521 4.10669C12.3594 4.29092 12.492 4.54466 12.525 4.82V4.905C12.5277 4.92825 12.5277 4.95174 12.525 4.975C12.5184 5.27295 12.3944 5.55625 12.18 5.76321C11.9655 5.97016 11.678 6.08403 11.38 6.08H11.4Z"
    />
  </svg>
));
Image.displayName = 'Image';
