import { WorkspaceMemberRoleDisplayName } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type CopiedMemberInviteLink = { roleId: string; role?: WorkspaceMemberRoleDisplayName };

export const useTrackCopiedInviteMemberLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackCopiedInviteMemberLink = useCallback(
    (params: CopiedMemberInviteLink) => {
      track(`Copied a member invite link`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });
    },
    [track],
  );

  return { trackCopiedInviteMemberLink };
};
