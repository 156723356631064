import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type ViewedBoardsLocation = 'fat-nav' | 'main-nav' | 'bottom-bar';

export const useTrackViewedWorkspaceBoards = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedWorkspaceBoards = useCallback(
    (params: { location: ViewedBoardsLocation }) => {
      track(`Viewed Workspace boards`, params);
    },
    [track],
  );

  return { trackViewedWorkspaceBoards };
};
