import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackToggledSessionDuration.meta';

export type useTrackToggledSessionDurationParams = {
  session_duration_enabled: boolean;
  session_duration_time: number | undefined;
};

export const useTrackToggledSessionDuration = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledSessionDuration = useCallback(
    (params: useTrackToggledSessionDurationParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackToggledSessionDuration };
};
