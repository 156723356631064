import { forwardRef, SVGProps } from 'react';

export interface FigmaCaretDownIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaCaretDownIcon = forwardRef<SVGSVGElement, FigmaCaretDownIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path fill="currentColor" d="M8 10L11 6L5 6L8 10Z" />
  </svg>
));

FigmaCaretDownIcon.displayName = 'FigmaCaretDownIcon';
