import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackToggledFavoriteParams = {
  favorited: boolean;
  ids: Clip['id'][];
  location: AirActionTrackingLocation;
};

export const useTrackToggledFavorite = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledFavorite = useCallback(
    (params: TrackToggledFavoriteParams) => {
      track(`Favorited asset`, params);
    },
    [track],
  );

  return { trackToggledFavorite };
};
