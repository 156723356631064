import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackEditedBoardParams = {
  boardId: Board['id'];
  location: AirActionTrackingLocation;
};

export const useTrackEditedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackEditedBoard = useCallback(
    (params: TrackEditedBoardParams) => {
      track(`Edited a board`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackEditedBoard };
};
