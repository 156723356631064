import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAddedShareLinkExpirationDate = () => {
  const { track } = useAnalyticsUtilities();

  const trackAddedShareLinkExpirationDate = useCallback(
    (params: { shortId: string }) => {
      track(`Added expiration date to share link`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackAddedShareLinkExpirationDate };
};
