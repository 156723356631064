'use client';
import { Account } from '@air/api';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getAccountKey } from './useAccount';

export const useGetCurrentAccount = () => {
  const queryClient = useQueryClient();

  const getCurrentAccount = useCallback(async () => {
    try {
      const account = await Account.get();

      queryClient.setQueryData(getAccountKey(), account);

      return { ...account, sub: account.authId };
    } catch (error) {
      throw reportErrorToBugsnag({ error, context: 'Error fetching account' });
    }
  }, [queryClient]);

  return {
    getCurrentAccount,
  };
};
