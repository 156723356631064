import { object, string } from 'yup';

import { emailValidationSchema } from '../schemas/emailValidationSchema';

export const getLoginValidationSchema = (email?: string) => {
  return object()
    .shape({
      email: emailValidationSchema.required('Please enter your email').default(email ? decodeURIComponent(email) : ''),
      password: string().required('Please enter your password').default(''),
    })
    .required();
};
