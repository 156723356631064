import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedBoardGuestLearnMore = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedBoardGuestLearnMore = useCallback(() => {
    track(`Clicked Learn More from Invite Board Guest Modal`);
  }, [track]);
  return { trackClickedBoardGuestLearnMore };
};
