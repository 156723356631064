import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type JoinedWorkspace = { method: 'email' | 'link'; workspaceId: string; workspaceName: string };

export const useTrackJoinedAWorkspace = () => {
  const { track } = useAnalyticsUtilities();

  const trackJoinedAWorkspace = useCallback(
    (params: JoinedWorkspace) => {
      track(`Joined a workspace`, params);
    },
    [track],
  );

  return { trackJoinedAWorkspace };
};
