import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackSelectedItemsParams } from '../types';

/**
 * Logs event for when a user deselectedItem
 */
export const useTrackDeselectedItems = () => {
  const { track } = useAnalyticsUtilities();

  const trackDeselectedItems = useCallback(
    (params: TrackSelectedItemsParams) => {
      track(`Deselected items`, params);
    },
    [track],
  );

  return { trackDeselectedItems };
};
