import { Meta } from '../types/docs';

const META: Meta = {
  name: 'update_library_member',
  description: 'User updates a library member',
  params: [
    {
      name: 'library_id',
      description: 'The id of the library',
      required: true,
      type: 'string',
    },
    {
      name: 'library_member_email',
      description: 'Email of the updated library member',
      required: true,
      type: 'string',
    },
    {
      name: 'library_member_role_id',
      description: 'The role of the updated library member',
      required: false,
      type: 'string',
    },
  ],
};

export default META;
