'use client';

import { useCurrentUserInfo } from './useCurrentUserInfo';

export const useIsLoggedIn = () => {
  const { data: currentUserInfo, isFetched } = useCurrentUserInfo();

  return {
    currentUserInfo,
    isLoggedIn: !!currentUserInfo,
    isLoggedInFetched: isFetched,
  };
};
