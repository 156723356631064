import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type FailedToUploadAssetParams = Pick<Clip, 'id' | 'type' | 'source'> & {
  error: string;
};

export const useTrackFailedToUploadAsset = () => {
  const { track } = useAnalyticsUtilities();

  const trackFailedToUploadAsset = useCallback(
    (params: FailedToUploadAssetParams) => {
      track(`Asset failed to upload`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackFailedToUploadAsset };
};
