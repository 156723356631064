import { Meta } from '../types/docs';

const META: Meta = {
  name: 'edited_session_duration_number',
  description: 'User edits session duration number input',
  params: [
    {
      name: 'session_duration_time',
      description: 'Duration period (in seconds)',
      required: true,
      type: 'number',
    },
    {
      name: 'session_duration_number',
      description: 'The numerical value the user inputed into the text field',
      required: true,
      type: 'number',
    },
  ],
};

export default META;
