import { Auth } from '@air/api';
import { useQuery } from '@tanstack/react-query';

export const CURRENT_SESSION = 'CURRENT_SESSION';

export const getCurrentSessionKey = () => [CURRENT_SESSION];

export const useCurrentUserSession = () => {
  return useQuery({
    queryKey: getCurrentSessionKey(),
    queryFn: Auth.getCurrentSession,
    staleTime: 1000 * 60 * 60 * 24,
  });
};
