'use client';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useMedia } from 'react-use';

export const sizes = {
  xxlarge: 1174,
  xlarge: 1104,
  large: 920,
  medium: 700,
  small: 560,
  zero: 0,
};

interface Props {
  children: ReactNode;
}

const defaultValue = {
  isAboveSmallScreen: true,
  isAboveMediumScreen: true,
  isAboveLargeScreen: false,
  isAboveXLScreen: false,
  isAboveXXLScreen: false,
};

const MediaQueryContext = createContext(defaultValue);

const mediaQueries = {
  isAboveSmall: `(min-width: ${sizes.small}px)`, // min-width: breakpoints.small
  isAboveMedium: `(min-width: ${sizes.medium}px)`, // min-width: breakpoints.medium
  isAboveLarge: `(min-width: ${sizes.large}px)`, // min-width: breakpoints.large
  isAboveXL: `(min-width: ${sizes.xlarge}px)`, // min-width: breakpoints.xl
  isAboveXXL: `(min-width: ${sizes.xxlarge}px)`, // min-width: breakpoints.xxl
};

export const MediaQueryProvider = ({ children }: Props) => {
  const isAboveSmallScreen = useMedia(mediaQueries.isAboveSmall, defaultValue.isAboveSmallScreen);
  const isAboveMediumScreen = useMedia(mediaQueries.isAboveMedium, defaultValue.isAboveMediumScreen);
  const isAboveLargeScreen = useMedia(mediaQueries.isAboveLarge, defaultValue.isAboveLargeScreen);
  const isAboveXLScreen = useMedia(mediaQueries.isAboveXL, defaultValue.isAboveXLScreen);
  const isAboveXXLScreen = useMedia(mediaQueries.isAboveXXL, defaultValue.isAboveXXLScreen);

  const value = useMemo(
    () => ({
      isAboveSmallScreen,
      isAboveMediumScreen,
      isAboveLargeScreen,
      isAboveXLScreen,
      isAboveXXLScreen,
    }),
    [isAboveSmallScreen, isAboveMediumScreen, isAboveLargeScreen, isAboveXLScreen, isAboveXXLScreen],
  );

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>;
};

export function useBreakpointsContext() {
  const context = useContext(MediaQueryContext);

  if (context === defaultValue) {
    throw 'useBreakpointsContext used outside of MediaQueryProvider';
  }

  return context;
}
