import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { DragWorkspaceData } from './useTrackStartedDragging';

export type BaseDragData = {
  numberOfBoards?: number;
  boardIds?: string[];
  numberOfAssets?: number;
  clipIds?: Clip['id'][];
};

export type DropWorkspaceData = DragWorkspaceData & {
  newIndex: number;
};

export type CompletedDraggingParams = (BaseDragData | DropWorkspaceData) & {
  dropLocation: {
    type:
      | 'board'
      | 'asset'
      | 'file'
      | 'rearrange'
      | 'sub-nav-rearrange'
      | 'workspace-rearrange'
      | 'version-rearrange'
      | 'library';
    id?: string;
  };
  usedSelecting?: boolean; // so we know if a used the selection before dragging
};

export const useTrackCompletedDragging = () => {
  const { track } = useAnalyticsUtilities();

  const trackCompletedDragging = useCallback(
    (params: CompletedDraggingParams) => {
      track(`Completed dragging items`, params);
    },
    [track],
  );

  return { trackCompletedDragging };
};
