export default {
  name: 'Activated search',
  description: 'User activated search',
  params: [
    {
      name: 'location',
      description: 'The location where the user activated search',
      required: true,
      type: 'string',
    },
    {
      name: 'isQuickSearch',
      description: 'Whether the search was a quick search',
      required: true,
      type: 'boolean',
    },
  ],
};
