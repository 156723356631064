import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackCustomFieldAddOption.meta';

export const useTrackCustomFieldAddOption = () => {
  const { track } = useAnalyticsUtilities();

  const trackCustomFieldAddOption = useCallback(() => {
    track(META.name, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackCustomFieldAddOption };
};
