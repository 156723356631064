import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedNeedHelp = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedNeedHelp = useCallback(() => {
    track(`Clicked Need Help in Import Modal`);
  }, [track]);

  return { trackClickedNeedHelp };
};
