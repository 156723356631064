import { AnalyticsBrowser } from '@segment/analytics-next';
import isbot from 'isbot';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';

import { AnalyticsContext } from '../contexts/AnalyticsContext';
import { logger } from '../utils/logger';

interface AnalyticsProviderProps {
  analyticsKey: string;
  children: ReactNode;
  isDisabled?: boolean;
}

export const AnalyticsProvider = ({ analyticsKey, children, isDisabled }: AnalyticsProviderProps) => {
  const analyticsRef = useRef<AnalyticsBrowser | undefined>(undefined);
  const globalPropsRef = useRef({});

  const value = useMemo(() => ({ analyticsRef, globalPropsRef }), []);

  if (isDisabled) {
    logger(`disabled`);
    return <>{children}</>;
  }

  /**
   * The code below to setup the analytics isn't in a useEffect
   * because we want any page mount track calls to suceed
   * */
  if (!analyticsKey) {
    throw Error(
      `No analytics key was provided! Missing the NEXT_PUBLIC_SEGMENT_KEY environment variable. If you're working locally, add NEXT_PUBLIC_SEGMENT_KEY to your .env.local. If this is in prod, add NEXT_PUBLIC_SEGMENT_KEY to the environment variables in Vercel.`,
    );
  }

  /** If analytics has already been setup, don't re-setup */
  if (!analyticsRef.current) {
    analyticsRef.current = AnalyticsBrowser.load({ writeKey: analyticsKey });

    const userAgentString = typeof window !== 'undefined' ? window.navigator.userAgent : '';

    /** Data teram wants to be able to track which users are bots */
    if (isbot(userAgentString)) {
      analyticsRef.current.identify('bot');
    }

    logger(`Started analytics`);
  }

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};
