import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackSetCustomBoardThumbnailParams = {
  clipId: Clip['id'];
  boardId: Board['id'];
  setCurrentBoardThumbnail: boolean;
  location: AirActionTrackingLocation;
};

export const useTrackSetCustomBoardThumbnail = () => {
  const { track } = useAnalyticsUtilities();

  const trackSetCustomBoardThumbnail = useCallback(
    (params: TrackSetCustomBoardThumbnailParams) => {
      track(`Set custom board thumbnail`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackSetCustomBoardThumbnail };
};
