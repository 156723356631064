import { forwardRef, SVGProps } from 'react';

export interface CameraProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Camera = forwardRef<SVGSVGElement, CameraProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M27.05 27.5H5a3 3 0 01-1.15-.23 2.88 2.88 0 01-1-.66 3 3 0 01-.65-1 3 3 0 01-.2-1.14V11.22a3.07 3.07 0 01.22-1.16 3 3 0 01.65-1 3.21 3.21 0 011-.66A3 3 0 015 8.19h4.3l1.87-2.93a1.53 1.53 0 01.5-.52 1.55 1.55 0 01.77-.24h7.33a1.54 1.54 0 01.76.2 1.61 1.61 0 01.58.58l1.72 2.91h4.35a2.84 2.84 0 012 .84 2.89 2.89 0 01.61.93 2.93 2.93 0 01.21 1.09v13.43a3 3 0 01-.85 2.11 3 3 0 01-2.08.91zM5 10.21a.84.84 0 00-.38.08 1 1 0 00-.32.21 1.19 1.19 0 00-.22.33 1 1 0 00-.07.38v13.27a1 1 0 00.07.39 1 1 0 00.22.32.87.87 0 00.32.22 1 1 0 00.38.08h22a1 1 0 001-1V11.05a.88.88 0 00-.06-.33.8.8 0 00-.18-.27 1 1 0 00-.27-.18.81.81 0 00-.31-.06h-4.61a1.63 1.63 0 01-.76-.21 1.56 1.56 0 01-.56-.55l-1.74-2.94h-6.77l-1.89 3a1.57 1.57 0 01-1.31.72zm7.9-3.93v.06zm6.49 0zm-3.19 17.1a6.24 6.24 0 01-6.09-5 6.29 6.29 0 01.35-3.61 6.22 6.22 0 0112 2.39 6.32 6.32 0 01-1.05 3.48 6.2 6.2 0 01-2.79 2.3 6.08 6.08 0 01-2.42.44zm0-10.5a4.14 4.14 0 00-2.34.72 4.26 4.26 0 00-.64 6.52 4.13 4.13 0 002.16 1.16 4.2 4.2 0 004.32-1.8 4.27 4.27 0 00.39-4 4.13 4.13 0 00-.92-1.37 4.21 4.21 0 00-3-1.25zm9.3 1h-.39a1 1 0 010-2h.39a1 1 0 010 2z" />
  </svg>
));
Camera.displayName = 'Camera';
