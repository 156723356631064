import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackClickedConnectToSlackOptionParams } from './useTrackClickedConnectToSlackOption';

export type TrackConnectedSlackIntegrationParams = TrackClickedConnectToSlackOptionParams & {
  channelName: string;
};

/**
 * Tracks when a user connects a slack integration
 */
export const useTrackConnectedSlackIntegration = () => {
  const { track } = useAnalyticsUtilities();

  const trackConnectedSlackIntegration = useCallback(
    (params: TrackConnectedSlackIntegrationParams) => {
      track('Connected slack integration', params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackConnectedSlackIntegration };
};
