import { forwardRef, SVGProps } from 'react';

export interface FigmaBackIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaBackIcon = forwardRef<SVGSVGElement, FigmaBackIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M5.70717 7.99998L10.3537 3.35358L9.64657 2.64648L4.29297 7.99998L9.64657 13.3536L10.3537 12.6465L5.70717 7.99998Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaBackIcon.displayName = 'FigmaBackIcon';
