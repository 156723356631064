import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackDownloadedItemsParams = {
  boardIds?: string[];
  clipIds?: string[];
  location: AirActionTrackingLocation;
};

/**
 * Use this hook to track when a user downloads any combination of clips/boards (private or public)
 */
export const useTrackDownloadedItems = () => {
  const { track } = useAnalyticsUtilities();

  const trackDownloadedItems = useCallback(
    (params: TrackDownloadedItemsParams) => {
      track(`downloaded_items`, params);
    },
    [track],
  );

  return { trackDownloadedItems };
};
