import { WorkspaceMemberRoleDisplayName } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type GeneratedInviteMemberLink = {
  roleId: string;
  role: WorkspaceMemberRoleDisplayName;
};

export const useTrackGeneratedInviteMemberLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackGeneratedInviteMemberLink = useCallback(
    (params: GeneratedInviteMemberLink) => {
      track(`Generated a member invite link`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackGeneratedInviteMemberLink };
};
