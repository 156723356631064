import Cookies from 'js-cookie';

export const wipeAllLocalData = (filterDataFn: (key: string) => boolean) => {
  if (typeof window !== 'undefined') {
    const allCookies = Cookies.get();
    Object.keys(allCookies)
      // get all the Cogntio-related keys
      .filter(filterDataFn)
      // and remove them
      .forEach((key) => Cookies.remove(key));

    Object.keys(localStorage)
      // get all the Cogntio-related keys
      .filter(filterDataFn)
      // and remove them
      .forEach((key) => localStorage.removeItem(key));
  }
};
