import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackDownloadedProxyParams = {
  id: string;
  proxyId: string;
  location: AirActionTrackingLocation;
};

export const useTrackDownloadedProxyAssets = () => {
  const { track } = useAnalyticsUtilities();

  const trackDownloadedProxyAssets = useCallback(
    (params: TrackDownloadedProxyParams) => {
      track(`Downloaded proxy assets`, params);
    },
    [track],
  );

  return { trackDownloadedProxyAssets };
};
