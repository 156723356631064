import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackClickedUpgradeToEnterpriseCTA = {
  location: AirActionTrackingLocation;
};

export const useTrackClickedUpgradeToEnterpriseCTA = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedUpgradeToEnterpriseCTA = useCallback(
    (params: TrackClickedUpgradeToEnterpriseCTA) => {
      track(`Clicked 'Upgrade to Enterprise' CTA`, params);
    },
    [track],
  );

  return { trackClickedUpgradeToEnterpriseCTA };
};
