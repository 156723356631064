import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAnnotationUndoClicked = () => {
  const { track } = useAnalyticsUtilities();

  const trackAnnotationUndoClicked = useCallback(() => {
    track(`Annotation undo clicked`);
  }, [track]);

  return { trackAnnotationUndoClicked };
};
