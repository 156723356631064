import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackOpenedVersionsTray = () => {
  const { track } = useAnalyticsUtilities();

  const trackOpenedVersionsTray = useCallback(() => {
    track(`Opened versions tray`);
  }, [track]);

  return { trackOpenedVersionsTray };
};
