import type { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import type { AirActionTrackingLocation } from '../types';
import META from './useTrackAddedClipsToBoard.meta';

export type TrackAddedClipsToBoardParams = {
  boardIds: Board['id'][];
  clipIds: Clip['id'][];
  location: AirActionTrackingLocation;
};

export const useTrackAddedClipsToBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackAddedClipsToBoard = useCallback(
    (params: TrackAddedClipsToBoardParams) => {
      track(META.name, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackAddedClipsToBoard };
};
