import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type ClickedNotifyAdminLink = {
  location:
    | 'Subscription expired modal'
    | 'Subscription expired banner'
    | 'Out of storage banner'
    | 'Member limit mini banner';
};

export const useTrackClickedNotifyAdminLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedNotifyAdminLink = useCallback(
    (params: ClickedNotifyAdminLink) => {
      track(`Clicked "Notify Admin"`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackClickedNotifyAdminLink };
};
