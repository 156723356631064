import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClickedVideoFullScreenButton = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedVideoFullScreenButton = useCallback(() => {
    track(`Clicked full screen button in video player`);
  }, [track]);

  return { trackClickedVideoFullScreenButton };
};
