import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type ClickedUpgradeButton = { plan: 'plus' | 'pro' };

/**
 * Logs event for when a user clicks "Upgrade" on the PaidPlansModal and tracks which plan it is
 */
export const useTrackClickedUpgradeButton = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedUpgradeButton = useCallback(
    (params: ClickedUpgradeButton) => {
      track(`Clicked upgrade button`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackClickedUpgradeButton };
};
