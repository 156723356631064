import { Meta } from '../types/docs';

const META: Meta = {
  name: 'rejected_library_access',
  description: 'Rejected library access for user',
  params: [
    {
      name: 'library_id',
      description: 'The id of the library',
      required: true,
      type: 'string',
    },
    {
      name: 'library_member_id',
      description: 'The id of the updated library member',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
