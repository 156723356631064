import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackDuplicatedBoardParams = {
  boardId: Board['id'];
  boardTitle: Board['title'];
  location: AirActionTrackingLocation;
};

export const useTrackDuplicatedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackDuplicatedBoard = useCallback(
    (params: TrackDuplicatedBoardParams) => {
      track(`Duplicated board`, params);
    },
    [track],
  );

  return { trackDuplicatedBoard };
};
