import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackSessionExpired.meta';

export type UseTrackSessionExpiredParams = {
  session_duration_time: number;
};

export const useTrackSessionExpired = () => {
  const { track } = useAnalyticsUtilities();

  const trackSessionExpired = useCallback(
    (params: UseTrackSessionExpiredParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackSessionExpired };
};
