import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackNavigatedToWorkspaceSettingsTab = {
  tab: 'tags' | 'fields';
  location: 'asset-modal';
};

export const useTrackNavigatedToWorkspaceSettingsTab = () => {
  const { track } = useAnalyticsUtilities();

  const trackNavigatedToWorkspaceSettingsTab = useCallback(
    (params: TrackNavigatedToWorkspaceSettingsTab) => {
      track(`Navigated to workspace settings tab`, params);
    },
    [track],
  );

  return { trackNavigatedToWorkspaceSettingsTab };
};
