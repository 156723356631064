import { forwardRef, SVGProps } from 'react';

export interface CloudPullProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const CloudPull = forwardRef<SVGSVGElement, CloudPullProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.79728 2.4502C5.63892 2.4502 3.97804 4.17531 3.77555 6.35022C2.15171 6.63118 0.950195 8.1817 0.950195 9.92757C0.950195 11.8653 2.42606 13.5502 4.3577 13.5502C4.66145 13.5502 4.9077 13.304 4.9077 13.0002C4.9077 12.6964 4.66145 12.4502 4.3577 12.4502C3.11433 12.4502 2.0502 11.3418 2.0502 9.92757C2.0502 8.50639 3.12071 7.40494 4.30478 7.40494C4.60854 7.40494 4.85478 7.1587 4.85478 6.85494V6.79908C4.85478 4.93481 6.18636 3.5502 7.79728 3.5502C9.41856 3.5502 10.7927 4.94598 10.7927 6.79908V6.85494C10.7927 7.1587 11.0389 7.40494 11.3427 7.40494C12.5861 7.40494 13.6502 8.51333 13.6502 9.92757C13.6502 11.3418 12.5861 12.4502 11.3427 12.4502C11.0389 12.4502 10.7927 12.6964 10.7927 13.0002C10.7927 13.304 11.0389 13.5502 11.3427 13.5502C13.2743 13.5502 14.7502 11.8653 14.7502 9.92757C14.7502 8.17082 13.5371 6.62187 11.8711 6.34787C11.6614 4.16526 9.94577 2.4502 7.79728 2.4502ZM8.15641 13.3646C8.05201 13.4827 7.90197 13.5503 7.74435 13.5503C7.58673 13.5503 7.43669 13.4827 7.33229 13.3646L5.85062 11.6886C5.64943 11.461 5.67082 11.1134 5.8984 10.9123C6.12597 10.7111 6.47355 10.7325 6.67475 10.96L7.19435 11.5478V7.52543C7.19435 7.22168 7.44059 6.97543 7.74435 6.97543C8.04811 6.97543 8.29435 7.22168 8.29435 7.52543V11.5478L8.81396 10.96C9.01515 10.7325 9.36273 10.7111 9.5903 10.9123C9.81788 11.1134 9.83927 11.461 9.63808 11.6886L8.15641 13.3646Z"
      clipRule="evenodd"
    />
  </svg>
));
CloudPull.displayName = 'CloudPull';
