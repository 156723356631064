import { forwardRef, SVGProps } from 'react';

export interface MemberRemoveProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const MemberRemove = forwardRef<SVGSVGElement, MemberRemoveProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 16 16">
    <path d="M7.00143 8.81312C8.84643 8.81312 10.3514 7.04131 10.3514 4.86293C10.3514 3.97103 9.99848 3.11565 9.37024 2.48498C8.74199 1.85431 7.8899 1.5 7.00143 1.5C6.11295 1.5 5.26087 1.85431 4.63262 2.48498C4.00437 3.11565 3.65143 3.97103 3.65143 4.86293C3.66643 7.07644 5.14143 8.81312 7.00143 8.81312ZM7.00143 2.63436C7.29053 2.63633 7.5764 2.6956 7.84262 2.80879C8.10883 2.92198 8.35015 3.08684 8.55272 3.29392C8.75528 3.501 8.9151 3.74621 9.02299 4.01547C9.13089 4.28472 9.18473 4.57273 9.18143 4.86293C9.18143 6.36872 8.18143 7.62857 7.01643 7.62857C5.85143 7.62857 4.85143 6.4139 4.85143 4.86293C4.84472 4.28195 5.06686 3.72181 5.46952 3.30444C5.87218 2.88707 6.42276 2.64623 7.00143 2.63436ZM7.21143 9.50574C5.94389 9.51927 4.71284 9.93345 3.69317 10.6894C2.67351 11.4454 1.91711 12.5048 1.53143 13.7169C1.50119 13.8062 1.49261 13.9014 1.5064 13.9947C1.52019 14.0879 1.55596 14.1765 1.61073 14.2531C1.6655 14.3297 1.73769 14.392 1.8213 14.435C1.90491 14.4779 1.99752 14.5002 2.09143 14.4999H7.21143C7.36791 14.4999 7.51798 14.4375 7.62862 14.3265C7.73927 14.2154 7.80143 14.0647 7.80143 13.9077C7.80143 13.7506 7.73927 13.5999 7.62862 13.4889C7.51798 13.3778 7.36791 13.3154 7.21143 13.3154H3.00143C3.40119 12.5338 4.00601 11.8767 4.7506 11.4149C5.49519 10.9532 6.35127 10.7044 7.22643 10.6953C8.24649 10.7084 9.23652 11.0438 10.056 11.6538C10.8754 12.2637 11.4826 13.1173 11.7914 14.0934C11.8297 14.2121 11.9045 14.3154 12.0052 14.3885C12.1059 14.4616 12.2272 14.5007 12.3514 14.4999C12.4453 14.5002 12.538 14.4779 12.6216 14.435C12.7052 14.392 12.7774 14.3297 12.8321 14.2531C12.8869 14.1765 12.9227 14.0879 12.9365 13.9947C12.9503 13.9014 12.9417 13.8062 12.9114 13.7169C12.5247 12.5015 11.7653 11.4399 10.7416 10.6836C9.71794 9.9273 8.4824 9.51506 7.21143 9.50574ZM10.75 7.5C10.3358 7.5 10 7.83579 10 8.25C10 8.66421 10.3358 9 10.75 9H13.75C14.1642 9 14.5 8.66421 14.5 8.25C14.5 7.83579 14.1642 7.5 13.75 7.5H10.75Z" />
  </svg>
));
MemberRemove.displayName = 'MemberRemove';
