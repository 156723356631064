import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackCopiedShareLinkParams = {
  id: string;
  shareId: string;
  type: 'clip' | 'board';
  location: AirActionTrackingLocation;
};

export const useTrackCopiedShareLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackCopiedShareLink = useCallback(
    (params: TrackCopiedShareLinkParams) => {
      track(`Copied a share url`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });
    },
    [track],
  );

  return { trackCopiedShareLink };
};
