import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

type TrackExportImagesParams = {
  format: string;
  size?: string;
};

export const useTrackClickedExportAsButton = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedExportAsButton = useCallback(
    (params: TrackExportImagesParams) => {
      track(`'Export as' button clicked`, params);
    },
    [track],
  );

  return { trackClickedExportAsButton };
};
