import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackViewedOnboardingSize = () => {
  const { track } = useAnalyticsUtilities();

  const trackViewedOnboardingSize = useCallback(() => {
    track(`Viewed onboarding company size`, undefined, { integrations: { Intercom: true } });
  }, [track]);

  return { trackViewedOnboardingSize };
};
