import { forwardRef, SVGProps } from 'react';

export interface FigmaImageIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaImageIcon = forwardRef<SVGSVGElement, FigmaImageIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M13 3H3L3 9.29289L5.14645 7.14645L5.5 6.79289L5.85355 7.14645L11.7071 13H13V3ZM3 13L3 10.7071L5.5 8.20711L10.2929 13H3ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H3ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM12 6C12 7.10457 11.1046 8 10 8C8.89543 8 8 7.10457 8 6C8 4.89543 8.89543 4 10 4C11.1046 4 12 4.89543 12 6Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaImageIcon.displayName = 'FigmaImageIcon';
