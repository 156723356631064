import { Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackUploadedAsset = () => {
  const { track } = useAnalyticsUtilities();

  const trackUploadedAsset = useCallback(
    (params: { clipId: Clip['id'] }) => {
      track(`Uploaded an asset`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });
    },
    [track],
  );

  return { trackUploadedAsset };
};
