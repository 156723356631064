import { type OAuthConfiguration } from '@air/api';

export const OAUTH_CONFIG: OAuthConfiguration = {
  clientId: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID!,
  domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN!,
  scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  responseType: 'code',
  redirectSignIn: process.env.NEXT_PUBLIC_OAUTH_SIGNIN_REDIRECT!,
  redirectSignOut: process.env.NEXT_PUBLIC_OAUTH_SIGNOUT_REDIRECT!,
};

export const ANONYMOUS_OAUTH_CONFIG: OAuthConfiguration = {
  ...OAUTH_CONFIG,
  clientId: process.env.NEXT_PUBLIC_ANONYMOUS_OAUTH_CLIENT_ID!,
};
