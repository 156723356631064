import { forwardRef, SVGProps } from 'react';

export interface BehanceProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Behance = forwardRef<SVGSVGElement, BehanceProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M13.56 16.79a1.91 1.91 0 00-1.17-.33H9.94v2.81h2.4a3.24 3.24 0 00.64-.06 1.39 1.39 0 00.53-.22 1.07 1.07 0 00.36-.4 1.48 1.48 0 00.14-.67 1.34 1.34 0 00-.45-1.13zm-.35-2.15a1.07 1.07 0 00.38-.93 1.26 1.26 0 00-.12-.59 1 1 0 00-.35-.36 1.42 1.42 0 00-.49-.17 2.78 2.78 0 00-.58-.05H9.94v2.38h2.28a1.63 1.63 0 00.99-.28zM4 4v24h24V4zm14.54 7.48h4.14v1h-4.14zM16 19.39a2.57 2.57 0 01-.81.92 3.34 3.34 0 01-1.16.53 5 5 0 01-1.33.17h-5V10.79h4.82a6.74 6.74 0 011.33.13 2.88 2.88 0 011 .43 2 2 0 01.66.77 2.73 2.73 0 01.24 1.2 2.25 2.25 0 01-.35 1.29 2.5 2.5 0 01-1 .84 2.48 2.48 0 011.41 1 3.15 3.15 0 01.16 3zm8.34-1.59H19a2.06 2.06 0 00.51 1.44 1.78 1.78 0 001.31.45 1.84 1.84 0 001.06-.31 1.32 1.32 0 00.55-.66h1.79a3.43 3.43 0 01-1.32 1.91 3.9 3.9 0 01-2.15.58 4.35 4.35 0 01-1.57-.28 3.46 3.46 0 01-1.2-.8 3.73 3.73 0 01-.75-1.23 4.48 4.48 0 01-.27-1.58 4.4 4.4 0 01.28-1.55 3.66 3.66 0 01.76-1.24 3.71 3.71 0 011.2-.82 3.88 3.88 0 011.54-.31 3.58 3.58 0 011.65.37 3.22 3.22 0 011.15 1 4 4 0 01.65 1.41 5 5 0 01.1 1.62zm-3.65-2.86a1.67 1.67 0 00-.81.17 1.59 1.59 0 00-.51.4 1.53 1.53 0 00-.27.52 2.67 2.67 0 00-.1.49h3.31a1.94 1.94 0 00-.47-1.17 1.51 1.51 0 00-1.2-.41z" />
  </svg>
));
Behance.displayName = 'Behance';
