export type NetworkStatusInfoListener = (isOnline: boolean) => void;

const CHECK_INTERVAL = 10_000;

export class _NetworkStatusInfo {
  /** A boolean storing the state whether or no the user is online */
  private isConnected = true;

  /** This array stores the online and offline listeners passed into subcribe by the consumer of this class */
  private listeners: NetworkStatusInfoListener[] = [];

  /**
   * After a user goes offline, a recurring check (pingEndpoint) to see if the user is online is made.
   * This is the number of milliseconds that interval should be
   *  */
  private onlineCheckInterval: number | undefined;

  /**
   * This is the promise to that is used if multiple calls to get if the user is online are made at the same time
   * to prevent multiple calls going out across the network
   * */
  private checkingIfOnlinePromise: Promise<boolean> | null = null;

  /** The URL to hit to see if the user is online */
  private _endpoint: string | null = null;

  /** This number indicates how low the fetch to check whether the user is online should timeout after */
  private _timeout = 5000;

  public hitEndpoint = () =>
    this._endpoint
      ? fetch(this._endpoint)
          .then(() => true)
          .catch(() => false)
      : Promise.reject();

  /**
   * This
   * We do a race here so that if the fetch to the Air API,
   * we can report back to the user after certain amount of time (this._timeout)
   * the user is offline
   * */
  public _checkIfOnline = () =>
    Promise.race([
      this.hitEndpoint(),
      new Promise<boolean>((resolve) => setTimeout(() => resolve(false), this._timeout)),
    ]);

  set timeout(timeout: number) {
    this._timeout = timeout;
  }
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  get isOnline(): boolean {
    return this.isConnected;
  }

  /**
   * Call this to set the state to online.
   * If the state was previously offline, any subscribers will be notified.
   * */
  public setIsOnline = () => {
    if (this.onlineCheckInterval) {
      window.clearInterval(this.onlineCheckInterval);
    }
    const wasOffline = !this.isConnected;
    this.isConnected = true;
    if (wasOffline) {
      this.listeners.forEach((statusChanged) => statusChanged(true));
    }
  };

  /**
   * Call this to set the state to offline.
   * If the state was previously online, any subscribers will be notified.
   * */
  public setIsOffline = () => {
    const wasOnline = this.isConnected;
    this.isConnected = false;

    if (wasOnline) {
      this.listeners.forEach((statusChanged) => statusChanged(false));
      this.startOnlineCheck();
    }
  };

  /**
   * Use this to subscribe when the user's network goes online/offline
   * @param listener the function to call when the network status changes
   */
  public subscribe = (listener: NetworkStatusInfoListener) => {
    this.listeners.push(listener);

    return {
      unsubscribe: () => {
        this.listeners = this.listeners.filter((l) => l !== listener);
      },
    };
  };

  /**
   * This functions checks if the user is online by actually going out to a site and pinging it.
   * If multiple calls are made to this simultaneously, it only makes one
   * by storing the first request's promise
   *
   * It leverages the `is-online` package
   * @see https://github.com/sindresorhus/is-online
   *
   * @returns {boolean}
   */
  public checkIfOnline = async () => {
    try {
      /**
       * To prevent multiple calls to the backend to see if the user is online,
       * we just check if there is one currently checking and if so,
       * we return that in-progress promise
       */
      if (this.checkingIfOnlinePromise) {
        return this.checkingIfOnlinePromise;
      }

      this.checkingIfOnlinePromise = this._checkIfOnline();

      const online = await this.checkingIfOnlinePromise;

      if (online) {
        this.setIsOnline();
      } else {
        this.setIsOffline();
      }
    } catch (_error) {
      this.setIsOffline();
    } finally {
      this.checkingIfOnlinePromise = null;
    }

    return this.isConnected;
  };

  private startOnlineCheck = () => {
    if (this.onlineCheckInterval) {
      window.clearInterval(this.onlineCheckInterval);
    }

    this.onlineCheckInterval = window.setInterval(() => this.checkIfOnline(), CHECK_INTERVAL);
  };
}

export const NetworkStatusInfo = new _NetworkStatusInfo();
