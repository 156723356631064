import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

type TrackToggledFullScreen = {
  location: 'pdf' | 'video';
};

export const useTrackExitedFullScreen = () => {
  const { track } = useAnalyticsUtilities();

  const trackExitedFullScreen = useCallback(
    (params: TrackToggledFullScreen) => {
      track(`Exited full screen`, params);
    },
    [track],
  );

  return { trackExitedFullScreen };
};
