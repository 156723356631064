import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackFiltersApplied } from '../types';

export const useTrackReturnedEmptyFiltersResult = () => {
  const { track } = useAnalyticsUtilities();

  const trackReturnedEmptyFiltersResult = useCallback(
    (params: TrackFiltersApplied) => {
      track(`Filter application returned a null state`, params);
    },
    [track],
  );

  return { trackReturnedEmptyFiltersResult };
};
