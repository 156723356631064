'use client';
import { Auth } from '@air/api';
import { useQuery } from '@tanstack/react-query';

export const CURRENT_USER_INFO_KEY = 'CURRENT_USER';

export const getCurrentUserInfoKey = () => [CURRENT_USER_INFO_KEY];

export const useCurrentUserInfo = () => {
  return useQuery({
    queryKey: getCurrentUserInfoKey(),
    queryFn: Auth.getCurrentUserInfo,
    staleTime: 1000 * 60 * 60 * 24,
  });
};
