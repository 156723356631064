import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type RemovedCollectionFormCustomField = {
  fieldId?: string;
  fieldName: string;
};

export const useTrackRemovedCustomFieldFromForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedCustomFieldFromForm = useCallback(
    (params: RemovedCollectionFormCustomField) => {
      track(`Removed a field to collection form`, params);
    },
    [track],
  );

  return { trackRemovedCustomFieldFromForm };
};
