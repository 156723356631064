import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackDeletedContentCollectionLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackDeletedContentCollectionLink = useCallback(() => {
    track(`Deleted a content collection link`);
  }, [track]);

  return { trackDeletedContentCollectionLink };
};
