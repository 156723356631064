import type { Subscription, User, Workspace } from '@air/api/types';
import { Options } from '@segment/analytics-next';
import { useCallback } from 'react';

import { eventLogger } from '../utils/logger';
import { useAnalyticsContext } from './useAnalyticsContext';

interface SegmentGlobalProps
  extends Partial<
    Pick<Subscription, 'subscribed' | 'plan'> & {
      workspaceId: Workspace['id'];
      workspaceName: Workspace['name'];
    }
  > {
  email_verified?: boolean;
  is_in_paid_workspace?: boolean;
  OS?: string;
  screen_height?: number;
  screen_width?: number;
  initial_referrer?: string;
  initial_referring_domain?: string;
  airAccountId?: string;
  referrer?: string | null;
  referring_domain?: string | null;
  userEmail?: string;
  accountType?: string;
}

type SegmentUserProps = {
  email: string;
  firstName: string;
  lastName: string;
};

type SegmentPersonObject = {
  first_name?: string;
  last_name?: string;
  email?: string;
  account_type?: string;
};

type includeDestinations = 'Intercom' | 'Customer.io (Actions)';

type OptionsParams = Options & {
  integrations?: {
    [key in includeDestinations]?: boolean;
  };
};

/**
 * Hook for analytics functions which rely on useAnalyticsContext
 */
export const useAnalyticsUtilities = () => {
  const { analyticsRef, globalPropsRef } = useAnalyticsContext();

  // sets global properties which will then be passed to every subsequent track call
  const setGlobalEventProps = useCallback(
    (params: SegmentGlobalProps) => {
      globalPropsRef.current = { ...globalPropsRef.current, ...params };
    },
    [globalPropsRef],
  );

  // resets global properties
  const resetGlobalEventProps = useCallback(() => {
    globalPropsRef.current = {};
  }, [globalPropsRef]);

  // this is used to track page views.
  // many destinations require this to be called at least once to initialize properly
  const page = useCallback(
    (category?: string, name?: string, params?: object, options?: OptionsParams) => {
      const { integrations, ...rest } = options || {};

      analyticsRef.current?.page(
        category,
        name,
        {
          ...globalPropsRef.current,
          ...(params || {}),
        },
        {
          integrations: {
            // page events can interact with Intercom's messenger. we explicitly avoid sending page events there unless otherwise overridden (like in trackShowIntercomWidget).
            // an intercom initialization page event is located in AnalyticsPageRouting
            Intercom: false,
            ...integrations,
          },
          ...rest,
        },
      );

      eventLogger(`page: ${category}, ${name}`);
    },
    [analyticsRef, globalPropsRef],
  );

  // this is used for generalized tracking events
  const track = useCallback(
    (event: string, params?: object, options?: OptionsParams) => {
      const { integrations, ...rest } = options || {};
      analyticsRef.current?.track(
        event,
        {
          ...globalPropsRef.current,
          ...(params || {}),
        },
        {
          integrations: {
            // Intercom is a "device-mode" source in Segment; this means we must specifiy in code if an event should be routed there.
            // However, there is a limit on the number of events Intercom can accept, so it doesn't send by default
            Intercom: false,
            ...integrations,
          },
          ...rest,
        },
      );
      eventLogger(`track: ${event}`);
    },
    [analyticsRef, globalPropsRef],
  );

  // this is used to track external link clicks, it delays leaving the page to give the track event time to complete
  // **This needs an actual element, not just a selector like with Mixpanel!**
  const trackLink = useCallback(
    (selector: HTMLElement, event: string, params?: object, options?: OptionsParams) => {
      const { integrations, ...rest } = options || {};

      analyticsRef.current?.trackLink(
        selector,
        event,
        {
          ...globalPropsRef.current,
          ...(params || {}),
        },
        {
          integrations: {
            Intercom: false,
            ...integrations,
          },
          ...rest,
        },
      );
      eventLogger(`trackLink, ${selector}`);
    },
    [analyticsRef, globalPropsRef],
  );

  // this is used to send information about a user
  const identify = useCallback(
    (id?: User['id'], traits?: SegmentPersonObject, options?: OptionsParams) => {
      const { integrations, ...rest } = options || {};

      analyticsRef.current?.identify(id, traits, {
        integrations,
        /**
         * Intercom is an integration that we use and should be included in each `identify` call.
         */
        Intercom: {
          hideDefaultLauncher: true,
        },
        ...rest,
      });
      eventLogger(`identify: ${traits}`);
    },
    [analyticsRef],
  );

  // this is a Mixpanel-specific call that associates previously anonymous events with a now-known user
  // it will no longer be necessary if we enable Mixpanel ID Merge
  const alias = useCallback(
    (id: string) => {
      analyticsRef.current?.alias(id, undefined, {
        integrations: {
          All: false,
          Mixpanel: true,
        },
      });
      eventLogger(`alias: ${id}`);
    },
    [analyticsRef],
  );

  // resets user, id, traits, cookies, localstorage
  // integrated third-party tools might have their own cookies/localstorage not covered by this method
  const reset = useCallback(() => {
    analyticsRef.current?.reset();
    eventLogger('reset');
  }, [analyticsRef]);

  // an identify call for partial data
  // TODO: see if this legacy code is still needed
  const updateUser = useCallback(
    (user: Partial<SegmentUserProps>) => {
      let personObject: SegmentPersonObject = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
      };

      personObject = Object.keys(personObject).reduce((accumulator, current) => {
        // @ts-ignore
        if (personObject[current]) {
          // @ts-ignore
          accumulator[current] = personObject[current];
        }
        return accumulator;
      }, {} as SegmentPersonObject);

      analyticsRef.current?.identify(personObject);
      eventLogger('set_person');
    },
    [analyticsRef],
  );

  return {
    setGlobalEventProps,
    resetGlobalEventProps,
    page,
    track,
    trackLink,
    identify,
    alias,
    reset,
    updateUser,
  };
};
