import { config } from '@air/api';
import { type ConfigureAPIPackageParams } from '@air/utils-auth';
import { defaultsDeep, isUndefined } from 'lodash';

// These imports must start with ../, because this file is imported in Cypress tests
import { getAirApiConfig } from '../constants/airApi';
import { getAnonymousCognitoConfig, getCognitoConfig } from '../constants/cognito';
import { getAnonymousOAuthConfig, getOauthConfig } from '../constants/oauth';

/**
 * This function will configure the API to set the api endpoint and oauth properties.
 * If any values are missing that are required, it will throw errors at build time.
 *
 * @deprecated Use `configureAPIPackage` from `@air/utils-auth` instead. We're keeping
 * this function for compatibility with Cypress tests in the meantime, but moving forward
 * we should use the `@air/utils-auth` version because it doesn't require `window.Cypress`.
 */
export const configureAPIPackage = (params: DeepPartial<ConfigureAPIPackageParams> = {}) => {
  const defaults: ConfigureAPIPackageParams = {
    cognitoConfigParams: getCognitoConfig(),
    oauthConfigParams: getOauthConfig(),
    baseUrl: getAirApiConfig().baseUrl,
  };

  const _params: ConfigureAPIPackageParams = defaultsDeep(params, defaults);

  const isBaseUrlValid = _params.baseUrl;
  const isCognitoValid =
    _params.cognitoConfigParams.region &&
    _params.cognitoConfigParams.userPoolId &&
    _params.cognitoConfigParams.authenticationFlowType &&
    !isUndefined(_params.cognitoConfigParams.mandatorySignIn);

  const isOauthValid =
    _params.oauthConfigParams.clientId &&
    _params.oauthConfigParams.domain &&
    _params.oauthConfigParams.redirectSignIn &&
    _params.oauthConfigParams.scope;

  const errors = [];

  if (!isBaseUrlValid) {
    errors.push('A baseUrl has not been set');
  }

  if (!isCognitoValid) {
    errors.push(
      'The cognito config must include a region, userPoolId, authenticationFlowType, and mandatorySignIn property',
    );
  }

  if (!isOauthValid) {
    errors.push('The oauth config must include a clientId, domain, redirectSignIn, and scope');
  }

  if (errors.length > 0) {
    errors.push('Current config:', JSON.stringify(params, null, 2));

    throw new Error(errors.join('\n'));
  }

  config.configure({
    ssr: true,
    ..._params,
  });
};

/**
 * @deprecated Use `configureAPIPackageForAnonymousSignUp` from `@air/utils-auth` instead.
 */
export const configureAPIPackageForAnonymousSignUp = () =>
  configureAPIPackage({
    cognitoConfigParams: getAnonymousCognitoConfig(),
    oauthConfigParams: getAnonymousOAuthConfig(),
    baseUrl: getAirApiConfig().baseUrl,
  });

/**
 * @deprecated Use `restoreAPIPackageConfiguration` from `@air/utils-auth` instead.
 */
export const restoreAPIPackageConfiguration = (savedAPIConfig: ReturnType<typeof config.getAuthConfiguration>) => {
  configureAPIPackage({
    cognitoConfigParams: {
      userPoolId: savedAPIConfig.userPoolId,
      mandatorySignIn: savedAPIConfig.mandatorySignIn,
      authenticationFlowType: savedAPIConfig.authenticationFlowType,
      region: savedAPIConfig.region,
    },
    oauthConfigParams: savedAPIConfig.oauth,
  });
};

/**
 * @deprecated Use `isAPIConfiguredForAnonymousSignUp` from `@air/utils-auth` instead.
 */
export const isAPIConfiguredForAnonymousSignUp = () => {
  const savedAPIConfig = config.getAuthConfiguration();
  return savedAPIConfig?.authenticationFlowType === getAnonymousCognitoConfig().authenticationFlowType;
};

/**
 * @deprecated Use `isApiConfiguredForFullAccountSignUp` from `@air/utils-auth` instead.
 */
export const isApiConfiguredForFullAccountSignUp = () => {
  const savedAPIConfig = config.getAuthConfiguration();
  return savedAPIConfig?.authenticationFlowType === getCognitoConfig().authenticationFlowType;
};
