import { AvailablePlanName } from '@air/api/types';
import { capitalize } from 'lodash';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { trackGTMEvent } from '../utils/trackGTMEvent';

export interface TrackCompletedCheckoutParams {
  planId: AvailablePlanName;
  totalPrice: number;
}

export const useTrackCompletedCheckout = () => {
  const { track } = useAnalyticsUtilities();

  const trackCompletedCheckout = useCallback(
    ({ planId, totalPrice }: TrackCompletedCheckoutParams) => {
      track(`Completed checkout`, undefined, { integrations: { Intercom: true } });
      trackGTMEvent({
        event: `${capitalize(planId)} Account Sign Up`,
        eventProps: { label: planId, category: 'self serve', value: totalPrice / 100 },
      });
    },
    [track],
  );

  return { trackCompletedCheckout };
};
