import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackToggleApprovedDomains = {
  approvedDomainsEnabled: boolean;
};

export const useTrackToggleApprovedDomains = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggleApprovedDomains = useCallback(
    (params: TrackToggleApprovedDomains) => {
      track(`Toggled approved domains`, params);
    },
    [track],
  );

  return { trackToggleApprovedDomains };
};
