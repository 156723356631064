import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type CollectionFormFieldRequiredStatus = {
  fieldId?: string;
  fieldName: string;
  required: boolean;
};

export const useTrackToggledFieldRequiredStatus = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledFieldRequiredStatus = useCallback(
    (params: CollectionFormFieldRequiredStatus) => {
      track(`Toggled field required status`, params);
    },
    [track],
  );

  return { trackToggledFieldRequiredStatus };
};
