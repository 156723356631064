import { config } from '@air/api';

import { configureAPIPackage } from './configureAPIPackage';
import { isApiConfiguredForFullAccountSignUp } from './isApiConfiguredForFullAccountSignUp';
import { restoreAPIPackageConfiguration } from './restoreAPIPackageConfiguration';
/**
 * This method configures cognito to use full account user pool and calls callback using this configuration
 * If callback throws an error, it restores original configuration
 * If callback succeeds, full user pool is set as current config
 */
export const callOnFullAccountUserPool = async <T>(callback: () => Promise<T> | T) => {
  /**
   * If the API is already configured for full account sign up, call the callback and return.
   */
  if (isApiConfiguredForFullAccountSignUp()) {
    return callback();
  }

  /**
   * Configure the API as anonymous, call the callback and return the original configuration.
   */
  const savedAPIConfig = config.getAuthConfiguration();

  try {
    configureAPIPackage();

    return await callback();
  } catch (error) {
    if (savedAPIConfig) {
      restoreAPIPackageConfiguration(savedAPIConfig);
    }

    throw error;
  }
};
