import { forwardRef, SVGProps } from 'react';

export interface FigmaArrowUpIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaArrowUpIcon = forwardRef<SVGSVGElement, FigmaArrowUpIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.99998 2.29297L7.64642 2.64652L4.64642 5.64652L5.35353 6.35363L7.49998 4.20718L7.49998 12.5001H8.49998L8.49998 4.20718L10.6464 6.35363L11.3535 5.64652L8.35353 2.64652L7.99998 2.29297Z"
      clipRule="evenodd"
      fillOpacity="0.8"
      fillRule="evenodd"
    />
  </svg>
));

FigmaArrowUpIcon.displayName = 'FigmaArrowUpIcon';
