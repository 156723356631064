import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { TrackFavoritedBoardParams } from '../types';

export const useTrackFavoritedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackFavoritedBoard = useCallback(
    (params: TrackFavoritedBoardParams) => {
      track(`Favorited board`, params);
    },
    [track],
  );

  return { trackFavoritedBoard };
};
