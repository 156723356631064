import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackShownInactivityModal.meta';

export const useTrackShownInactivityModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackShownInactivityModal = useCallback(() => {
    track(META.name);
  }, [track]);

  return { trackShownInactivityModal };
};
