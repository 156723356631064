import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackDeletedItems.meta';

export type TrackDeletedItemsParams = {
  item_ids: string[];
  item_total: number;
  location?: string;
  item_type: 'library' | 'asset' | 'board';
};

export const useTrackDeletedItems = () => {
  const { track } = useAnalyticsUtilities();

  const trackDeletedItems = useCallback(
    (params: TrackDeletedItemsParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackDeletedItems };
};
