import { forwardRef, SVGProps } from 'react';

export interface TextEditProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const TextEdit = forwardRef<SVGSVGElement, TextEditProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M18.63 8.41h-5.54v16.17a1.5 1.5 0 01-.35 1 1.28 1.28 0 01-1 .41 1.29 1.29 0 01-1-.41 1.5 1.5 0 01-.35-1V8.41H4.71a1.17 1.17 0 01-.85-.35 1.1 1.1 0 01-.36-.84 1.18 1.18 0 01.35-.86A1.21 1.21 0 014.74 6h13.87a1.25 1.25 0 01.89.36 1.16 1.16 0 01.37.86 1.1 1.1 0 01-.36.84 1.21 1.21 0 01-.88.35zm8.58 14.93a1 1 0 00-.71-.29 2.4 2.4 0 00-.86.27 2.8 2.8 0 01-1.2.25 2.49 2.49 0 01-.82-.12 1 1 0 01-.53-.42 6.39 6.39 0 01-.35-.58 2 2 0 01-.16-.76q0-.56-.06-.84v-5.21h3.15a1.16 1.16 0 001.16-1.15 1.1 1.1 0 00-.34-.8 1.12 1.12 0 00-.82-.35h-3.16v-2.16a1.08 1.08 0 00-.28-.78 1.05 1.05 0 00-.8-.31c-.54 0-.94.33-1.19 1a11.46 11.46 0 00-.35 2.26h-1.26a1.2 1.2 0 00-.83.33 1.13 1.13 0 000 1.65 1.23 1.23 0 00.83.32h1.22v4.73c0 3.74 1.47 5.63 4.37 5.63a4.09 4.09 0 002.9-.89 1.41 1.41 0 00.38-1 1.09 1.09 0 00-.29-.78z"
    />
  </svg>
));
TextEdit.displayName = 'TextEdit';
