import { Meta } from '../types/docs';

const META: Meta = {
  name: 'update_library_details',
  description: 'User updated library details',
  params: [
    {
      name: 'library_accessibility',
      description: 'The accessibility of the library',
      required: false,
      type: 'enum',
      values: ['public', 'private'],
    },
    {
      name: 'library_id',
      description: 'The id of the library',
      required: true,
      type: 'string',
    },
    {
      name: 'library_name',
      description: 'The name of the library',
      required: false,
      type: 'string',
    },
    {
      name: 'library_visibility',
      description: 'The visibility of the library',
      required: false,
      type: 'enum',
      values: ['libraryMember', 'workspaceMember'],
    },
  ],
};

export default META;
