import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { OpenAssetModalLocations } from '../types';

export type TrackOpenedAssetModal = {
  location: OpenAssetModalLocations;
};

export const useTrackOpenedAssetModal = () => {
  const { track } = useAnalyticsUtilities();

  const trackOpenedAssetModal = useCallback(
    (params: TrackOpenedAssetModal) => {
      track(`Opened asset details modal`, params);
    },
    [track],
  );

  return { trackOpenedAssetModal };
};
