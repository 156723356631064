// This doesn't work in older broswers (Safari 10-):

// const I18n = {
//   'en-US': {
//     logInLabel: `Log in`,
//   },
// }

// export default I18n[navigator.language || navigator.userLanguage || 'en-US']

const tagline = 'The Creative Ops System for marketers';

export const I18n = {
  tagline,

  basePageTitle: `Air – ${tagline}`,
  basePageShortTitle: `Air`,
  basePageDescription:
    'Save time by centralizing your team’s content in a workspace that’s organized, searchable, and easy to share. Get started today for free!',
  basePageCardAlt: `The Air wordmark in white on navy`,

  switchPageTitle: `Wasting time in Dropbox and Google Drive folders?`,
  switchPageDescription: `Switch to Air so your team can find the creative assets they need.`,

  termsPageTitle: `Terms of service`,
  termsPageDescription: ``,

  privacyPageTitle: `Privacy policy`,
  privacyPageDescription: ``,

  jobsPageTitle: `Work at Air`,
  jobsPageDescription: `We're hiring! Check out open positions at Air.`,

  signUpPageTitle: `Sign up`,
  signUpPageDescription: `Sign up for a free Air account.`,

  inviteMembersPageTitle: `Invite members`,
  inviteMembersPageDescription: `Invite members to your workspace.`,

  loginPageTitle: `Log in`,
  loginPageDescription: `Log in to your Air account.`,

  logoutPageTitle: `Log out`,
  logoutPageDescription: `Log out of your Air account.`,

  forgotPageTitle: `Forgot password`,
  forgotPageDescription: `Forgot your Air account password.`,

  notFoundPageTitle:
    'Afraid there’s nothing but hot air here, maybe you entered the wrong address or the page was deleted?',
  notFoundPageDescription: '(Lucky for you, we have deletion recovery so same could never be said about your assets)',

  noWorkspacePermissionTitle: `You need access`,
  noWorkspacePermissionDescription: `You do not have access to this workspace. Please contact an admin to add you as a member.`,

  clipsPageTitle: `Clips`,

  accountPageTitle: `Account`,

  workspacePageTitle: `Workspace`,
  workspaceCreateImportTitle: `Import`,
  workspaceIsPendingTitle: `Processing`,
  workspaceCreatePageTitle: `Create a workspace`,
  workspaceJoinPageTitle: `Join a workspace`,

  returnHomeLabel: `← Return home`,
  returnToLibraryLabel: `← Return to Library`,
};
