import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAnnotationColorChanged = () => {
  const { track } = useAnalyticsUtilities();

  const trackAnnotationColorChanged = useCallback(
    (params: { color: string }) => {
      track(`Annotation color changed`, params);
    },
    [track],
  );

  return { trackAnnotationColorChanged };
};
