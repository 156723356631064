import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type ClickedPublicBoardLink = {
  name: 'clicked google auth' | 'clicked login' | 'clicked sign up' | 'clicked view in workspace';
  page_type: 'marketing site' | 'public board' | 'public asset';
  page_area: 'banner' | 'header' | 'footer';
};

export const useTrackClickedPublicBoardLink = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedPublicBoardLink = useCallback(
    (params: ClickedPublicBoardLink) => {
      track(`Clicked public board cta`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackClickedPublicBoardLink };
};
