import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackClosedVersionsTray = () => {
  const { track } = useAnalyticsUtilities();

  const trackClosedVersionsTray = useCallback(() => {
    track(`Closed versions tray`);
  }, [track]);

  return { trackClosedVersionsTray };
};
