import { ViewTypeName } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackChangedViewType = () => {
  const { track } = useAnalyticsUtilities();

  const trackChangedViewType = useCallback(
    (params: { viewType: ViewTypeName }) => {
      track(`Switched view type`, params);
    },
    [track],
  );

  return { trackChangedViewType };
};
