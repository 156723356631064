import { Meta } from '../types/docs';

const META: Meta = {
  name: 'Added clips to a board',
  description: 'User added clips to a board',
  params: [
    {
      name: 'boardIds',
      description: 'The IDs of the boards the clips were added to',
      required: true,
      values: ['string'],
      type: 'array',
    },
    {
      name: 'clipIds',
      description: 'The IDs of the clips that were added to the boards',
      required: true,
      values: ['string'],
      type: 'array',
    },
    {
      name: 'location',
      description: 'The location where the user added clips to a board',
      required: true,
      values: [
        'action-bar',
        'asset-details',
        'board-collection-gallery',
        'board-container-null-state',
        'board-container-right-click',
        'board-select-modal',
        'board-side-panel',
        'board-stats-bar',
        'context-menu-bulk-edit',
        'create-workspace-page',
        'drag-n-drop',
        'empty-board-add-content',
        'file-uploader',
        'gallery-context-menu',
        'gallery-item-context-menu',
        'gallery-item-ellipsis-menu',
        'gallery-multi-select-context-menu',
        'gallery-multi-select-ellipsis-menu',
        'guest-invite-modal',
        'header',
        'header-bar-add-button',
        'kanban-view',
        'library',
        'mobile-bottom-nav',
        'pdf-control-bar',
        'pdf-page-preview',
        'private-gallery-page',
        'private-kanban-page',
        'public-asset-page',
        'public-gallery-page',
        'public-kanban-page',
        'saved-filter',
        'side-nav-saved-filters',
        'side-nav-extension',
        'side-nav-extension-end-button',
        'side-nav-extension-item-button',
        'side-nav-extension-item-context-menu',
        'side-nav-extension-item-ellipsis-menu',
        'side-nav-extension-item-sub-button',
        'side-nav-tree-item',
        'table-cell',
        'table-header',
        'table-item-context-menu',
        'table-item-ellipsis-menu',
        'table-multi-select-context-menu',
        'table-multi-select-ellipsis-menu',
        'tag-manager',
        'workspace-settings',
        'workspace-settings-security',
      ],
      type: 'enum',
    },
  ],
};

export default META;
