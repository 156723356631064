import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type TrackToggledFullScreen = {
  location: 'pdf' | 'video';
};

export const useTrackEnteredFullScreen = () => {
  const { track } = useAnalyticsUtilities();

  const trackEnteredFullScreen = useCallback(
    (params: TrackToggledFullScreen) => {
      track(`Entered full screen`, params);
    },
    [track],
  );

  return { trackEnteredFullScreen: trackEnteredFullScreen };
};
