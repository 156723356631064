import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackRemovedClipsFromBoardParams = {
  boardId: Board['id'];
  clipIds: Clip['id'][];
  location: AirActionTrackingLocation;
};

export const useTrackRemovedClipsFromBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedClipsFromBoard = useCallback(
    (params: TrackRemovedClipsFromBoardParams) => {
      track(`Removed clips from board`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackRemovedClipsFromBoard };
};
