import type { Library } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackUpdateLibraryDetails.meta';

export type UseTrackUpdateLibraryDetailsParams = {
  library_accessibility?: Library['accessibility'];
  library_id: Library['id'];
  library_name?: Library['title'];
  library_visibility?: Library['visibility'];
};

export const useTrackUpdateLibraryDetails = () => {
  const { track } = useAnalyticsUtilities();

  const trackUpdateLibraryDetails = useCallback(
    (params: UseTrackUpdateLibraryDetailsParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackUpdateLibraryDetails };
};
