import { forwardRef, SVGProps } from 'react';

export interface PlusSquareProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const PlusSquare = forwardRef<SVGSVGElement, PlusSquareProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 11V21M21 16H11M16 4H8C5.79086 4 4 5.79086 4 8V24C4 26.2091 5.79086 28 8 28H24C26.2091 28 28 26.2091 28 24V8C28 5.79086 26.2091 4 24 4H16Z"
    />
  </svg>
));

PlusSquare.displayName = 'PlusSquare';
