import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackCopiedPublicBoardParams = {
  id: string;
  workspaceId: string;
  location: AirActionTrackingLocation;
};

export const useTrackCopiedPublicBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackCopiedPublicBoard = useCallback(
    (params: TrackCopiedPublicBoardParams) => {
      track(`Copied public board`, params);
    },
    [track],
  );

  return { trackCopiedPublicBoard };
};
