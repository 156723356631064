import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackSwitchedSortType = () => {
  const { track } = useAnalyticsUtilities();

  /** Added `location` param for the side subnav board sort, that's the only place using `location` for now */
  const trackSwitchedSortType = useCallback(
    (params: { sortingFieldName: string; direction: string; location?: string }) => {
      track(`Switched sort type`, params);
    },
    [track],
  );

  return { trackSwitchedSortType };
};
