import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAnnotationSizeChanged = () => {
  const { track } = useAnalyticsUtilities();

  const trackAnnotationSizeChanged = useCallback(
    (params: { size: number }) => {
      track(`Annotation size changed`, params);
    },
    [track],
  );

  return { trackAnnotationSizeChanged };
};
