import { forwardRef, SVGProps } from 'react';

export interface FigmaPlusIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaPlusIcon = forwardRef<SVGSVGElement, FigmaPlusIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.5 7.5V2.5H8.5V7.5H13.5V8.5H8.5V13.5H7.5V8.5H2.5V7.5H7.5Z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaPlusIcon.displayName = 'FigmaPlusIcon';
