import { Board, Clip } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { AirActionTrackingLocation } from '../types';

export type TrackCreatedBoardParams = Pick<Board, 'id' | 'title' | 'description'> & {
  clipIds?: Clip['id'][];
  location: AirActionTrackingLocation;
};

export const useTrackCreatedBoard = () => {
  const { track } = useAnalyticsUtilities();

  const trackCreatedBoard = useCallback(
    (params: TrackCreatedBoardParams) => {
      track(`Created a board`, params, { integrations: { Intercom: true, 'Customer.io (Actions)': true } });
    },
    [track],
  );

  return { trackCreatedBoard };
};
