import { forwardRef, SVGProps } from 'react';

export interface FigmaStarIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaStarIcon = forwardRef<SVGSVGElement, FigmaStarIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <g clip-path="url(#a)">
      <path
        fill="currentColor"
        d="M9.87882 6.21586L8.52536 2.01913L8 0.390137L7.47464 2.01913L6.12118 6.21586L1.71161 6.20622L0 6.20248L1.38692 7.20551L4.96 9.78959L3.5882 13.9804L3.05573 15.607L4.43825 14.598L8 11.9983L11.5617 14.598L12.9443 15.607L12.4118 13.9804L11.04 9.78959L14.6131 7.20551L16 6.20248L14.2884 6.20622L9.87882 6.21586ZM12.9015 7.20925L9.88101 7.21585L9.15112 7.21745L8.92709 6.52279L8 3.64813L7.07291 6.52279L6.84888 7.21745L6.11899 7.21585L3.09854 7.20925L5.54602 8.97929L6.13744 9.40702L5.91038 10.1007L4.97073 12.9713L7.41045 11.1905L8 10.7602L8.58955 11.1905L11.0293 12.9713L10.0896 10.1007L9.86256 9.40702L10.454 8.97929L12.9015 7.20925Z"
        clipRule="evenodd"
        fillOpacity="0.8"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
));

FigmaStarIcon.displayName = 'FigmaStarIcon';
