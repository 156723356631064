import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackMarkedAllNotificationsAsRead = () => {
  const { track } = useAnalyticsUtilities();

  const trackMarkedAllNotificationsAsRead = useCallback(() => {
    track(`Marked all notifications as read`);
  }, [track]);

  return { trackMarkedAllNotificationsAsRead };
};
