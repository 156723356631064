import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackActivatedSearch.meta';

export type TrackSearchLocation = string;

export type TrackActivatedSearchParams = {
  location: TrackSearchLocation;
  isQuickSearch: boolean;
};

export const useTrackActivatedSearch = () => {
  const { track } = useAnalyticsUtilities();

  const trackActivatedSearch = useCallback(
    (params: TrackActivatedSearchParams) => {
      track(META.name, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackActivatedSearch };
};
