import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { sendIntercomLauncherVisibilityEvent } from '../utils/intercomVisibilityListener';

/**
 * this hides the intercom widget/button while also opening the messenger window
 */
export const useTrackOpenIntercomChat = () => {
  const { page } = useAnalyticsUtilities();

  // page events can be used to communicate options to Intercom, see:
  // https://segment.com/docs/connections/destinations/catalog/intercom/#conditionally-show-the-intercom-chat-widget-browser-only
  const trackOpenIntercomChat = useCallback(() => {
    const intercom = window.Intercom ?? null;

    if (intercom) {
      intercom('show');

      sendIntercomLauncherVisibilityEvent(false);

      page(
        undefined,
        'intercom_hide_and_open_chat',
        {},
        { Intercom: { hideDefaultLauncher: true }, integrations: { All: false, Intercom: true } },
      );
    } else {
      // TODO is this error handling necessary?
    }
  }, [page]);

  return { trackOpenIntercomChat };
};
