import { forwardRef, SVGProps } from 'react';

export interface FigmaCaretRightIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaCaretRightIcon = forwardRef<SVGSVGElement, FigmaCaretRightIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path fill="currentColor" d="M12 8L8 5V11L12 8Z" />
  </svg>
));

FigmaCaretRightIcon.displayName = 'FigmaCaretRightIcon';
