import { forwardRef, SVGProps } from 'react';

export interface LineweightMediumProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const LineweightMedium = forwardRef<SVGSVGElement, LineweightMediumProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M8.05,28.3a1.34,1.34,0,0,1-.73-.23A1.3,1.3,0,0,1,7,26.27c.19-.28-.36-1-1-1.8-1-1.19-2.46-3-1.17-4.91C6.2,17.48,9,19.26,11.67,21c1.21.77,3.41,2.18,4.2,2.18h0c-.22-.89-3.52-3.2-5.51-4.59C6.61,16,3.07,13.46,2.73,10.82a3.13,3.13,0,0,1,.76-2.48c2.27-2.68,6.88.39,12.21,3.95a69.09,69.09,0,0,0,6.4,3.95,4.07,4.07,0,0,0,4,.21,1.38,1.38,0,0,0,.6-1.37C26.47,13,23.32,9.59,15.13,6.2a1.3,1.3,0,1,1,1-2.4C27,8.32,29,12.63,29.27,14.81a4,4,0,0,1-1.71,3.78c-1.72,1.19-4.27,1.17-6.64,0a69.8,69.8,0,0,1-6.66-4.11c-2.6-1.73-8-5.34-8.79-4.42a.52.52,0,0,0-.17.47c.2,1.5,3.87,4.08,6.55,6,4.15,2.91,7.72,5.42,6.31,8a2.44,2.44,0,0,1-1.54,1.22c-1.75.44-4-1-6.35-2.51a20.5,20.5,0,0,0-3.36-1.89,5.69,5.69,0,0,0,1,1.54c1,1.2,2.46,3,1.17,4.91A1.3,1.3,0,0,1,8.05,28.3Z" />
  </svg>
));
LineweightMedium.displayName = 'LineweightMedium';
