import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackAnnotationRedoClicked = () => {
  const { track } = useAnalyticsUtilities();

  const trackAnnotationRedoClicked = useCallback(() => {
    track(`Annotation redo clicked`);
  }, [track]);

  return { trackAnnotationRedoClicked };
};
