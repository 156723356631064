import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackChangedPaymentMethod = () => {
  const { track } = useAnalyticsUtilities();

  const trackChangedPaymentMethod = useCallback(() => {
    track('changed_payment_method');
  }, [track]);

  return { trackChangedPaymentMethod };
};
