import { forwardRef, SVGProps } from 'react';

export interface CopyrightProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Copyright = forwardRef<SVGSVGElement, CopyrightProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.99967 2.58301C9.43626 2.58301 10.814 3.15369 11.8298 4.16951C12.8457 5.18533 13.4163 6.56309 13.4163 7.99967C13.4163 9.43626 12.8457 10.814 11.8298 11.8298C10.814 12.8457 9.43626 13.4163 7.99967 13.4163C6.56309 13.4163 5.18533 12.8457 4.16951 11.8298C3.15369 10.814 2.58301 9.43626 2.58301 7.99967C2.58301 6.56309 3.15369 5.18533 4.16951 4.16951C5.18533 3.15369 6.56309 2.58301 7.99967 2.58301ZM7.99967 14.6663C9.76778 14.6663 11.4635 13.964 12.7137 12.7137C13.964 11.4635 14.6663 9.76778 14.6663 7.99967C14.6663 6.23156 13.964 4.53587 12.7137 3.28563C11.4635 2.03539 9.76778 1.33301 7.99967 1.33301C6.23156 1.33301 4.53587 2.03539 3.28563 3.28563C2.03539 4.53587 1.33301 6.23156 1.33301 7.99967C1.33301 9.76778 2.03539 11.4635 3.28563 12.7137C4.53587 13.964 6.23156 14.6663 7.99967 14.6663ZM6.52572 9.47363C5.71322 8.66113 5.71322 7.34082 6.52572 6.52832C7.33822 5.71582 8.65853 5.71582 9.47103 6.52832C9.71582 6.77311 10.1117 6.77311 10.3538 6.52832C10.596 6.28353 10.5986 5.8877 10.3538 5.64551C9.05176 4.34342 6.94238 4.34342 5.6403 5.64551C4.33822 6.94759 4.33822 9.05697 5.6403 10.359C6.94238 11.6611 9.05176 11.6611 10.3538 10.359C10.5986 10.1143 10.5986 9.71842 10.3538 9.47624C10.109 9.23405 9.71322 9.23145 9.47103 9.47624C8.65853 10.2887 7.33822 10.2887 6.52572 9.47624V9.47363Z"
    />
  </svg>
));
Copyright.displayName = 'Copyright';
