import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackCreatedContentCollectionForm = () => {
  const { track } = useAnalyticsUtilities();

  const trackCreatedContentCollectionForm = useCallback(() => {
    track(`Created a content collection form`);
  }, [track]);

  return { trackCreatedContentCollectionForm };
};
