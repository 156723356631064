'use client';
import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixTooltip from '@radix-ui/react-tooltip';

export const TooltipProvider = RadixTooltip.Provider;

export type TooltipProps = Pick<
  RadixTooltip.TooltipProps,
  'defaultOpen' | 'delayDuration' | 'disableHoverableContent' | 'onOpenChange' | 'open'
> &
  Pick<RadixTooltip.TooltipTriggerProps, 'children'> &
  Pick<
    RadixTooltip.TooltipContentProps,
    'align' | 'alignOffset' | 'avoidCollisions' | 'className' | 'side' | 'sideOffset'
  > & {
    'data-testid'?: string;
    label?: RadixTooltip.TooltipContentProps['children'];
  };

export const Tooltip = ({
  align,
  alignOffset,
  avoidCollisions,
  children,
  className,
  'data-testid': testId,
  defaultOpen,
  delayDuration = 500,
  disableHoverableContent = false,
  label,
  onOpenChange,
  open,
  side,
  sideOffset = 8,
}: TooltipProps) => {
  if (!label) return <>{children}</>;

  return (
    <RadixTooltip.Root
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      onOpenChange={onOpenChange}
      open={open}
    >
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          align={align}
          alignOffset={alignOffset}
          avoidCollisions={avoidCollisions}
          className={tailwindMerge(
            'z-10 min-h-[32px] rounded bg-black px-2 py-1.5 text-14 font-medium text-white',
            className,
          )}
          data-testid={testId}
          side={side}
          sideOffset={sideOffset}
        >
          {label}
          <RadixTooltip.Arrow />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};
