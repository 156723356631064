import { forwardRef, SVGProps } from 'react';

export interface LineweightHeavyProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const LineweightHeavy = forwardRef<SVGSVGElement, LineweightHeavyProps>((props, ref) => (
  <svg ref={ref} {...props} fill="currentColor" aria-hidden="true" viewBox="0 0 32 32">
    <path d="M8.05,28.5a1.54,1.54,0,0,1-.84-.26,1.5,1.5,0,0,1-.4-2.08s0,0,0,0a5.23,5.23,0,0,0-1-1.53c-1-1.24-2.56-3.12-1.18-5.15C6.14,17.2,9,19,11.78,20.82c1,.64,3.49,2.34,3.64,2.05.38-.43-3.49-2.95-5.17-4.13-3.79-2.66-7.37-5.17-7.72-7.89a3.36,3.36,0,0,1,.81-2.64c2.38-2.81,7.06.3,12.47,3.91a69.2,69.2,0,0,0,6.38,3.94,3.91,3.91,0,0,0,3.79.23,1.18,1.18,0,0,0,.51-1.19c-.07-.7-1-4.38-11.44-8.72A1.5,1.5,0,1,1,16.2,3.62c11,4.55,13,9,13.27,11.16a4.15,4.15,0,0,1-1.8,4c-1.78,1.22-4.4,1.21-6.84,0a72.28,72.28,0,0,1-6.68-4.12c-2.61-1.74-7.48-5-8.54-4.46-.13.16-.12.24-.11.31.18,1.42,4,4.07,6.47,5.83,4.22,3,7.86,5.52,6.37,8.27a2.64,2.64,0,0,1-1.67,1.31c-1.84.47-4.1-1-6.51-2.53a15.13,15.13,0,0,0-2.84-1.68c-.27.07.57.77.79,1,1,1.24,2.56,3.11,1.18,5.14A1.49,1.49,0,0,1,8.05,28.5Z" />
  </svg>
));
LineweightHeavy.displayName = 'LineweightHeavy';
