import { User } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackRequestedForgetPasswordCode = () => {
  const { track } = useAnalyticsUtilities();

  const trackRequestedForgetPasswordCode = useCallback(
    (params: Pick<User, 'email'>) => {
      track(`Requested forgot password code`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackRequestedForgetPasswordCode };
};
