import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export const useTrackRemovedSearchTerm = () => {
  const { track } = useAnalyticsUtilities();

  const trackRemovedSearchTerm = useCallback(() => {
    track(`Removed a search term`);
  }, [track]);

  return { trackRemovedSearchTerm };
};
