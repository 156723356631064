import { forwardRef, SVGProps } from 'react';

export interface FigmaStarFilledIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaStarFilledIcon = forwardRef<SVGSVGElement, FigmaStarFilledIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <g clip-path="url(#a)">
      <path
        fill="currentColor"
        d="M8 0.390137L9.87882 6.21586L16 6.20248L11.04 9.78959L12.9443 15.607L8 11.9983L3.05573 15.607L4.96 9.78959L0 6.20248L6.12118 6.21586L8 0.390137Z"
        fillOpacity="0.8"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
));

FigmaStarFilledIcon.displayName = 'FigmaStarFilledIcon';
