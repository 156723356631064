import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { BaseEditCommentParams } from '../types';

export const useTrackDeletedComment = () => {
  const { track } = useAnalyticsUtilities();

  const trackDeletedComment = useCallback(
    (params: BaseEditCommentParams) => {
      track(`Deleted a comment`, params);
    },
    [track],
  );

  return { trackDeletedComment };
};
