import { Meta } from '../types/docs';

const META: Meta = {
  name: 'enabled_or_disabled_session_duration',
  description: 'Tracks when a user enables/disables session duration',
  params: [
    {
      name: 'session_duration_enabled',
      description: 'binary value captures whether feature was enabled or disabled',
      required: true,
      type: 'boolean',
    },
    {
      name: 'session_duration_time',
      description: 'Duration period (in seconds)',
      required: false,
      type: 'number',
    },
  ],
};

export default META;
