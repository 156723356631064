import { Clip, Tag, UnmadeTag } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type UpdatedAssetTags = { asset: Clip['id']; tags: (Tag | UnmadeTag)[] };

export const useTrackUpdatedAssetTags = () => {
  const { track } = useAnalyticsUtilities();

  const trackUpdatedAssetTags = useCallback(
    (params: UpdatedAssetTags) => {
      track(`Updated an asset's tags`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackUpdatedAssetTags };
};
