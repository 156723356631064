import { forwardRef, SVGProps } from 'react';

export interface KeyProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const Key = forwardRef<SVGSVGElement, KeyProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.05204 9.35619C8.66209 9.23159 8.23521 9.33518 7.94574 9.62465L7.16414 10.4062H6.25C5.64594 10.4062 5.15625 10.8959 5.15625 11.5V12.1562H4.5C3.89594 12.1562 3.40625 12.6459 3.40625 13.25V13.9062H2.09375V12.3359L6.37535 8.05426C6.66482 7.76479 6.76841 7.33791 6.64381 6.94796C6.53049 6.59332 6.46875 6.21069 6.46875 5.8125C6.46875 3.75875 8.13375 2.09375 10.1875 2.09375C12.2413 2.09375 13.9062 3.75875 13.9062 5.8125C13.9062 7.86625 12.2413 9.53125 10.1875 9.53125C9.78931 9.53125 9.40668 9.46951 9.05204 9.35619ZM10.1875 10.625C9.67617 10.625 9.18125 10.5457 8.71914 10.398L7.80859 11.3086C7.68555 11.4316 7.51875 11.5 7.34375 11.5H6.25V12.5938C6.25 12.9574 5.95742 13.25 5.59375 13.25H4.5V14.3438C4.5 14.7074 4.20742 15 3.84375 15H1.65625C1.29258 15 1 14.7074 1 14.3438V12.1562C1 11.9813 1.06836 11.8145 1.19141 11.6914L5.60195 7.28086C5.4543 6.81875 5.375 6.32383 5.375 5.8125C5.375 3.15469 7.52969 1 10.1875 1C12.8453 1 15 3.15469 15 5.8125C15 8.47031 12.8453 10.625 10.1875 10.625ZM10.748 6.22266C11.3144 6.22266 11.7734 5.76357 11.7734 5.19727C11.7734 4.63096 11.3144 4.17188 10.748 4.17188C10.1817 4.17188 9.72266 4.63096 9.72266 5.19727C9.72266 5.76357 10.1817 6.22266 10.748 6.22266Z"
      clipRule="evenodd"
    />
  </svg>
));
Key.displayName = 'Key';
