import { forwardRef, SVGProps } from 'react';

export interface YouTubeProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const YouTube = forwardRef<SVGSVGElement, YouTubeProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 32 32">
    <path
      fill="#666"
      d="M16.217 7.00384C17.5316 7.02301 18.6151 7.03452 19.7024 7.06137C20.5851 7.08055 21.4678 7.11507 22.3505 7.15343C22.9983 7.18028 23.6461 7.2148 24.2939 7.26466C24.9001 7.31069 25.5062 7.37206 26.1086 7.44111C26.6541 7.50248 27.1807 7.6444 27.6619 7.91673C28.5938 8.44223 29.1772 9.24005 29.4197 10.2949C29.5599 10.9124 29.6584 11.5415 29.7379 12.1705C29.8819 13.3443 29.9538 14.5257 29.9842 15.7109C30.0069 16.5893 30.0069 17.4715 29.9728 18.3499C29.9463 19.0556 29.9046 19.7614 29.8478 20.4633C29.7947 21.0924 29.7265 21.7176 29.6394 22.3428C29.575 22.8146 29.4992 23.2864 29.3856 23.7467C29.2113 24.4525 28.8287 25.0355 28.2832 25.5073C27.7831 25.9407 27.2035 26.1977 26.5594 26.3128C25.9722 26.4164 25.3774 26.4854 24.7827 26.5429C23.828 26.6312 22.8733 26.6849 21.9186 26.7232C19.3122 26.8344 16.7057 26.8536 14.0955 26.8153C13.2772 26.8038 12.4551 26.7884 11.6368 26.7616C10.6859 26.727 9.73883 26.681 8.79172 26.635C8.32196 26.612 7.85219 26.5775 7.38243 26.5391C6.67399 26.4816 5.96177 26.4164 5.2647 26.2783C4.35548 26.098 3.64325 25.607 3.1053 24.8476C2.79844 24.4141 2.61659 23.9231 2.51052 23.4015C2.36656 22.6842 2.26427 21.9593 2.19608 21.2305C2.01802 19.4239 1.96877 17.6211 2.01802 15.8106C2.03696 15.0818 2.07106 14.3531 2.11652 13.6281C2.16577 12.7958 2.2529 11.9672 2.38171 11.1426C2.44232 10.7475 2.49157 10.3447 2.62038 9.965C3.01816 8.77593 3.81752 7.99728 5.0033 7.64056C5.27985 7.55618 5.56777 7.52166 5.85569 7.4833C6.35198 7.41809 6.84826 7.36056 7.34833 7.31069C7.76885 7.2685 8.18936 7.23781 8.61366 7.21096C9.27664 7.17261 9.93961 7.13425 10.6026 7.1074C11.2959 7.08055 11.9891 7.05754 12.6824 7.04603C13.9364 7.02685 15.1904 7.01534 16.217 7V7.00384ZM20.604 16.9191C18.1908 15.5191 15.7927 14.1229 13.3947 12.7305C13.3643 12.7152 13.334 12.6999 13.3075 12.6845C13.3075 12.6845 13.2999 12.6845 13.2962 12.6845C13.2962 12.6845 13.2886 12.6845 13.2848 12.6884C13.2621 13.0489 13.2734 21.054 13.2999 21.1538C15.7321 19.7422 18.1605 18.3307 20.604 16.9115V16.9191Z"
    />
  </svg>
));
YouTube.displayName = 'YouTube';
