import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';

export type ClickedEllipsisMenuButtonLocation =
  | 'board-item'
  | 'asset-item'
  | 'file-item'
  | 'sidebar'
  | 'board-header'
  | 'versions-tab'
  | 'pdf-page';

export type ClickedEllipsisMenuButton = { location: ClickedEllipsisMenuButtonLocation };

export const useTrackClickedEllipsisMenuButton = () => {
  const { track } = useAnalyticsUtilities();

  const trackClickedEllipsisMenuButton = useCallback(
    (params: ClickedEllipsisMenuButton) => {
      track(`Clicked on ellipsis`, params, { integrations: { Intercom: true } });
    },
    [track],
  );

  return { trackClickedEllipsisMenuButton };
};
