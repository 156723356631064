import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import { BaseEditCommentParams } from '../types';

export const useTrackResolvedDiscussion = () => {
  const { track } = useAnalyticsUtilities();

  const trackResolvedDiscussion = useCallback(
    (params: BaseEditCommentParams) => {
      track(`Resolved a discussion`, params);
    },
    [track],
  );

  return { trackResolvedDiscussion };
};
