const enableLogging = typeof window !== 'undefined' && !!window.localStorage && !!window.localStorage.analyticsDebug;

//analytics-enabled logger
const analyticsBadgeStyles =
  'font-weight: bold; background-color: #4030FF; color: #FFFFFF; padding: 2px 8px; border-radius: 4px;';

export const logger = (message: string) => {
  if (enableLogging) {
    console.info('%cAnalytics', analyticsBadgeStyles, message);
  }
};

//event logger

/**
 * It can be useful to log events while developing. In this package, logging is enabled
 * by setting `localStorage.analyticsDebug` to a truthy value; to disable, set the value
 * to and empty string.
 * ```
 * localStorage.analyticsDebug = '';
 * ```
 *
 * @param message message to be logged to the dev console
 */
export const eventLogger = (message: string) => {
  if (enableLogging) {
    console.info('%cAnalytics event:', analyticsBadgeStyles, message);
  }
};
